import * as firebase from 'firebase/app';
import 'firebase/auth';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import { Subscriber } from '@evidentid/subscriber';

// Ensure that Map is available (for IE 11)
import 'core-js/es/map';
// @ts-ignore: for some reason Map polyfill is treat as dead code, if there is no Map created
window.__mapDeadCodeEliminator__ = new Map();

export interface FirebaseAppConfig {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    initializeApp?: typeof firebase.initializeApp;
}

export interface FirebaseAppSession {
    firebaseId: string;
    displayName: string;
    email: string;
    provider: string;
    jwt: string;
    jwtExpiresAt: number;
    avatarUrl: string | null;
    emailVerified: boolean;
    claims: string[];
}

/**
 * Verify the schema of Firebase configuration.
 */
export function isValidFirebaseAppConfig(config: any): boolean {
    if (!config || typeof config !== 'object') {
        return false;
    }
    const expectedKeys = [ 'apiKey', 'authDomain', 'databaseURL', 'projectId', 'storageBucket', 'messagingSenderId' ];
    for (const key of expectedKeys) {
        if (typeof config[key] !== 'string') {
            return false;
        }
    }
    return true;
}

export enum FirebaseSignInExternalMethod {
    google = 'google',
    facebook = 'facebook',
}

/**
 * Get internal Firebase instance of AuthProvider,
 * based on selected strategy.
 *
 * @throws {Error}
 */
function getExternalAuthProvider(type: FirebaseSignInExternalMethod): firebase.auth.AuthProvider {
    if (type === FirebaseSignInExternalMethod.facebook) {
        return (new firebase.auth.FacebookAuthProvider()).addScope('email');
    } else if (type === FirebaseSignInExternalMethod.google) {
        return (new firebase.auth.GoogleAuthProvider())
            .addScope('https://www.googleapis.com/auth/userinfo.email');
    }
    throw new Error('Unknown Firebase auth provider');
}

/**
 * Support Firebase auth with nice interface.
 */
export default class FirebaseAuth {
    private config: Required<FirebaseAppConfig>;
    private firebaseApp: ReturnType<typeof firebase.initializeApp>;
    private subscriber: Subscriber<FirebaseAppSession | null> = new Subscriber();
    private ready = false;

    public constructor(config: FirebaseAppConfig) {
        if (!isValidFirebaseAppConfig(config)) {
            throw new Error('Invalid Firebase app configuration');
        }

        this.config = {
            initializeApp: firebase.initializeApp.bind(firebase),
            ...config,
        };
        this.firebaseApp = this.config.initializeApp(omit(this.config, 'initializeApp'));
        this.firebaseApp.auth().onAuthStateChanged(this.emitChange.bind(this));
        this.subscriber.listenOnce(() => {
            this.ready = true;
        });
    }

    /**
     * The Firebase is initialized when its listener is called the first time,
     * so some mechanisms may want to wait until this time.
     */
    public untilReady(timeout = 5000): Promise<void> {
        if (this.ready) {
            return Promise.resolve();
        }
        return new Promise((resolve, reject) => {
            let ready = false;

            function finish() {
                ready = true;
                resolve();
            }

            const unsubscribe = this.subscriber.listenOnce(finish);
            setTimeout(() => {
                if (!ready) {
                    unsubscribe();
                    reject(new Error('FirebaseAuth readiness timed out'));
                }
            }, timeout);
        });
    }

    /**
     * Obtain information about user from current session.
     */
    public async getSession(): Promise<FirebaseAppSession | null> {
        const currentUser = this.firebaseApp.auth().currentUser;
        if (!currentUser) {
            return null;
        }
        return currentUser.getIdTokenResult().then((tokenResult) => ({
            firebaseId: currentUser.uid,
            displayName: currentUser.displayName! || currentUser.email!,
            email: currentUser.email!,
            provider: currentUser.providerId,
            jwt: tokenResult.token,
            jwtExpiresAt: tokenResult.claims.exp * 1000,
            avatarUrl: currentUser.photoURL,
            emailVerified: Boolean(tokenResult.claims?.email_verified),
            claims: Object.keys(tokenResult.claims || {})
                .filter((x) => x !== 'exp' && x !== 'email_verified' && tokenResult.claims[x]),
        }));
    }

    /**
     * Get recent Firebase ID Token.
     * It will renew it if Firebase wasn't able to refresh it by itself,
     * and then return the latest one.
     */
    public async getToken(): Promise<string | null> {
        // Retrieve latest session data
        const session = await this.getSession();

        // When it has recent information, return it
        if (!session) {
            return null;
        } else if (session.jwtExpiresAt >= Date.now()) {
            return session.jwt;
        }

        // Refresh token, as it expired
        await this.refreshToken();

        // Try once again, with renewed token
        return this.getToken();
    }

    /**
     * Firebase ID Token is expiring in 1 hour.
     * If users are longer on single site, you need to call it frequently,
     * to increase session expiration time.
     *
     * As Firebase is not emitting event during existing user data refresh,
     * emit such event on behalf of Firebase.
     */
    public async refreshToken(): Promise<void> {
        const currentUser = this.firebaseApp.auth().currentUser;
        if (currentUser) {
            const previousData = await this.getSession();
            await currentUser.getIdTokenResult(true);
            const nextData = await this.getSession();
            if (!isEqual(nextData, previousData)) {
                await this.emitChange();
            }
        }
    }

    /**
     * Sign in with external provider, based on provided strategy.
     */
    public async signInWithExternalProvider(type: FirebaseSignInExternalMethod): Promise<firebase.auth.UserCredential> {
        return this.firebaseApp.auth().signInWithPopup(getExternalAuthProvider(type));
    }

    /**
     * Sign in with regular e-mail and password combination.
     */
    public async signIn(email: string, password: string): Promise<firebase.auth.UserCredential> {
        return this.firebaseApp.auth().signInWithEmailAndPassword(email, password);
    }

    /**
     * Create new user with e-mail/password combination.
     */
    public async register(email: string, password: string): Promise<firebase.auth.UserCredential> {
        return this.firebaseApp.auth().createUserWithEmailAndPassword(email, password);
    }

    /**
     * Send request for password request.
     * Works only for existing user.
     */
    public async resetPassword(email: string, continueUrl: string): Promise<void> {
        return this.firebaseApp.auth().sendPasswordResetEmail(email, {
            url: continueUrl,
        });
    }

    /**
     * Send verification e-mail.
     * Works only for authenticated user.
     */
    public async sendEmailVerification(continueUrl: string): Promise<void> {
        return this.firebaseApp.auth().currentUser?.sendEmailVerification({
            url: continueUrl,
        });
    }

    /**
     * Destroy current Firebase session,
     * and logout from external provider.
     */
    public async signOut() {
        return this.firebaseApp.auth().signOut();
    }

    /**
     * Register listener, which will obtain information about user session changes.
     */
    public addListener(fn: (data: FirebaseAppSession | null) => any): () => void {
        return this.subscriber.listen(fn);
    }

    /**
     * Destroy all listeners and Firebase instance.
     */
    public destroy() {
        this.subscriber.clear();
        this.firebaseApp.delete();
        this.ready = false;
    }

    /**
     * Emit session information, when it's changed.
     */
    private async emitChange() {
        this.subscriber.emit(await this.getSession());
    }
}
