import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import vuex from '@/modules/decisioning-criteria/vuex';
import Severity from '@evidentid/logger/Severity';
import DecisioningCriteria from '@/modules/decisioning-criteria/views/DecisioningCriteria.vue';
import CoverageCriteriaGroup from '@/modules/decisioning-criteria/views/CoverageCriteriaGroup.vue';

export const decisioningCriteriaModule = createModuleBuilder()
    .registerRoutes(() => [
        {
            path: '/:rpId?/decisioningCriteria',
            name: 'insuredDecisioningCriteria',
            component: DecisioningCriteria as any,
            meta: { title: 'Decisioning Criteria' },
        },
        {
            path: '/:rpId?/decisioningCriteria/group/',
            name: 'createInsuredDecisioningCriteriaGroup',
            component: CoverageCriteriaGroup as any,
            meta: { title: 'Decisioning Criteria' },
        },
        {
            path: '/:rpId?/decisioningCriteria/group/:id',
            name: 'insuredDecisioningCriteriaGroup',
            component: CoverageCriteriaGroup as any,
            meta: { title: 'Decisioning Criteria' },
        },
    ])
    .demandContext<{
        rpweb: RpWebApiClient;
        tracker?: Tracker<any>;
        logger?: Logger<any>;
    }>()
    .registerVuex({ decisioningCriteria: vuex.instantiateModule })
    .execute((app) => {
        function onLoadingCoverageCriteriaGroupsRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Coverage Criteria Groups',
                    description: `[Requested] Load coverage criteria group for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Coverage Criteria Groups',
                    message: `[Requested] Load coverage criteria group for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCoverageCriteriaGroupsFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Coverage Criteria Groups',
                    description: `[Finished] Load coverage criteria group for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Coverage Criteria Groups',
                    message: `[Finished] Load coverage criteria group for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCoverageCriteriaGroupsFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Coverage Criteria Groups Failed',
                    description: `[Failed] Load coverage criteria group for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Coverage Criteria Groups',
                    message: `[Failed] Load coverage criteria group for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingInsuredCoverageCriteriaGroupsRequest(rpName: string, insuredId: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Insured Coverage Criteria Groups',
                    description: `[Requested] Load insured coverage criteria groups for rp: ${rpName} insured: ${insuredId}`,
                    rpName,
                    insuredId,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Insured Coverage Criteria Groups',
                    message: `[Requested] Load insured coverage criteria groups for rp: ${rpName} insured: ${insuredId}`,
                    data: { rpName, insuredId },
                });
            }
        }

        function onLoadingInsuredCoverageCriteriaGroupsFinish(rpName: string, insuredId: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Insured Coverage Criteria Groups',
                    description: `[Finished] Load insured coverage criteria groups for rp: ${rpName} insured: ${insuredId}`,
                    rpName,
                    insuredId,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Insured Coverage Criteria Groups',
                    message: `[Finished] Load insured coverage criteria groups for rp: ${rpName} insured: ${insuredId}`,
                    data: { rpName, insuredId },
                });
            }
        }

        function onLoadingInsuredCoverageCriteriaGroupsFailure(rpName: string, insuredId: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Insured Coverage Criteria Groups Failed',
                    description: `[Failed] Load insured coverage criteria groups for rp: ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Insured Coverage Criteria Groups',
                    message: `[Failed] Load insured coverage criteria groups for rp: ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCurrentCoverageCriteriaGroupRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Single Coverage Criteria Group',
                    description: `[Requested] Load single coverage criteria group for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Coverage Criteria Group',
                    message: `[Requested] Load single coverage criteria group for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCurrentCoverageCriteriaGroupFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Coverage Criteria Group',
                    description: `[Finished] Load single coverage criteria group for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Coverage Criteria Group',
                    message: `[Finished] Load single coverage criteria group for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCurrentCoverageCriteriaGroupFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Single Coverage Criteria Group Failed',
                    description: `[Failed] Load single coverage criteria group for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Coverage Criteria Group',
                    message: `[Failed] Load single coverage criteria group for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCoverageCriteriaRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Coverage Criteria',
                    description: `[Requested] Load coverage criteria for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Coverage Criteria Group',
                    message: `[Requested] Load coverage criteria for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCoverageCriteriaFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Coverage Criteria Group',
                    description: `[Finished] Load coverage criteria for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Coverage Criteria Group',
                    message: `[Finished] Load coverage criteria for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCoverageCriteriaFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Coverage Criteria Failed',
                    description: `[Failed] Load coverage criteria for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Coverage Criteria Group',
                    message: `[Failed] Load coverage criteria for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCoverageCriterionTemplatesRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Coverage Criterion Templates',
                    description: `[Requested] Load coverage criterion templates for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Coverage Criterion Templates Group',
                    message: `[Requested] Load coverage criterion templates for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCoverageCriterionTemplatesFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Coverage Criterion Templates Group',
                    description: `[Finished] Load coverage criterion templates for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Coverage Criterion Templates Group',
                    message: `[Finished] Load coverage criterion templates for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCoverageCriterionTemplatesFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Coverage Criterion Templates Failed',
                    description: `[Failed] Load coverage criterion templates for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Coverage Criterion Templates Group',
                    message: `[Failed] Load coverage criterion templates for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCoverageTypesPerCountryRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Single Coverage Criteria Group',
                    description: `[Requested] Load single coverage criteria group for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Coverage Criteria Group',
                    message: `[Requested] Load single coverage criteria group for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCoverageTypesPerCountryFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Coverage Types Per Country',
                    description: `[Finished] Load coverage types per country for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Coverage Types Per Country',
                    message: `[Finished] Load coverage types per country for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCoverageTypesPerCountryFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Single Coverage Criteria Group Failed',
                    description: `[Failed] Load coverage types per country for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Coverage Types Per Country',
                    message: `[Failed] Load coverage types per country for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onDeletingCoverageCriteriaGroupRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Delete Coverage Criteria Group ',
                    description: `[Requested] Delete coverage criteria group for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Coverage Criteria Group ',
                    message: `[Requested] Delete coverage criteria group for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onDeletingCoverageCriteriaGroupFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Delete Coverage Criteria Group ',
                    description: `[Finished] Delete coverage criteria group for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Coverage Criteria Group ',
                    message: `[Finished] Delete coverage criteria group for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onDeletingCoverageCriteriaGroupFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Delete Coverage Criteria Group Failed',
                    description: `[Failed] Delete coverage criteria group for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Coverage Criteria Group ',
                    message: `[Failed] Delete coverage criteria group for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onCreatingCoverageCriteriaGroupRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Create Coverage Criteria Group ',
                    description: `[Requested] Create coverage criteria group for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Coverage Criteria Group ',
                    message: `[Requested] Create coverage criteria group for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onCreatingCoverageCriteriaGroupFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Create Coverage Criteria Group ',
                    description: `[Finished] Create coverage criteria group for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Coverage Criteria Group ',
                    message: `[Finished] Create coverage criteria group for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onCreatingCoverageCriteriaGroupFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Create Coverage Criteria Group Failed',
                    description: `[Failed] Create coverage criteria group for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Coverage Criteria Group ',
                    message: `[Failed] Create coverage criteria group for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onPatchingCoverageCriteriaRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Patch Coverage Criteria ',
                    description: `[Requested] Patch coverage criteria for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Coverage Criteria Group ',
                    message: `[Requested] Patch coverage criteria for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onPatchingCoverageCriteriaFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Patch Coverage Criteria ',
                    description: `[Finished] Patch coverage criteria for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Coverage Criteria Group ',
                    message: `[Finished] Patch coverage criteria for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onPatchingCoverageCriteriaFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Patch Coverage Criteria Failed',
                    description: `[Failed] Patch coverage criteria for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Coverage Criteria Group ',
                    message: `[Failed] Patch coverage criteria for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onUpdatingCoverageCriteriaGroupRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Update Coverage Criteria Group ',
                    description: `[Requested] Update coverage criteria group for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Coverage Criteria Group ',
                    message: `[Requested] Update coverage criteria group for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onUpdatingCoverageCriteriaGroupFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Update Coverage Criteria Group ',
                    description: `[Finished] Update coverage criteria group for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Coverage Criteria Group ',
                    message: `[Finished] Update coverage criteria group for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onUpdatingCoverageCriteriaGroupFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Update Coverage Criteria Group Failed',
                    description: `[Failed] Update coverage criteria group for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Coverage Criteria Group ',
                    message: `[Failed] Update coverage criteria group for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCoverageCriterionMessageRequest(rpName: string, criterionId: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Loading Coverage Criterion Message ',
                    description: `[Requested] Load coverage criteria group for rp:${rpName}, criterion:${criterionId}`,
                    rpName,
                    criterionId,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Coverage Criterion Message ',
                    message: `[Requested] Load coverage criterion message for rp:${rpName}, criterion:${criterionId}`,
                    data: { rpName, criterionId },
                });
            }
        }

        function onLoadingCoverageCriterionMessageFinish(rpName: string, criterionId: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Coverage Criterion Message ',
                    description: `[Finished] Load coverage criterion message for rp:${rpName}, criterion:${criterionId}`,
                    rpName,
                    criterionId,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Coverage Criterion Message ',
                    message: `[Finished] Load coverage criterion message for rp:${rpName}, criterion:${criterionId}`,
                    data: { rpName, criterionId },
                });
            }
        }

        function onLoadingCoverageCriterionMessageFailure(rpName: string, criterionId: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Update Coverage Criteria Group Failed',
                    description: `[Failed] Update coverage criteria group for rp:${rpName}, criterion:${criterionId}`,
                    rpName,
                    criterionId,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Coverage Criteria Group ',
                    message: `[Failed] Update coverage criteria group for rp:${rpName}, criterion:${criterionId}`,
                    data: { rpName, criterionId },
                });
            }
        }

        function onLoadingCoverageModelsRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Coverage Models',
                    description: `[Requested] Load coverage models for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Coverage Models',
                    message: `[Requested] Load coverage models for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCoverageModelsFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Coverage Models',
                    description: `[Finished] Load coverage models for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Coverage Models',
                    message: `[Finished] Load coverage models for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCoverageModelsFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Coverage Models Failed',
                    description: `[Failed] Load coverage models for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Coverage Models',
                    message: `[Failed] Load coverage models for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        app.observer.onMutation('startLoadingCoverageCriteriaGroups', (x) => onLoadingCoverageCriteriaGroupsRequest(x.rpName));
        app.observer.onMutation('finishLoadingCoverageCriteriaGroups', (x) => onLoadingCoverageCriteriaGroupsFinish(x.rpName));
        app.observer.onMutation('failLoadingCoverageCriteriaGroups', (x) => onLoadingCoverageCriteriaGroupsFailure(x.rpName));
        app.observer.onMutation('startLoadingInsuredCoverageCriteriaGroups', (x) => onLoadingInsuredCoverageCriteriaGroupsRequest(x.rpName, x.insuredId));
        app.observer.onMutation('finishLoadingInsuredCoverageCriteriaGroups', (x) => onLoadingInsuredCoverageCriteriaGroupsFinish(x.rpName, x.insuredId));
        app.observer.onMutation('failLoadingInsuredCoverageCriteriaGroups', (x) => onLoadingInsuredCoverageCriteriaGroupsFailure(x.rpName, x.insuredId));
        app.observer.onMutation('startLoadingCurrentCoverageCriteriaGroup', (x) => onLoadingCurrentCoverageCriteriaGroupRequest(x.rpName));
        app.observer.onMutation('finishLoadingCurrentCoverageCriteriaGroup', (x) => onLoadingCurrentCoverageCriteriaGroupFinish(x.rpName));
        app.observer.onMutation('failLoadingCurrentCoverageCriteriaGroup', (x) => onLoadingCurrentCoverageCriteriaGroupFailure(x.rpName));
        app.observer.onMutation('startLoadingCoverageCriteria', (x) => onLoadingCoverageCriteriaRequest(x.rpName));
        app.observer.onMutation('finishLoadingCoverageCriteria', (x) => onLoadingCoverageCriteriaFinish(x.rpName));
        app.observer.onMutation('failLoadingCoverageCriteria', (x) => onLoadingCoverageCriteriaFailure(x.rpName));
        app.observer.onMutation('startLoadingCoverageCriterionTemplates', (x) => onLoadingCoverageCriterionTemplatesRequest(x.rpName));
        app.observer.onMutation('finishLoadingCoverageCriterionTemplates', (x) => onLoadingCoverageCriterionTemplatesFinish(x.rpName));
        app.observer.onMutation('failLoadingCoverageCriterionTemplates', (x) => onLoadingCoverageCriterionTemplatesFailure(x.rpName));
        app.observer.onMutation('startLoadingCoverageTypesPerCountry', (x) => onLoadingCoverageTypesPerCountryRequest(x.rpName));
        app.observer.onMutation('finishLoadingCoverageTypesPerCountry', (x) => onLoadingCoverageTypesPerCountryFinish(x.rpName));
        app.observer.onMutation('failLoadingCoverageTypesPerCountry', (x) => onLoadingCoverageTypesPerCountryFailure(x.rpName));
        app.observer.onMutation('startDeletingCoverageCriteriaGroup', (x) => onDeletingCoverageCriteriaGroupRequest(x.rpName));
        app.observer.onMutation('finishDeletingCoverageCriteriaGroup', (x) => onDeletingCoverageCriteriaGroupFinish(x.rpName));
        app.observer.onMutation('failDeletingCoverageCriteriaGroup', (x) => onDeletingCoverageCriteriaGroupFailure(x.rpName));
        app.observer.onMutation('startCreatingCoverageCriteriaGroup', (x) => onCreatingCoverageCriteriaGroupRequest(x.rpName));
        app.observer.onMutation('finishCreatingCoverageCriteriaGroup', (x) => onCreatingCoverageCriteriaGroupFinish(x.rpName));
        app.observer.onMutation('failCreatingCoverageCriteriaGroup', (x) => onCreatingCoverageCriteriaGroupFailure(x.rpName));
        app.observer.onMutation('startPatchingCoverageCriteria', (x) => onPatchingCoverageCriteriaRequest(x.rpName));
        app.observer.onMutation('finishPatchingCoverageCriteria', (x) => onPatchingCoverageCriteriaFinish(x.rpName));
        app.observer.onMutation('failPatchingCoverageCriteria', (x) => onPatchingCoverageCriteriaFailure(x.rpName));
        app.observer.onMutation('startUpdatingCoverageCriteriaGroup', (x) => onUpdatingCoverageCriteriaGroupRequest(x.rpName));
        app.observer.onMutation('finishUpdatingCoverageCriteriaGroup', (x) => onUpdatingCoverageCriteriaGroupFinish(x.rpName));
        app.observer.onMutation('failUpdatingCoverageCriteriaGroup', (x) => onUpdatingCoverageCriteriaGroupFailure(x.rpName));

        app.observer.onMutation('startLoadingCoverageCriterionMessage', (x) => onLoadingCoverageCriterionMessageRequest(x.rpName, x.criterionId));
        app.observer.onMutation('finishLoadingCoverageCriterionMessage', (x) => onLoadingCoverageCriterionMessageFinish(x.rpName, x.criterionId));
        app.observer.onMutation('failLoadingCoverageCriterionMessage', (x) => onLoadingCoverageCriterionMessageFailure(x.rpName, x.criterionId));
        app.observer.onMutation('startLoadingCoverageModels', (x) => onLoadingCoverageModelsRequest(x.rpName));
        app.observer.onMutation('finishLoadingCoverageModels', (x) => onLoadingCoverageModelsFinish(x.rpName));
        app.observer.onMutation('failLoadingCoverageModels', (x) => onLoadingCoverageModelsFailure(x.rpName));
    })
    .end();
