import {
    InsuranceCollateralEntity,
    InsuranceEvaluatedEntityMappingAction,
    InsuranceInsuredActionDetails,
    InsuranceInsuredActionType,
    InsuranceInsuredEndorsementFormNumberAction,
    InsuranceInsuredEntityNameMatchingAction,
} from '@evidentid/rpweb-api-client/types';

function getEvaluatedEntityMappingActionValue(entityMappingAction: InsuranceEvaluatedEntityMappingAction): string {
    // [entityMappingExpansion]: currently only collateral type support, when expanded, add cases per type
    const requiredEntity = entityMappingAction.requiredEntity as InsuranceCollateralEntity;
    return requiredEntity.description;
}

export function getActionValue(insuredActionDetails: InsuranceInsuredActionDetails): any {
    switch (insuredActionDetails.action.$action) {
        case InsuranceInsuredActionType.entityNameMatchingV1:
            return (insuredActionDetails.action as InsuranceInsuredEntityNameMatchingAction).given;
        case InsuranceInsuredActionType.endorsementFormNumberV1:
            return (insuredActionDetails.action as InsuranceInsuredEndorsementFormNumberAction).formNumber;
        case InsuranceInsuredActionType.evaluatedEntityMappingV1:
            return getEvaluatedEntityMappingActionValue(
                insuredActionDetails.action as InsuranceEvaluatedEntityMappingAction,
            );
        default:
            return null;
    }
}
