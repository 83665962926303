
export enum InsuredFilterCategory {
    compliantAndNotExpiringSoon = 'compliantAndNotExpiringSoon',
    compliantAndExpiringSoon = 'compliantAndExpiringSoon',
    nonCompliant = 'nonCompliant',
    pendingAndNew = 'pendingAndNew',
}

export type FilterType = 'AND' | 'OR';

export interface InsuredStandardFilters {
    search?: string;
    displayName?: string;
    contactName?: string;
    contactEmail?: string;
    contactPhoneNumber?: string;
    complianceStatus?: string;
    verificationStatus?: string;
    expiresBeforeOrOn?: string;
    expiresAfterOrOn?: string;
    showDeactivated?: string;
    paused?: string;
    coverageCriteriaGroupFilterType?: FilterType;
    coverageCriteriaGroupId?: string;
    effectiveGroup?: string;
}

export interface InsuredFieldFilters {
    insuredFieldFilters?: Record<string, string>;
}

export type InsuredFilters = InsuredStandardFilters & InsuredFieldFilters;

export class InsuredStandardFiltersObject implements InsuredStandardFilters {
    public search = undefined;
    public displayName = undefined;
    public contactName = undefined;
    public contactEmail = undefined;
    public contactPhoneNumber = undefined;
    public complianceStatus = undefined;
    public verificationStatus = undefined;
    public expiresBeforeOrOn = undefined;
    public expiresAfterOrOn = undefined;
    public showDeactivated = undefined;
    public paused = undefined;
    public coverageCriteriaGroupFilterType = undefined;
    public coverageCriteriaGroupId = undefined;
    public effectiveGroup = undefined;
}
