import { RulesLogic } from 'json-logic-js';
import JsonSchema from '@evidentid/json-schema/interfaces/JsonSchema';
import type { AsaStatusType } from '@evidentid/ido-lib/interfaces/attributeStatuses';
import type { RelyingPartyRequestStatusType } from '@evidentid/ido-lib/getIdoRequestStatus';
import { PatchOperationType } from './index';

export interface RelyingPartySignature {
    name: string;
    displayName: string;
    externalConfigurationEnabled?: boolean; // Is the RP (Insurance) Configuration enabled?
}

export enum OperationError {
    keyConflict = 'KeyConflict',
    notFound = 'NotFound',
    badRequest = 'BadRequest',
    badRoute = 'BadRoute',
    unauthorized = 'Unauthorized',
    unknown = 'Unknown',
}

export interface RelyingPartyConsentData {
    data: string;
    metadata: Record<string, string>;
    // eslint-disable-next-line camelcase
    mime_type: string;
}

export interface RelyingPartySettings {
    branding: {
        dbaName: string;
        logoUrl: string;
        primaryColor: string;
        wordmarkUrl: string;
    };
    email: {
        barBelowWordmarkBgColor: string;
        boxBehindButtonBgColor: string;
        boxBehindButtonFgColor: string;
        buttonBgColor: string;
        buttonFgColor: string;
    };
    customAuthErrorStrings: any;
    fcraHtml: any;
    idoConfirmationMessage: any;
    idoStatusMessages: any;
    postHooks: any;
    requestTimeoutSettings: any;
}

export interface RelyingPartyFilters {
    dateSortAsc?: boolean;
    seqId?: number;
    limit?: number;
    startDate?: number;
    endDate?: number;
    textSearchPattern?: string;
    sentBy?: string;
    summary?: string;
}

export interface RelyingPartySearchAsa {
    attributeSource: string;
    attributeType: string;
    exp: number;
    iat: number;
    id: string;
    iss: string;
    stage: AsaStatusType;
    status: string;
    statusDetail: string | null;
    sub: string;
    subscription: boolean;
    updateFrequencyDays: null;
}

export interface AttributeData {
    displayName: string | null;
    status: string;
    type: string;
    value: any;
}

export interface RelyingPartyRequestDetails {
    id: string;
    description: string;
    summary: string;
    completedAt: number;
    createdAt: number;
    createdBy: string;
    idOwner: {
        domain: string;
        email: string;
        id: string;
    };
    notes: string;
    relyingPartyId: string;
    seqId: number;
    status: RelyingPartyRequestStatusType;
    templateId: string | null;
    attributeSharingAgreements: RelyingPartySearchAsa[];
    attributes: AttributeData[];
}

export interface RelyingPartySearchRequest {
    id: string;
    description: string;
    summary: string;
    completedAt: number;
    createdAt: number;
    createdBy: string;
    idOwner: {
        domain: string;
        email: string;
        id: string;
    };
    idOwnerId: string;
    notes: string;
    recipientEmail: string;
    relyingPartyId: string;
    seqId: number;
    stage: AsaStatusType;
    status: RelyingPartyRequestStatusType;
    statusDetail: string | null;
    templateId: string | null;
    attributeSharingAgreements: RelyingPartySearchAsa[];
}

export interface RelyingPartySearchResult {
    requests: RelyingPartySearchRequest[];
}

export interface AttributeDefinition {
    attributePath: string;
    category: string;
    group: string;
    mtime: {
        $objectType: 'utctime';
        timestamp: number;
    };
    readableAttributeName: string;
    value: string;
}

export interface AttributeDefinitionList {
    attributeTypes: AttributeDefinition[];
}

export enum InsuranceComplianceStatus {
    new = 'NEW',
    pending = 'PENDING',
    compliant = 'COMPLIANT',
    nonCompliant = 'NON_COMPLIANT',
}

export enum InsuranceComplianceStatusLabel {
    new = 'New',
    pending = 'Pending',
    compliant = 'Compliant',
    nonCompliant = 'Non-Compliant',
}

export enum InsuranceVerificationStatus {
    complete = 'COMPLETE',
    exhausted = 'EXHAUSTED',
    noInsuredResponse = 'NO_INSURED_RESPONSE',
    noBrokerResponse = 'NO_BROKER_RESPONSE',
    processing = 'PROCESSING',
    pendingInsuredSubmission = 'PENDING_INSURED_SUBMISSION',
    pendingBrokerSubmission = 'PENDING_BROKER_SUBMISSION',
    actionsReview = 'ACTIONS_REVIEW',
}

export enum InsuranceVerificationStatusLabel {
    complete = '',
    exhausted = 'Exhausted',
    noInsuredResponse = 'No Insured Response',
    noBrokerResponse = 'No Broker Response',
    processing = 'Processing',
    pendingInsuredSubmission = 'Pending Insured Submission',
    pendingBrokerSubmission = 'Pending Broker Submission',
    actionsReview = 'Actions Review',
}

export interface InsuranceInsuredField {
    id: string;
    key: string;
    name: string;
    required: boolean;
    description: string | null;
    schema: JsonSchema;
    createdAt: string;
}

export enum InsuranceCoverageType {
    brokerInfo = 'BROKER_INFO',
    businessAutoLiability = 'BUSINESS_AUTO_LIABILITY',
    commercialCrimeLiability = 'COMMERCIAL_CRIME_LIABILITY',
    commercialGeneralLiability = 'COMMERCIAL_GENERAL_LIABILITY',
    commercialProperty = 'COMMERCIAL_PROPERTY',
    cyberLiability = 'CYBER_LIABILITY',
    employeeDishonesty = 'EMPLOYEE_DISHONESTY',
    employmentPracticesLiability = 'EMPLOYMENT_PRACTICES_LIABILITY',
    garageKeepersLiability = 'GARAGE_KEEPERS_LIABILITY',
    garageLiability = 'GARAGE_LIABILITY',
    inlandMarineCommercialAuto = 'INLAND_MARINE_COMMERCIAL_AUTO',
    occupationalAccident = 'OCCUPATIONAL_ACCIDENT',
    privatePassengerAuto = 'PRIVATE_PASSENGER_AUTO',
    professionalLiability = 'PROFESSIONAL_LIABILITY',
    sexualAbuseAndMolestationLiability = 'SEXUAL_ABUSE_AND_MOLESTATION_LIABILITY',
    thirdPartyFidelityBond = '3RD_PARTY_FIDELITY_BOND',
    umbrellaExcessLiability = 'UMBRELLA_EXCESS_LIABILITY',
    workersCompensation = 'WORKERS_COMPENSATION',
    cargoLiability = 'CARGO_LIABILITY',
    contingentAutoLiability = 'CONTINGENT_AUTO_LIABILITY',
    nonTruckingLiability = 'NON_TRUCKING_LIABILITY',
    buildersRisk = 'BUILDERS_RISK',
    cropInsurance = 'CROP_INSURANCE',
    dwelling = 'DWELLING',
    farmowners = 'FARMOWNERS',
    fleet = 'FLEET',
    homeowners = 'HOMEOWNERS',
    lifeInsurance = 'LIFE_INSURANCE',
    lossOfIncome = 'LOSS_OF_INCOME',
    floodInsurance = 'FLOOD_INSURANCE'
}

export enum InsuranceExceptionLevel {
    insured = 'INSURED',
    coverageType = 'COVERAGE_TYPE',
    coverageCriterion = 'COVERAGE_CRITERION',
}

export enum InsuranceExceptionType {
    permanent = 'PERMANENT',
}

export interface InsuranceExceptionInput {
    type: InsuranceExceptionType;
    level: InsuranceExceptionLevel;
    status: InsuranceComplianceStatus | null;
    until?: string | null;
    coverageType?: InsuranceCoverageType;
    criterionId?: string;
}

export interface InsuranceException extends InsuranceExceptionInput {
    id: string;
}

export interface InsuranceInsuredException {
    coverageType: InsuranceCoverageType;
    until: string | null;
    status?: InsuranceComplianceStatus | null;
}

export interface InsuranceSheetData {
    coverageTypes: { coverageType: InsuranceCoverageType | 'BROKER_INFO', attributes: string[] }[];
}

export interface InsuranceCollateral {
    description: string;
    category: string;
    limitRequired: number;
    uniqueIdentifier?: string;
    maximumDeductible?: number;
    id?: string;
}

export enum InsuranceSchemaDisplayFormat {
    checkbox = 'CHECKBOX',
    collateral = 'COLLATERAL',
    collaterals = 'COLLATERALS',
    currency = 'CURRENCY',
    days = 'DAYS',
    hidden = 'HIDDEN',
    insuredField = 'INSURED_FIELD',
}

export type InsuranceInsuredFieldType =
    string | number | boolean | undefined | string[] | InsuranceCollateral[];

export type InsuranceInsuredFieldValue = Record<string, InsuranceInsuredFieldType>;

export interface InsuranceInsuredBase {
    displayName: string;
    contactEmail: string;
    contactName?: string;
    contactPhoneNumber?: string | null;
    insuredFields?: InsuranceInsuredFieldValue;
}

export interface InsuranceInsuredInput extends InsuranceInsuredBase {
    exceptions?: Record<string, string>;
}

export interface InsuranceInsuredInputDataModel extends InsuranceInsuredBase {
    exceptions?: InsuranceInsuredException[];
}

export interface InsuranceComplianceStatusException {
    category: InsuranceExceptionLevel;
    type: string;
    id: string;
}

export type InsuranceStatusOverride = Exclude<InsuranceException, 'until'>;

export interface InsuranceComplianceStatusMetadata {
    statusOverride: InsuranceStatusOverride;
}

export interface InsuranceInsuredEffectiveGroup {
    id: string;
    displayName: string;
    complianceStatus: InsuranceComplianceStatus;
}

export interface InsuranceInsured {
    id: string;
    displayName: string;
    contactName: string;
    contactEmail: string;
    contactPhoneNumber: string;
    nextExpiration: string | null;
    complianceStatus: InsuranceComplianceStatus;
    complianceStatusMetadata: InsuranceComplianceStatusMetadata | null;
    verificationStatus: InsuranceVerificationStatus;
    createdAt: string;
    active: boolean;
    paused: boolean;
    insuredFields: InsuranceInsuredFieldValue | null;
    effectiveGroup: InsuranceInsuredEffectiveGroup[] | null;
}

export interface InsuranceAddInsuredsFailure {
    element: InsuranceInsuredInputDataModel;
    error: {
        message: string;
        error: OperationError;
    };
}

export interface InsuranceInsuredPostResponse {
    successCount: number;
    failureCount: number;
    totalCount: number;
    successes: InsuranceInsured[];
    failures: InsuranceAddInsuredsFailure[];
}

export interface InsuranceInsuredUpsertResponse {
    successCount: number;
    failureCount: number;
    totalCount: number;
    successes: InsuranceInsured[];
    failures: (InsuranceInsuredInputDataModel | InsuranceInsured)[];
}

export interface Change<T> {
    oldValue: T;
    newValue: T;
}

export interface InsuredPatchResult {
    id: string;
    displayName?: Change<string>;
    contactName?: Change<string>;
    contactEmail?: Change<string>;
    contactPhoneNumber?: Change<string>;
    nextExpiration?: Change<string>;
    complianceStatus?: Change<InsuranceComplianceStatus>;
    verificationStatus?: Change<InsuranceVerificationStatus>;
    active?: Change<boolean>;
    paused?: Change<boolean>;
    insuredFields?: Record<string, Change<InsuranceInsuredFieldType>>;
}

export interface InsuranceInsuredPatchResponse {
    successCount: number;
    failureCount: number;
    totalCount: number;
    successes: InsuredPatchResult[];
    failures: { element: InsuredPatchResult, error: { message: string, error: string } }[];
}

export interface InsuranceInsuredStatistics {
    compliance: {
        forcePlaced: number;
        compliant: number;
        nonCompliant: number;
        pending: number;
        new: number;
    };
    expiration: {
        within30Days: number;
    };
}

export interface InsuranceDashboardConfiguration {
    insuredFields: string[];
}

export interface InsuranceSendCoiRequestSuccess {
    insuredId: string;
    verificationRequest: any;
}

export interface InsuranceSendCoiRequestResponse {
    totalCount: number;
    successCount: number;
    failureCount: number;
    successes: InsuranceSendCoiRequestSuccess[];
    failures: string[];
}

export interface InsuranceCarrier {
    naicNumber: string | null;
    name: string | null;
    amBestFinancialStrengthRating: string | null;
    amBestFinancialSizeCategory: number | null;
}

export interface InsurancePolicy {
    carrier: InsuranceCarrier;
    insured: string;
    policyNumber: string;
    currency: string;
    effectiveDate: string;
    expirationDate: string;
}

export interface InsuranceEvaluationResult {
    id: string;
    insuredId: string;
    evaluatedEntityId?: string;
    coverageCriteriaGroupId: string;
    criterionId: string;
    criterionName: string;
    result: InsuranceComplianceStatus;
    complianceStatusMetadata: InsuranceComplianceStatusMetadata | null;
    failures: string[];
    usedFields: string[];
    evaluatedAt: string;
    coverageId?: string;
    verificationId?: string;
    nonComplianceMessage?: string;
    useDuringComplianceCalculation: boolean;
}

export enum InsuranceAdditionalInsuredEndorsementType {
    unknown = 'UNKNOWN',
    additionalInsured = 'ADDITIONAL_INSURED',
}

export interface InsuranceAdditionalInsuredEndorsementDetails {
    additionalInsuredType: string;
    additionalInsuredName: string;
}

export interface InsuranceAdditionalInsuredEndorsement {
    endorsementType: InsuranceAdditionalInsuredEndorsementType;
    formNumber: string;
    policyNumber: string;
    details: InsuranceAdditionalInsuredEndorsementDetails | null;
}

export interface InsuranceInsuredCoverage {
    id: string;
    coverageType: InsuranceCoverageType;
    policy: InsurancePolicy;
    requestId: string;
    details: any;
    createdAt: string;
    modifiedAt: string;
    complianceStatus: InsuranceComplianceStatus;
    endorsements: InsuranceAdditionalInsuredEndorsement[];
}

export interface InsuranceInsuredCoverageDetails {
    coverageType: InsuranceCoverageType;
    complianceStatus: InsuranceComplianceStatus | null;
    complianceStatusMetadata: InsuranceComplianceStatusMetadata | null;
    verificationStatus: InsuranceVerificationStatus;
    coverage: InsuranceInsuredCoverage | null;
    evaluationResults: InsuranceEvaluationResult[];
}

export interface InsuranceEvaluationRule {
    coverageTypes: InsuranceCoverageType[];
}

export enum InsuranceCoverageCriteriaGroupType {
    default = 'DEFAULT',
    collateral = 'COLLATERAL',
}

export interface InsuranceCoverageCriteriaGroupInput {
    displayName: string;
    active: boolean;
    ccgType: InsuranceCoverageCriteriaGroupType;
    numberOfCoverageTypes: number | null;
    numberOfInsuredsAssigned: number | null;
    assignmentRule: RulesLogic | null;
    countryCode: string | null;
    evaluationRule?: {
        coverageTypes: InsuranceCoverageType[];
    };
}

export interface InsuranceCoverageCriteriaGroup extends InsuranceCoverageCriteriaGroupInput {
    id: string;
    countryCode: string;
}

export interface InsuranceInsuredCoverageCriteriaGroup {
    id: string;
    countryCode: string;
    displayName: string;
    active: boolean;
    coverageTypes: InsuranceCoverageType[];
    complianceStatus: InsuranceComplianceStatus;
    evaluationRule: InsuranceEvaluationRule | null;
}

export type InsuranceEffectiveGroup = string[];

export type InsuranceCriterionSchema = JsonSchema & { displayFormat?: InsuranceSchemaDisplayFormat };

export interface InsuranceCoverageCriterionField {
    value: any;
    schema: InsuranceCriterionSchema;
}

export interface InsuranceCoverageCriterionField {
    value: any;
    schema: InsuranceCriterionSchema;
}

export interface InsuranceCoverageCriterionEvaluator {
    references: Record<string, InsuranceCoverageCriterionField>;
}

export enum InsuranceCriteriaCondition {
    equals = 'Equals',
    greaterThan = 'Greater than',
    greaterThanOrEqualTo = 'Greater than or equal to',
    lessThanOrEqualTo = 'less than or equal to',
    lessThan = 'less than',
}

interface InsuranceCriteriaEnumValues {
    reference: string;
    values: Record<string, string>;
}

export interface ConditionalValueReference {
    flagValueReference: string;
    flagEnabledReference: string;
    flagDisabledReference: string;
}

export interface InsuranceCriteriaDisplayMeta {
    title: string;
    condition: InsuranceCriteriaCondition;
    requiredValueReference?: string;
    conditionalValueReference?: ConditionalValueReference | null;
    nonInputCriterionText?: string;
    informationText?: string;
    enumValues?: InsuranceCriteriaEnumValues[];
}

export interface InsuranceCoverageCriterionBase {
    coverageType: InsuranceCoverageType;
    field: string;
    evaluator: InsuranceCoverageCriterionEvaluator;
    verify: boolean;
    emailMessage: string;
    deprecated: boolean;
}

export interface InsuranceCoverageCriterionInput extends InsuranceCoverageCriterionBase {
    displayMetadata?: InsuranceCriteriaDisplayMeta;
}

export interface InsuranceCoverageCriterionTemplate extends InsuranceCoverageCriterionBase {
    default: boolean;
    displayMetadata: InsuranceCriteriaDisplayMeta;
}

export interface InsuranceCoverageCriterion extends InsuranceCoverageCriterionBase {
    id: string;
}

export interface InsuranceCoverageModel {
    coverageType: InsuranceCoverageType;
    version: number;
    schema: any;
    label: string;
}

export interface InsuranceCoverageCriterionMessage {
    nonComplianceMessage: string;
}

export interface PatchInsuranceCriterionUpdate<T> {
    op: PatchOperationType.update;
    id: string;
    value: T;
}

export interface PatchInsuranceCriterionCreate<T> {
    op: PatchOperationType.create;
    value: T;
}

export interface PatchInsuranceCriterionDelete {
    op: PatchOperationType.delete;
    id: string;
}

export type InsuranceCriterionPatch =
    PatchInsuranceCriterionUpdate<InsuranceCoverageCriterion>
    | PatchInsuranceCriterionCreate<InsuranceCoverageCriterionInput>
    | PatchInsuranceCriterionDelete;

export interface InsuranceVerificationRequest {
    verificationId: string;
    requestId: string;
    insuredId: string;
    createdAt: string;
    completedAt: string | null;
    inputReceivedAt: string | null;
    coverageTypes: string[];
    cancelledCoverageTypes: string[];
    rprSummary: string | null;
    rprDescription: string | null;
    verificationType: string;
    parentId: string | null;
    verificationRequestStatus: string;
    createdAutomatically: boolean;
    timeoutAt: string | null;
    issuedAt: string;
}

export type InsuranceCoverageTypesPerCountry = Record<string, { coverageTypes: InsuranceCoverageType[] }>;

export interface InsuranceConfig {
    distinguishedName: string;
    enrolledAt: string; // ISO Date
    displayName: string;
    commonName: string;
    certificateHolderAddress: string | null; // HTML
}

export type InsuranceConfigInput = Omit<InsuranceConfig, 'commonName' | 'distinguishedName' | 'enrolledAt'>;

export interface InsuranceVerificationRequestConfiguration {
    summaryTemplate: string;
    descriptionTemplate: string;
}

export enum VerificationRequestType {
    expiring = 'EXPIRING',
    new = 'NEW',
    nonCompliant = 'NON_COMPLIANT',
}

export interface InsuranceRequestsConfig {
    collateralsEvaluationEnabled: boolean;
    enableEvaluationEngine: boolean;
    expirationNumberOfDays: number;
    notificationTemplateId: string; // UUID
    verificationDataSource: string; // FIXME: Enum?
    verificationRequestConfigurations: Record<VerificationRequestType, InsuranceVerificationRequestConfiguration>;
    coiRequirementsLink: string | null;
    fulfillmentUrl: string | null;
}

export enum InsuranceInsuredActionMatchType {
    custom = 'CUSTOM',
    insured = 'INSURED',
    relyingParty = 'RELYING_PARTY',
}

export enum InsuranceInsuredActionType {
    endorsementFormNumberV1 = 'EndorsementFormNumberCategorizationV1',
    entityNameMatchingV1 = 'EntityNameMatchingV1',
    evaluatedEntityMappingV1 = 'EvaluatedEntityMappingV1',
}

export interface InsuranceCollateralEntity {
    description: string;
    uniqueIdentifier: string;
}

export type InsuranceEvaluatedEntity = InsuranceCollateralEntity;

export interface InsuranceEntityMappingProvidedEntityDetails {
    verificationId: string;
    relyingPartyRequestId: string;
    coverageType: InsuranceCoverageType;
    providedEntity: InsuranceEvaluatedEntity;
}

export interface InsuranceInsuredEntityNameMatchingAction {
    $action: InsuranceInsuredActionType;
    field: string;
    given: any;
    matchType: InsuranceInsuredActionMatchType;
    comparedTo: string;
}

export interface InsuranceInsuredEndorsementFormNumberAction {
    $action: InsuranceInsuredActionType;
    formNumber: string;
}

export interface InsuranceEvaluatedEntityMappingAction {
    $action: InsuranceInsuredActionType;
    evaluatedEntityId: string;
    requiredEntity: InsuranceEvaluatedEntity;
    providedEntities: InsuranceEntityMappingProvidedEntityDetails[];
}

interface InsuranceInsuredActionDetailsBase {
    id: string;
    insuredId?: string;
    evaluationResults: InsuranceEvaluationResult[];
    createdAt: string;
    completedAt: string | null;
}

export interface InsuranceInsuredEntityMatchingActionDetails extends InsuranceInsuredActionDetailsBase {
    action: InsuranceInsuredEntityNameMatchingAction;
}

export interface InsuranceInsuredEndorsementActionDetails extends InsuranceInsuredActionDetailsBase {
    action: InsuranceInsuredEndorsementFormNumberAction;
}

export interface InsuranceEvaluatedEntityMappingActionDetails extends InsuranceInsuredActionDetailsBase {
    action: InsuranceEvaluatedEntityMappingAction;
}

export type InsuranceInsuredActionDetails =
    InsuranceInsuredEntityMatchingActionDetails
    | InsuranceInsuredEndorsementActionDetails
    | InsuranceEvaluatedEntityMappingActionDetails;

export interface InsuranceEntityMatchingActionResolveInput {
    // TODO[PRODUCT-18609]: enable this and include in payload, collaborate with BE to confirm the changes
    // $action: InsuranceInsuredActionType.entityNameMatchingV1;
    accepted: boolean;
}

export interface InsuranceEndorsementActionResolveInput {
    // TODO[PRODUCT-18609]: enable this and include in payload, collaborate with BE to confirm the changes
    // $action: InsuranceInsuredActionType.endorsementFormNumberV1;
    category: string | null;
}

export interface InsuranceEvaluatedEntityMappingResolveInput {
    $action: InsuranceInsuredActionType.evaluatedEntityMappingV1;
    accepted: boolean;
    providedEntity?: InsuranceEvaluatedEntity;
}

export type InsuranceActionResolveInput =
    InsuranceEntityMatchingActionResolveInput
    | InsuranceEndorsementActionResolveInput
    | InsuranceEvaluatedEntityMappingResolveInput;

export interface InsuranceInsuredActionsQuery {
    skip?: number;
    limit?: number;
    sort?: string | null;
    pending?: boolean;
}

export interface EnumLabelMapping {
    /**
     * A key is an enums token and value is human-readable label of an enum
     */
    [key: string]: string;
}
