import {
    InsuranceCoverageCriterion,
    InsuranceCoverageCriterionField,
    InsuranceCoverageCriterionInput,
    InsuranceCoverageCriterionTemplate,
} from '@evidentid/rpweb-api-client/types';
import { findCriterionTemplate } from '@/modules/decisioning-criteria/utils/coverageCriteria';
import { isArray } from '@evidentid/json-schema/schemaChecks';
import { JsonSchemaArray } from '@evidentid/json-schema/interfaces/JsonSchema';

function castToArray(
    criterionReference: InsuranceCoverageCriterionField,
    templateReference: InsuranceCoverageCriterionField,
): InsuranceCoverageCriterionField {
    const criterionSchema = criterionReference.schema;
    return (
        isArray(templateReference.schema)
        && ((templateReference.schema as JsonSchemaArray<any>).items).type === (criterionSchema as any).type
    )
        ? {
            ...criterionReference,
            value: criterionReference.value == null ? [] : [ criterionReference.value ],
            schema: {
                ...templateReference.schema,
            },
        }
        : criterionReference;
}

function castReference(
    criterionReference: InsuranceCoverageCriterionField,
    templateReference: InsuranceCoverageCriterionField,
): InsuranceCoverageCriterionField {
    return castToArray(criterionReference, templateReference);
}

function castCriterionReferenceTypeFromTemplate(
    criterion: InsuranceCoverageCriterion | InsuranceCoverageCriterionInput,
    template: InsuranceCoverageCriterionTemplate,
): InsuranceCoverageCriterion | InsuranceCoverageCriterionInput {
    const syncedReferences = Object.keys(criterion.evaluator.references).reduce((acc, key) => ({
        ...acc,
        [key]: castReference(criterion.evaluator.references[key], template.evaluator.references[key]),
    }), {});
    return { ...criterion, evaluator: { ...criterion.evaluator, references: syncedReferences } };
}

function addMissingReferencesFromTemplate(
    criterion: InsuranceCoverageCriterion | InsuranceCoverageCriterionInput,
    template: InsuranceCoverageCriterionTemplate,
): InsuranceCoverageCriterion | InsuranceCoverageCriterionInput {
    const syncedReferences = Object.keys(template.evaluator.references).reduce((acc, key) => ({
        ...acc,
        [key]: criterion.evaluator.references[key] ?? template.evaluator.references[key],
    }), {});
    return { ...criterion, evaluator: { ...criterion.evaluator, references: syncedReferences } };
}

function syncSchemaFromTemplate(
    criterion: InsuranceCoverageCriterion | InsuranceCoverageCriterionInput,
    template: InsuranceCoverageCriterionTemplate,
): InsuranceCoverageCriterion | InsuranceCoverageCriterionInput {
    const syncedReferences = Object.keys(template.evaluator.references).reduce((acc, key) => ({
        ...acc,
        [key]: { ...criterion.evaluator.references[key], schema: template.evaluator.references[key].schema },
    }), {});
    return { ...criterion, evaluator: { ...criterion.evaluator, references: syncedReferences } };
}

function syncCriterionWithTemplate(
    criterion: InsuranceCoverageCriterion | InsuranceCoverageCriterionInput,
    template: InsuranceCoverageCriterionTemplate,
): InsuranceCoverageCriterion | InsuranceCoverageCriterionInput {
    let syncCriterion = addMissingReferencesFromTemplate(criterion, template);
    syncCriterion = castCriterionReferenceTypeFromTemplate(syncCriterion, template);
    syncCriterion = syncSchemaFromTemplate(syncCriterion, template);
    return syncCriterion;
}

export function syncCriteriaWithTemplates(
    criteria: (InsuranceCoverageCriterion | InsuranceCoverageCriterionInput)[],
    templates: InsuranceCoverageCriterionTemplate[],
): (InsuranceCoverageCriterion | InsuranceCoverageCriterionInput)[] {
    /* this function is for backward compatibility. if new property introduced in template but BE does not perform a
    migration on existing criteria, UI will break thus we want to sync it with template default */
    return criteria.map(
        (criterion) => {
            const template = findCriterionTemplate(criterion, templates);
            return template
                ? syncCriterionWithTemplate(criterion, template)
                : criterion;
        });
}
