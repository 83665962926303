import { Component } from 'vue';
import type RpWebApiClient from '@evidentid/rpweb-api-client';
import { VerificationRequestType } from '@evidentid/rpweb-api-client/types';
import type ConfigApiClient from '@evidentid/config-api-client';
import { NotificationName } from '@evidentid/config-api-client/types';
import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import { createPersistingErrorModule } from '@evidentid/dashboard-commons/modules/persisting-error';
import { createSnackbarModule } from '@evidentid/dashboard-commons/modules/snackbar';
import NotificationsEmailConfiguration from './views/NotificationsEmailConfiguration.vue';
import NotificationsEmailTemplates from './views/NotificationsEmailTemplates.vue';
import BrandingSettings from './views/BrandingSettings.vue';
import SupportContactInformation from './views/SupportContactInformation.vue';
import SetRemindersProcedure from './procedures/SetRemindersProcedure.vue';
import EditEmailTemplateProcedure from './procedures/EditEmailTemplateProcedure.vue';
import vuex from './vuex';
import SendTestEmailProcedure from '@/modules/notifications-configuration/procedures/SendTestEmailProcedure.vue';

interface NotificationsConfigurationModuleExpectedViews {
    Page: Component;
}

export function createNotificationsConfigurationModule() {
    return createModuleBuilder()
        .demandOptions<{
            views: NotificationsConfigurationModuleExpectedViews;
        }>()
        .demandContext<{
            rpweb: RpWebApiClient;
            configClient: ConfigApiClient;
        }>()
        .registerRoutes(() => [
            {
                path: '/:rpId?/admin/notifications/email-settings',
                name: 'notifications-email-settings',
                component: NotificationsEmailConfiguration as any,
                meta: { title: 'Notification - Email Configuration' },
            },
            {
                path: '/:rpId?/admin/notifications/email-templates',
                name: 'notifications-email-templates',
                component: NotificationsEmailTemplates as any,
                meta: { title: 'Notification - Email Templates' },
            },
            {
                path: '/:rpId?/admin/branding',
                name: 'branding-settings',
                component: BrandingSettings as any,
                meta: { title: 'Branding Settings' },
            },
            {
                path: '/:rpId?/admin/support',
                name: 'support-contact',
                component: SupportContactInformation as any,
                meta: { title: 'Support Contact Information' },
            },
        ])
        .injectVue((app) => ({
            $notificationsConfiguration: {
                Page: app.options.views.Page,
            },
        }))
        .demand<ReturnType<typeof createPersistingErrorModule>>()
        .demand<ReturnType<typeof createSnackbarModule>>()
        .registerVuex({ notificationsConfiguration: vuex.instantiateModule })
        .registerProcedures<{
            setNotificationReminders: { rpName: string, type: NotificationName };
            editNotificationEmailTemplate: { rpName: string, type: VerificationRequestType };
            sendTestEmail: { rpName: string, type: VerificationRequestType };
        }>({
            setNotificationReminders: SetRemindersProcedure,
            editNotificationEmailTemplate: EditEmailTemplateProcedure,
            sendTestEmail: SendTestEmailProcedure,
        })
        .end();
}

export type NotificationsConfigurationModule = ReturnType<typeof createNotificationsConfigurationModule>;
