var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"CoverageCriterionSettings"},[(_vm.isInformationBannerVisible)?_c('div',{staticClass:"CoverageCriterionSettings__informationBanner"},[(_vm.template.displayMetadata.informationText)?_c('Alert',{attrs:{"type":"regular"}},[_c('img',{staticClass:"CoverageCriterionSettings__bannerIcon",attrs:{"src":_vm.infoCircle,"alt":"info"}}),_c('span',[_vm._v(_vm._s(_vm.template.displayMetadata.informationText))])]):_vm._e(),(_vm.isSpecialConditionSetsCriterion)?_c('GeneralAggregateLimitBreakdownBanner',{attrs:{"criterion":_vm.localCriterion,"insured-fields":_vm.insuredFields}}):_vm._e()],1):_vm._e(),(_vm.orderedTemplateReferences.length > 0)?_c('div',{staticClass:"CoverageCriterionSettings__criterionFields"},[_c('div',{staticClass:"CoverageCriterionSettings__table",class:{'CoverageCriterionSettings__table--hasSubstitutable': _vm.hasSubstitutableReference}},[_c('div',{staticClass:"CoverageCriterionSettings__tableHeader"},[_c('header',[_vm._v("Settings")]),_c('header',[_vm._v("Required")]),(_vm.hasSubstitutableReference)?_c('header',{staticClass:"CoverageCriterionSettings__tableActionHeader"},[_vm._v(" Set Limit to Insured Field ")]):_vm._e()]),(_vm.controller)?_c('div',{staticClass:"CoverageCriterionSettings__tableBody"},[_c('JsonSchemaCustomForm',{attrs:{"controller":_vm.controller},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var fields = ref.fields;
return _vm._l((fields),function(field,key){return _c('div',{key:key},[(_vm.filteredReferenceByStaticNameAndValue.includes(key))?_c('div',{staticClass:"CoverageCriterionSettings__row",class:{
                                'CoverageCriterionSettings__row--nonSubstitutable':
                                    !_vm.isArrayWithObject(field.form.schema) &&
                                    !_vm.isSubstitutableSchema(field.form.schema),
                                'CoverageCriterionSettings__row--substitutable':
                                    _vm.isSubstitutableSchema(field.form.schema),
                                'CoverageCriterionSettings__row--conditionSets':
                                    key === _vm.GeneralAggregateLimitReferences.conditionSets,
                            }},[_c('JsonSchemaCustomFormElement',_vm._b({attrs:{"field":field,"custom-component-input":_vm.getCustomComponent,"show-error":""},on:{"input":function($event){return _vm.onInput($event, key)}}},'JsonSchemaCustomFormElement',{ disallowedValues: _vm.disallowedValues[key] },false))],1):_vm._e()])})}}],null,false,4083311239),model:{value:(_vm.controller.value),callback:function ($$v) {_vm.$set(_vm.controller, "value", $$v)},expression:"controller.value"}})],1):_vm._e()])]):_vm._e(),(_vm.parsedEmailMessage)?_c('div',{staticClass:"CoverageCriterionSettings__emailMessage"},[_c('div',{staticClass:"CoverageCriterionSettings__emailMessageTitle"},[_vm._v(" Email Message ")]),_c('div',{staticClass:"CoverageCriterionSettings__emailMessageHelpText"},[_vm._v(" If Non-compliant, the Insured will receive the following message: ")]),_c('div',{staticClass:"CoverageCriterionSettings__emailMessageContent"},[_vm._v(" "+_vm._s(_vm.parsedEmailMessage)+" ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }