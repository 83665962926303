import {
    InsuredFilterCategory,
    InsuredFilters,
    InsuredStandardFiltersObject,
} from '@/modules/insured-filtering/types';
import { InsuranceComplianceStatus, InsuranceInsuredField } from '@evidentid/rpweb-api-client/types';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import { JsonSchemaType, RegularJsonSchema } from '@evidentid/json-schema/interfaces/JsonSchema';

const expiringSoonDays = 30;

export const categoryFilterMap = {
    [InsuredFilterCategory.compliantAndExpiringSoon]: {
        complianceStatus: InsuranceComplianceStatus.compliant,
        expiresAfterOrOn: moment().format('YYYY-MM-DD'),
        expiresBeforeOrOn: moment().add(expiringSoonDays, 'days').format('YYYY-MM-DD'),
    },
    [InsuredFilterCategory.compliantAndNotExpiringSoon]: {
        complianceStatus: InsuranceComplianceStatus.compliant,
        expiresAfterOrOn: moment().add(expiringSoonDays + 1, 'days').format('YYYY-MM-DD'),
    },
    [InsuredFilterCategory.nonCompliant]: {
        complianceStatus: InsuranceComplianceStatus.nonCompliant,
    },
    [InsuredFilterCategory.pendingAndNew]: {
        complianceStatus: `${InsuranceComplianceStatus.pending},${InsuranceComplianceStatus.new}`,
    },
};

export function getInsuredFieldQueryKey(insuredFieldKey: string): string {
    return `if_${insuredFieldKey}`;
}

export function getInsuredFieldKeyFromQueryKey(queryKey: string): string {
    return queryKey.replace('if_', '');
}

export function getCategoryByFilters(filter: InsuredFilters): InsuredFilterCategory | null {
    const { search, ...filterWithoutSearch } = filter;
    const foundEntry = Object.entries(categoryFilterMap).find(([ , value ]) => isEqual(value, filterWithoutSearch));
    return foundEntry ? foundEntry[0] as InsuredFilterCategory : null;
}

export function getInsuredStandardFilterKeys(): string[] {
    return Object.keys(new InsuredStandardFiltersObject());
}

export function getInsuredFieldFilterKeys(insuredFields: InsuranceInsuredField[]): string[] {
    return insuredFields.map((field) => getInsuredFieldQueryKey(field.key));
}

export function parseFiltersForApi(filters: InsuredFilters, insuredFields: InsuranceInsuredField[]): InsuredFilters {
    const { insuredFieldFilters, ...standardFilters } = filters;
    const parsedInsuredFieldFilters = Object.entries(insuredFieldFilters || {})
        .reduce((acc, [ key, val ]) => {
            const schema = insuredFields.find((field) =>
                field.key === getInsuredFieldKeyFromQueryKey(key))?.schema as RegularJsonSchema;
            let value = val;
            if (!schema || schema.type === JsonSchemaType.string || schema.type === JsonSchemaType.array) {
                value = JSON.stringify(value);
            }
            return { ...acc, [key]: value };
        }, {} as Record<string, string>);
    return Object.keys(parsedInsuredFieldFilters).length > 0
        ? { ...standardFilters, insuredFieldFilters: parsedInsuredFieldFilters }
        : { ...standardFilters };
}
