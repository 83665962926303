import omit from 'lodash/omit';
import JsonSchema, { JsonSchemaEnum, JsonSchemaType } from '@evidentid/json-schema/interfaces/JsonSchema';
import { InsuranceInsuredField } from '@evidentid/rpweb-api-client/types';
import { filterInsuredFieldsByValueType } from './filterInsuredFieldsByValueType';

export function convertInsuredFieldSchemaToEnumSchema(
    ifSchema: JsonSchema,
    insuredFields: InsuranceInsuredField[],
    insuredValueTypeFilterBy?: JsonSchemaType | null,
): JsonSchema {
    const filteredInsureds = filterInsuredFieldsByValueType(insuredFields, insuredValueTypeFilterBy);

    if (filteredInsureds.length === 0) {
        return ifSchema;
    }

    return {
        ...omit(ifSchema, [ 'format', 'type' ]),
        enum: filteredInsureds.map((field) => field.key),
        enumLabels: filteredInsureds.map((field) => field.name),
    };
}
