import { stringify as buildQuery } from 'query-string';
import noop from 'lodash/noop';
import chunk from 'lodash/chunk';
import omit from 'lodash/omit';
import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';
import b64 from 'urlsafe-base64';
import { PartialDeep } from 'type-fest';
import parseJsonConditionally from '@evidentid/universal-framework/parseJsonConditionally';
import createCustomXhrErrorFactory from '@evidentid/universal-framework/createCustomXhrErrorFactory';
import {
    AttributeDefinitionList,
    RelyingPartyFilters,
    RelyingPartyRequestDetails,
    RelyingPartySearchResult,
    RelyingPartySettings,
    RelyingPartySignature,
    InsuranceInsured,
    InsuranceInsuredPostResponse,
    InsuranceInsuredField,
    InsuranceInsuredUpsertResponse,
    InsuranceComplianceStatus,
    InsuranceInsuredStatistics,
    InsuranceDashboardConfiguration,
    InsuranceInsuredFieldValue,
    InsuranceInsuredPatchResponse,
    InsuranceInsuredInputDataModel,
    InsuranceSheetData,
    InsuranceSendCoiRequestResponse,
    InsuranceInsuredCoverage,
    InsuranceInsuredCoverageDetails,
    InsuranceCoverageCriteriaGroup,
    InsuranceCoverageCriterionInput,
    InsuranceCoverageCriterion,
    InsuranceCoverageCriterionTemplate,
    InsuranceVerificationRequest,
    InsuranceCoverageCriteriaGroupInput,
    InsuranceCoverageTypesPerCountry,
    InsuranceConfig,
    InsuranceConfigInput,
    InsuranceRequestsConfig,
    InsuranceInsuredActionDetails,
    InsuranceInsuredActionsQuery,
    InsuranceExceptionInput,
    InsuranceActionResolveInput,
    InsuranceInsuredCoverageCriteriaGroup,
    InsuranceEffectiveGroup,
    InsuranceCoverageCriterionMessage,
    InsuranceCoverageModel,
    EnumLabelMapping,
} from './types';
import url from '@evidentid/universal-framework/url';
import { CollateralTabCollateral } from './models/CollateralTabCollateral.model';

const statusErrorFactories: Record<number, (xhr?: XMLHttpRequest) => Error> = {
    400: createCustomXhrErrorFactory('bad-request', 'Invalid data passed'),
    401: createCustomXhrErrorFactory('unauthorized', 'You are not authorized for selected operation'),
    403: createCustomXhrErrorFactory('forbidden', 'You are not authorized for selected operation'),
    404: createCustomXhrErrorFactory('not-found', 'The requested resource is not found'),
    440: createCustomXhrErrorFactory('session-expired', 'Your session has expired'),
};

// A key is an enum category
export type CategorizedEnumLabelsResult = Record<string, EnumLabelMapping[]>;

export interface RelyingPartiesQuery {
    filter?: string;
}

interface TermsAndConditionsStatus {
    signedBy: string;
    signedAt: number;
}

interface InsuranceInsuredsQuery {
    skip?: number;
    limit?: number;
    sort?: string | null;
    active?: boolean;
    paused?: boolean;
    search?: string | null;
    complianceStatus?: string;
    expiresBeforeOrOn?: string;
    expiresAfterOrOn?: string;
    displayName?: string;
    contactName?: string;
    contactEmail?: string;
    contactPhoneNumber?: string;
    insuredFieldFilters?: Record<string, string>;
}

export interface InsuranceInsuredsSearchResult {
    insureds: InsuranceInsured[];
    count: number;
}

export interface InsuranceInsuredActionsSearchResult {
    actions: InsuranceInsuredActionDetails[];
    count: number;
}

export enum PatchOperationType {
    replace = 'REPLACE',
    reset = 'RESET',
    update = 'UPDATE',
    create = 'CREATE',
    delete = 'DELETE',
}

export interface PatchReset {
    op: PatchOperationType.reset;
}

export interface PatchReplace<T> {
    op: PatchOperationType.replace;
    newValue: T;
}

export interface PatchUpdate<T> {
    op: PatchOperationType.update;
    oldValue: T;
    newValue: T;
}

export type PatchOperation<T> = PatchUpdate<T> | PatchReplace<T> | PatchReset;

export interface InsuredPatch {
    id: string;
    nextExpiration?: PatchOperation<string | null>;
    complianceStatus?: PatchOperation<InsuranceComplianceStatus>;
    active?: PatchOperation<boolean>;
    paused?: PatchOperation<boolean>;
    insuredFields?: PatchOperation<InsuranceInsuredFieldValue>;
}

interface ApiTokens {
    accessToken: string | null;
    idToken: string | null;
}

class RpWebApiClient {
    protected baseUrl: string;
    protected getTokens: () => Promise<ApiTokens> = () => Promise.resolve({ accessToken: null, idToken: null });

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl.replace(/\/+$/, '');
    }

    public setTokens(fn: (() => Promise<ApiTokens>) | (() => ApiTokens)) {
        this.getTokens = () => Promise.resolve(fn());
    }

    public async getTermsAndConditionsSign(rp: string): Promise<TermsAndConditionsStatus | null> {
        const result = await this.request('GET', url`/relyingParties/${rp}/settings/termsAndConditions`);
        return (result?.tcSigned)
            ? { signedAt: result.tcSigned.timestamp, signedBy: result.tcSigner }
            : null;
    }

    public async signTermsAndConditions(rp: string): Promise<void> {
        await this.request('PUT', url`/relyingParties/${rp}/settings/termsAndConditions`);
    }

    public async getAvailableRelyingParties(options?: RelyingPartiesQuery): Promise<RelyingPartySignature[]> {
        const query = buildQuery({
            filter: options?.filter || undefined,
        });
        const response = await this.request('GET', `/relyingParties?${query}`);
        return response.relyingParties;
    }

    public async getRelyingPartySettings(rp: string): Promise<RelyingPartySettings> {
        return this.request('GET', url`/relyingParties/${rp}/settings`);
    }

    public async getRelyingPartyRequests(rp: string, options: RelyingPartyFilters): Promise<RelyingPartySearchResult> {
        const rpRequestParams = b64.encode(btoa(JSON.stringify({
            ...options,
            dateSortAsc: Boolean(options.dateSortAsc),
        })));
        const requestUrl = url`/relyingParties/${rp}/requests?rpRequestParams=${rpRequestParams}&bust=${Date.now()}`;
        return await this.request<RelyingPartySearchResult>('GET', requestUrl, undefined, false);
    }

    public async getRelyingPartyRequestDetails(rp: string, id: string): Promise<RelyingPartyRequestDetails> {
        return await this.request<RelyingPartyRequestDetails>('GET', url`/relyingParties/${rp}/requests/${id}`);
    }

    public async getAttributeTypes(rp: string): Promise<AttributeDefinitionList> {
        return await this.request('GET', url`/relyingParties/${rp}/attributeTypes`, undefined, false);
    }

    public async getInsuranceInsureds(rp: string, options: InsuranceInsuredsQuery):
        Promise<InsuranceInsuredsSearchResult> {
        const insuredFieldFilters = options.insuredFieldFilters || {};
        const qs = buildQuery({
            skip: 0,
            limit: 25,
            active: options.active,
            ...omit(options, 'insuredFieldFilters'),
            ...insuredFieldFilters,
        });
        const requestUrl = `${url`/relyingParties/${rp}/insurance/insureds`}?${qs}`;
        const { navigation, records } = await this.request('GET', requestUrl);
        return { count: navigation.count, insureds: records };
    }

    public async getInsuranceInsured(rp: string, id: string):
        Promise<InsuranceInsured> {
        const requestUrl = `${url`/relyingParties/${rp}/insurance/insureds/${id}`}`;
        return this.request('GET', requestUrl);
    }

    public async getInsuranceInsuredFields(rp: string): Promise<InsuranceInsuredField[]> {
        const requestUrl = url`/relyingParties/${rp}/insurance/config/insureds/fields`;
        return await this.request<InsuranceInsuredField[]>('GET', requestUrl);
    }

    public async createInsuranceInsureds(rp: string, insureds: InsuranceInsuredInputDataModel[]):
        Promise<InsuranceInsuredPostResponse> {
        return await this.request('POST', url`/relyingParties/${rp}/insurance/insureds`, insureds);
    }

    public async batchCreateInsuranceInsureds(
        rp: string, insureds: InsuranceInsuredInputDataModel[], chunkSize: number,
        updateProgress: Function): Promise<PromiseSettledResult<InsuranceInsuredPostResponse>[]> {
        const insuredChunks = chunk(insureds, chunkSize || insureds.length);
        const promises: Promise<InsuranceInsuredPostResponse>[] = insuredChunks
            .map((insureds) => this.request('POST', url`/relyingParties/${rp}/insurance/insureds`, insureds));
        return this.batchRequests(promises, updateProgress);
    }

    public async upsertInsuranceInsureds(rp: string, insureds: (InsuranceInsuredInputDataModel | InsuranceInsured)[]):
        Promise<InsuranceInsuredUpsertResponse> {
        return await this.request('PUT', url`/relyingParties/${rp}/insurance/insureds`, insureds);
    }

    public async batchUpsertInsuranceInsureds(
        rp: string, insureds: (InsuranceInsuredInputDataModel | InsuranceInsured)[],
        chunkSize: number, updateProgress: Function): Promise<PromiseSettledResult<InsuranceInsuredUpsertResponse>[]> {
        const insuredChunks = chunk(insureds, chunkSize || 0);
        const promises: Promise<InsuranceInsuredUpsertResponse>[] = insuredChunks
            .map((insureds) => this.request('PUT', url`/relyingParties/${rp}/insurance/insureds`, insureds));
        return this.batchRequests(promises, updateProgress);
    }

    public async patchInsuranceInsured(rp: string, patch: InsuredPatch[]):
        Promise<InsuranceInsuredPatchResponse> {
        return await this.request('PATCH', url`/relyingParties/${rp}/insurance/insureds`, patch);
    }

    public async getInsuranceExport(rp: string): Promise<string> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/export`);
    }

    public async getInsuranceInsuredExport(rp: string): Promise<string> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/insureds/export`);
    }

    public async getInsuranceInsuredStatistics(rp: string): Promise<InsuranceInsuredStatistics> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/summaries/insureds`);
    }

    public async getInsuranceDashboardConfiguration(rp: string): Promise<InsuranceDashboardConfiguration> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/config/dashboard`);
    }

    public async updateInsuranceDashboardConfiguration(
        rp: string, dashboardConfiguration: InsuranceDashboardConfiguration): Promise<void> {
        return await this.request('PUT', url`/relyingParties/${rp}/insurance/config/dashboard`, dashboardConfiguration);
    }

    public async sendInsuredCoiRequest(
        rp: string, insuredIds: string[], chunkSize: number, updateProgress: (progress: number) => void):
        Promise<InsuranceSendCoiRequestResponse[]> {
        const insuredChunks = chunk(insuredIds, chunkSize);
        // TODO: Replace it with a backend call.
        const promises: Promise<InsuranceSendCoiRequestResponse>[] = insuredChunks
            .map((insureds) => this.request('POST', url`/relyingParties/${rp}/insurance/verifications`, insureds));
        const results = await this.batchRequests(promises, updateProgress);
        return results.map((result) => (result.status === 'fulfilled'
            ? result.value
            : {
                totalCount: 0,
                successCount: 0,
                failureCount: 0,
                successes: [],
                failures: [],
            }));
    }

    public async getInsuranceSheetData(rp: string): Promise<InsuranceSheetData> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/config/sheet/attributes`);
    }

    public async getInsuranceCollaterals(rp: string, insuredId: string): Promise<CollateralTabCollateral[]> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/insureds/${insuredId}/collaterals`);
    }

    public async getInsuranceCoveragesInfo(rp: string, insuredId: string): Promise<InsuranceInsuredCoverage[]> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/insureds/${insuredId}/coverages`);
    }

    public async getInsuranceCoveragesDetailsList(rp: string, insuredId: string):
        Promise<InsuranceInsuredCoverageDetails[]> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/insureds/${insuredId}/details`);
    }

    public async getInsuranceCoverageCriteriaGroups(rp: string):
        Promise<InsuranceCoverageCriteriaGroup[]> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/decisioning/groups`);
    }

    public async getInsuranceCoverageCriteriaGroup(rp: string, groupId: string):
        Promise<InsuranceCoverageCriteriaGroup> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/decisioning/groups/${groupId}`);
    }

    public async getInsuranceEffectiveGroups(rp: string):
        Promise<InsuranceEffectiveGroup[]> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/decisioning/groups/effective`);
    }

    public async getInsuranceInsuredCoverageCriteriaGroups(rp: string, insuredId: string):
        Promise<InsuranceInsuredCoverageCriteriaGroup[]> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/insureds/${insuredId}/groups`);
    }

    public async getInsuranceCoverageCriteria(rp: string, groupId: string):
        Promise<InsuranceCoverageCriterion[]> {
        return await this.request(
            'GET', url`/relyingParties/${rp}/insurance/decisioning/groups/${groupId}/criteria`);
    }

    public async getInsuranceCoverageModels(rp: string): Promise<InsuranceCoverageModel[]> {
        return await this.request('GET', url`/relyingParties/${rp}/models/coverages`);
    }

    public async getInsuranceCoverageCriteriaTemplates(rp: string):
        Promise<InsuranceCoverageCriterionTemplate[]> {
        return await this.request('GET', url`/relyingParties/${rp}/models/templates/criteria`);
    }

    public async postInsuranceCriterionForMessage(
        rp: string,
        criterion: InsuranceCoverageCriterion | InsuranceCoverageCriterionInput,
    ): Promise<InsuranceCoverageCriterionMessage> {
        return await this.request('POST', url`/relyingParties/${rp}/models/templates/criteria/messages`, criterion);
    }

    public async deleteInsuranceCoverageCriteriaGroup(rp: string, groupId: string): Promise<void> {
        return await this.request('DELETE', url`/relyingParties/${rp}/insurance/decisioning/groups/${groupId}`);
    }

    public async createInsuranceCoverageCriteriaGroup(
        rp: string, group: InsuranceCoverageCriteriaGroupInput): Promise<InsuranceCoverageCriteriaGroup> {
        return await this.request('POST', url`/relyingParties/${rp}/insurance/decisioning/groups`, group);
    }

    public async updateInsuranceCoverageCriteriaGroup(
        rp: string, group: InsuranceCoverageCriteriaGroup): Promise<InsuranceCoverageCriteriaGroup> {
        return await this.request('PUT', url`/relyingParties/${rp}/insurance/decisioning/groups/${group.id}`, group);
    }

    public async createInsuranceCoverageCriteria(
        rp: string, groupId: string,
        criteria: InsuranceCoverageCriterionInput[]): Promise<InsuranceCoverageCriterion[]> {
        return await this.request(
            'POST', url`/relyingParties/${rp}/insurance/decisioning/groups/${groupId}/criteria`, criteria);
    }

    public async patchInsuranceCoverageCriteria(
        rp: string, groupId: string,
        patches: any[]): Promise<any[]> {
        return await this.request('PATCH',
            url`/relyingParties/${rp}/insurance/decisioning/groups/${groupId}/criteria`, patches);
    }

    public async getInsuranceSubmissionLink(rp: string, requestId: string): Promise<string> {
        return await this.request('GET', url`/relyingParties/${rp}/requests/${requestId}/idoWebUrl`);
    }

    public async getInsuranceVerificationRequests(rp: string, insuredId: string):
        Promise<InsuranceVerificationRequest[]> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/insureds/${insuredId}/requests`);
    }

    public async getInsuranceVerification(rp: string, verificationId: string): Promise<InsuranceVerificationRequest> {
        return await this.request('GET', url`/relyingParties/${rp}/insurance/verifications/${verificationId}`);
    }

    public async getInsuranceCoverageTypesPerCountry(rp: string):
        Promise<InsuranceCoverageTypesPerCountry> {
        return await this.request('GET', url`/relyingParties/${rp}/models/countries`);
    }

    public async getInsuranceConfig(rp: string): Promise<InsuranceConfig> {
        return this.request('GET', url`/relyingParties/${rp}/insurance`);
    }

    public async updateInsuranceConfig(rp: string, changes: Partial<InsuranceConfigInput>): Promise<void> {
        const payload = mapValues(changes, (newValue) => ({ op: 'REPLACE', newValue }));
        await this.request('PATCH', url`/relyingParties/${rp}/insurance`, payload);
    }

    public async getInsuranceRequestsConfig(rp: string): Promise<InsuranceRequestsConfig> {
        return this.request('GET', url`/relyingParties/${rp}/insurance/config/requests`);
    }

    public async updateInsuranceRequestsConfig(
        rp: string,
        changes: PartialDeep<InsuranceRequestsConfig>,
    ): Promise<void> {
        const previousConfig = await this.getInsuranceRequestsConfig(rp);
        const config = merge(previousConfig, changes);
        await this.request('PUT', url`/relyingParties/${rp}/insurance/config/requests`, config);
    }

    public async getInsuranceInsuredActions(
        rp: string,
        queryOptions: InsuranceInsuredActionsQuery): Promise<InsuranceInsuredActionsSearchResult> {
        const qs = buildQuery({
            limit: queryOptions?.limit || 50,
            sort: queryOptions?.sort || undefined,
            pending: queryOptions?.pending || false,
            ...queryOptions,
        });
        const { navigation, records } =
            await this.request('GET', `${url`/relyingParties/${rp}/insurance/actions`}?${qs}`);
        return { count: navigation.count, actions: records };
    }

    public async createActionResolve(
        rp: string,
        actionId: string,
        resolve: InsuranceActionResolveInput): Promise<void> {
        await this.request('POST', url`/relyingParties/${rp}/insurance/actions/${actionId}/resolve`, resolve);
    }

    public async updateSupportContactInfo(rp: string, support: any): Promise<void> {
        await this.request('PUT', url`/relyingParties/${rp}/settings/supportContactInfo`, support);
    }

    public async updateEmailSettings(rp: string, settings: any): Promise<void> {
        await this.request('PUT', url`/relyingParties/${rp}/settings/email`, settings);
    }

    public async sendTestEmail(rp: string,
        verificationType: string,
        to: string,
        cc: string[] = [],
    ): Promise<void> {
        return this.request('POST', url`/relyingParties/${rp}/insurance/verifications/emails/test`,
            { verificationType, recipient: to, carbonCopy: cc });
    }

    public async updateBranding(rp: string, settings: any): Promise<void> {
        await this.request('PUT', url`/relyingParties/${rp}/settings/branding`, settings);
    }

    public async grantException(rp: string, insuredId: string, exceptions: InsuranceExceptionInput[]): Promise<void> {
        await this.request('POST', url`/relyingParties/${rp}/insurance/insureds/${insuredId}/overrides`, exceptions);
    }

    public async removeException(rp: string, insuredId: string, exceptionId: string): Promise<void> {
        await this.request('DELETE', url`/relyingParties/${rp}/insurance/insureds/${insuredId}/overrides/${exceptionId}`);
    }

    public async getInsuranceEndorsementCategories(rp: string): Promise<string[]> {
        return await this.request('GET', url`/relyingParties/${rp}/models/forms/categories`);
    }

    public async getCategorizedEnumLabels(rp: string): Promise<CategorizedEnumLabelsResult> {
        return await this.request('GET', url`/relyingParties/${rp}/models/enums`);
    }

    /**
     * Make a request to RP Web service.
     */
    private async request<T = any>(method: string, requestUrl: string, data?: any, contentJson = true):
        Promise<T> {
        // Retrieve current auth token
        const { accessToken, idToken } = await this.getTokens();

        return new Promise((resolve, reject) => {
            // Initialize XHR object
            const xhr = new XMLHttpRequest();
            xhr.open(method, `${this.baseUrl}${requestUrl}`);
            if (accessToken) {
                xhr.setRequestHeader('authorization', `Bearer ${accessToken}`);
            }
            if (idToken) {
                xhr.setRequestHeader('idtoken', idToken);
            }
            if (contentJson) {
                xhr.setRequestHeader('content-type', 'application/json');
            }

            // Handle valid response (read as JSON, but fallback to auth error or responseText)
            xhr.addEventListener('load', () => {
                const result = parseJsonConditionally(xhr.responseText);
                const statusCode = result?.downstream_error?.status_code || xhr.status;
                const statusErrorFactory = statusErrorFactories[statusCode];
                if (statusErrorFactory) {
                    return reject(statusErrorFactory(xhr));
                }
                const finish = statusCode >= 200 && statusCode < 300 ? resolve : reject;
                return finish(result);
            });

            // Handle connection problem
            xhr.addEventListener('error', (error) => {
                console.error(`RP Web Client request error: ${requestUrl} [${xhr.status}]`, error);
                reject(error);
            });

            // Initialize request
            if (data === undefined) {
                xhr.send();
            } else {
                xhr.send(JSON.stringify(data));
            }
        });
    }

    private async batchRequests<T = any>(
        promises: Promise<T> [],
        updateProgress: Function): Promise<PromiseSettledResult<T>[]> {
        let requestsFinished = 0;
        const updateFunc = updateProgress || noop;
        const onRequestFinished = () => {
            updateFunc(++requestsFinished / promises.length * 100);
        };
        updateFunc(0);
        for (const promise of promises) {
            promise.finally(onRequestFinished);
        }
        return Promise.allSettled(promises);
    }
}

export default RpWebApiClient;
