import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import Dashboard from '@/modules/dashboard/views/Dashboard.vue';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import vuexDashboardModule from './vuex/dashboard';
import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import Severity from '@evidentid/logger/Severity';
import CoverageCriteriaGroup from '@/modules/decisioning-criteria/views/CoverageCriteriaGroup.vue';

export const dashboardModule = createModuleBuilder()
    .registerRoutes(() => [
        {
            path: '/:rpId?/dashboard',
            name: 'dashboard',
            component: Dashboard as any,
            meta: { title: 'Dashboard' },
        },
    ])
    .demandContext<{
        rpweb: RpWebApiClient;
        tracker?: Tracker<any>;
        logger?: Logger<any>;
    }>()
    .registerVuex({ dashboard: vuexDashboardModule.instantiateModule })
    .execute((app) => {
        function onLoadInsuredsRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Insureds',
                    description: `[Requested] Get insureds for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Insureds',
                    message: `[Requested] Get insureds for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadInsuredsFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Insureds',
                    description: `[Finished] Get insureds for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Insureds',
                    message: `[Finished] Get insureds for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadInsuredsFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Insureds Failed',
                    description: `[Failed] Get insureds for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Dashboard - Insureds',
                    message: `[Failed] Get insureds for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadInsuredFieldsRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Insured Fields',
                    description: `[Requested] Get insured fields for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Insured Fields',
                    message: `[Requested] Get insured fields for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadInsuredFieldsFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Insured Fields',
                    description: `[Finished] Get insured fields for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Insured Fields',
                    message: `[Finished] Get insured fields for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadInsuredFieldsFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Insured Fields Failed',
                    description: `[Failed] Get insured fields for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Dashboard - Insured Fields',
                    message: `[Failed] Get insured fields for ${rpName}`,
                    data: { rpName },
                });
            }
        }
        function onLoadCoverageTypesRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Coverage Types',
                    description: `[Requested] Get coverageTypes for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Coverage Types',
                    message: `[Requested] Get coverageTypes for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadCoverageTypesFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Coverage Types',
                    description: `[Finished] Get coverageTypes for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Coverage Types',
                    message: `[Finished] Get coverageTypes for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadCoverageTypesFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Coverage Types Failed',
                    description: `[Failed] Get coverageTypes for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Dashboard - Coverage Types',
                    message: `[Failed] Get coverageTypes for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadInsuredStatisticsRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Insured Statistics',
                    description: `[Requested] Get insured Statistics for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Insured Statistics',
                    message: `[Requested] Get insured Statistics for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadInsuredStatisticsFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Insured Statistics',
                    description: `[Finished] Get insured Statistics for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Insured Statistics',
                    message: `[Finished] Get insured Statistics for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadInsuredStatisticsFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Insured Statistics Failed',
                    description: `[Failed] Get insured Statistics for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Dashboard - Insured Statistics',
                    message: `[Failed] Get insured Statistics for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadRequestsConfigRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Requests Config',
                    description: `[Requested] Get requests Config for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Requests Config',
                    message: `[Requested] Get requests Config for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadRequestsConfigFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Requests Config',
                    description: `[Finished] Get requests Config for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Requests Config',
                    message: `[Finished] Get requests Config for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadRequestsConfigFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Get Requests Config Failed',
                    description: `[Failed] Get requests Config for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Dashboard - Requests Config',
                    message: `[Failed] Get requests Config for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        app.observer.onChange((state) => (state as any).route, (newRoute, oldRoute) => {
            if (newRoute.params.rpId !== oldRoute.params.rpId) {
                app.store.actions.dashboard.loadCategorizedEnumLabels({ rpName: newRoute.params.rpId });
            }
        });
        app.observer.onMutation('startInsuredsListUpdate', (x) => onLoadInsuredsRequest(x.rpName));
        app.observer.onMutation('pushToInsuredsList', (x) => onLoadInsuredsFinish(x.rpName));
        app.observer.onMutation('failInsuredsListUpdate', (x) => onLoadInsuredsFailure(x.rpName));
        app.observer.onMutation('startLoadingInsuredFields', (x) => onLoadInsuredFieldsRequest(x.rpName));
        app.observer.onMutation('finishLoadingInsuredFields', (x) => onLoadInsuredFieldsFinish(x.rpName));
        app.observer.onMutation('failLoadingInsuredFields', (x) => onLoadInsuredFieldsFailure(x.rpName));
        app.observer.onMutation('startLoadingCoverageTypes', (x) => onLoadCoverageTypesRequest(x.rpName));
        app.observer.onMutation('finishLoadingCoverageTypes', (x) => onLoadCoverageTypesFinish(x.rpName));
        app.observer.onMutation('failLoadingCoverageTypes', (x) => onLoadCoverageTypesFailure(x.rpName));
        app.observer.onMutation('startLoadingInsuredStatistics', (x) => onLoadInsuredStatisticsRequest(x.rpName));
        app.observer.onMutation('finishLoadingInsuredStatistics', (x) => onLoadInsuredStatisticsFinish(x.rpName));
        app.observer.onMutation('failLoadingInsuredStatistics', (x) => onLoadInsuredStatisticsFailure(x.rpName));
        app.observer.onMutation('startLoadingRequestsConfig', (x) => onLoadRequestsConfigRequest(x.rpName));
        app.observer.onMutation('finishLoadingRequestsConfig', (x) => onLoadRequestsConfigFinish(x.rpName));
        app.observer.onMutation('failLoadingRequestsConfig', (x) => onLoadRequestsConfigFailure(x.rpName));
    })
    .end();
