import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import vuex from '@/modules/insured-details/vuex';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import Severity from '@evidentid/logger/Severity';
import GrantExceptionProcedure from '@/modules/insured-details/procedures/GrantExceptionProcedure.vue';
import { Exception } from '@/modules/dashboard/types';
import RemoveExceptionProcedure from '@/modules/insured-details/procedures/RemoveExceptionProcedure.vue';
import AMBestRatingDisclaimerProcedure from '@/modules/insured-details/procedures/AMBestRatingDisclaimerProcedure.vue';
import { InsuranceException, InsuranceInsured } from '@evidentid/rpweb-api-client/types';

export const insuredDetailsModule = createModuleBuilder()
    .demandContext<{
        rpweb: RpWebApiClient;
        tracker?: Tracker<any>;
        logger?: Logger<any>;
    }>()
    .registerVuex({ insuredDetails: vuex.instantiateModule })
    .registerProcedures<{
        grantExceptionProcedure: { exception: Exception };
        removeExceptionProcedure: { rpName: string, insured: InsuranceInsured, exceptionIds: string[] };
        aMBestRatingDisclaimerProcedure: {};
    }>({
        grantExceptionProcedure: GrantExceptionProcedure,
        removeExceptionProcedure: RemoveExceptionProcedure,
        aMBestRatingDisclaimerProcedure: AMBestRatingDisclaimerProcedure,
    })
    .execute((app) => {
        function onLoadingCoverageDetailsList(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Coverage Details',
                    description: `[Requested] Load coverage details for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Insured Details - Coverage Details',
                    message: `[Requested] Load coverage details for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCoverageDetailsListFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Coverage Details',
                    description: `[Finished] Load coverage details for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Insured Details - Coverage Details',
                    message: `[Finished] Load coverage details for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCoverageDetailsListFail(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Coverage Details Failed',
                    description: `[Failed] Load coverage details for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Insured Details - Coverage Details',
                    message: `[Failed] Load coverage details for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCollateralEntities(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Collateral Entities',
                    description: `[Requested] Load collateral entities for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Insured Details - Collateral Entities',
                    message: `[Requested] Load collateral entities for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCollateralEntitiesFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Collateral Entities',
                    description: `[Finished] Load collateral entities for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Insured Details - Collateral Entities',
                    message: `[Finished] Load collateral entities for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingCollateralEntitiesFail(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Collateral Entities Failed',
                    description: `[Failed] Load collateral entities for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Insured Details - Collateral Entities',
                    message: `[Failed] Load collateral entities for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        app.observer.onMutation('startLoadingCoverageDetailsList', (x) => onLoadingCoverageDetailsList(x.rpName));
        app.observer.onMutation('finishLoadingCoverageDetailsList', (x) => onLoadingCoverageDetailsListFinish(x.rpName));
        app.observer.onMutation('failLoadingCoverageDetailsList', (x) => onLoadingCoverageDetailsListFail(x.rpName));
        app.observer.onMutation('startLoadingCollateralEntities', (x) => onLoadingCollateralEntities(x.rpName));
        app.observer.onMutation('finishLoadingCollateralEntities', (x) => onLoadingCollateralEntitiesFinish(x.rpName));
        app.observer.onMutation('failLoadingCollateralEntities', (x) => onLoadingCollateralEntitiesFail(x.rpName));
    })
    .end();
