import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import type { PersistingErrorModule } from '@evidentid/dashboard-commons/modules/persisting-error';
import type RpWebApiClient from '@evidentid/rpweb-api-client';
import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import InsuredCoisListProcedure from './views/InsuredCoisListProcedure.vue';
import vuex from './vuex';
import InsuredCoverageCoiProcedure from '@/modules/insured-certificates/views/InsuredCoverageCoiProcedure.vue';
import { InsuranceCoverageModel, InsuranceCoverageType } from '@evidentid/rpweb-api-client/types';

export const insuredCertificatesModule = createModuleBuilder()
    .demand<PersistingErrorModule>()
    .demandContext<{ rpweb: RpWebApiClient }>()
    .registerVuex({ insuredCertificates: vuex.instantiateModule })
    .registerProcedures<{
        showInsuredCois: { rpName: string, email: string, coverageModels?: InsuranceCoverageModel[] };
        showInsuredCoverageCois: {
            rpName: string;
            email: string;
            coverageType?: InsuranceCoverageType;
            coverageTypeLabel?: string;
        };
    }>({
        showInsuredCois: InsuredCoisListProcedure,
        showInsuredCoverageCois: InsuredCoverageCoiProcedure,
    })
    .demandContext<{ tracker?: Tracker<any>, logger?: Logger<any> }>()
    .execute((app) => {
        function onLoadCoisRequest(rpName: string, email: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Insureds COIs requested',
                    description: `Insureds (${rpName}: ${email}) COIs requested`,
                    rpName,
                    email,
                });
            }

            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'insured-certificates',
                    message: '[Requested] Insureds COIs',
                    data: { rpName, email },
                });
            }
        }

        function onLoadCoisFinish(rpName: string, email: string, types: string[]) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Insureds COIs finished',
                    description: `Insureds (${rpName}: ${email}) COIs finished (${types.length})`,
                    rpName,
                    email,
                    count: types.length,
                });
            }

            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'insured-certificates',
                    message: '[Finished] Insureds COIs',
                    data: { rpName, email, types },
                });
            }
        }

        function onLoadCoisFailure(rpName: string, email: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Insureds COIs failed',
                    description: `Insureds (${rpName}: ${email}) COIs failed`,
                    rpName,
                    email,
                });
            }

            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'insured-certificates',
                    message: '[Failed] Insureds COIs',
                    data: { rpName, email },
                });
            }
        }

        app.observer.onMutation('startLoadingCoi', (x) => onLoadCoisRequest(x.rpName, x.email));
        app.observer.onMutation('finishLoadingCoi', (x) => onLoadCoisFinish(x.rpName, x.email, x.content.map((a) => a.id)));
        app.observer.onMutation('failLoadingCoi', (x) => onLoadCoisFailure(x.rpName, x.email));
    })
    .end();
