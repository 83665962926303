import { InsuranceInsured, InsuranceInsuredCoverage, InsuranceExceptionInput } from '@evidentid/rpweb-api-client/types';

export interface BrokerInfo {
    name: string;
    phoneNumber: string;
    contactName: string;
}

export interface PolicyInfo {
    name: string;
    coverage: InsuranceInsuredCoverage | null;
}

export interface Exception {
    rpName: string;
    insured: InsuranceInsured;
    data: InsuranceExceptionInput[];
}

export enum EnumCategories {
    complianceStatus = 'complianceStatus',
    countryCode = 'countryCode',
    coverageTypes = 'coverageTypes',
    verificationStatus = 'verificationStatus',
    automobilesCovered = 'automobilesCovered',
    physicalDamageCoverageValuationMethod = 'physicalDamageCoverageValuationMethod',
    limits = 'limits',
    valuation = 'valuation',
    perilsInsured = 'perilsInsured',
    occurrenceOrClaimsMade = 'occurrenceOrClaimsMade',
    limitAppliesPer = 'limitAppliesPer',
    coverageBasis = 'coverageBasis',
    generalAggregateLimitAppliesPer = 'generalAggregateLimitAppliesPer',
    currency = 'currency',
    collateralLimitType = 'collateralLimitType'
}
