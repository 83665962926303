import { CategorizedEnumLabels } from '@/modules/dashboard/vuex/dashboard';
import { EnumCategories } from '@/modules/dashboard/types';

export function getEnumDisplayValue<T>(
    enumCategory: string | EnumCategories,
    enumValue: T,
    readableEnums: CategorizedEnumLabels,
): T | string | string[] {
    const enumLabels = readableEnums[enumCategory];

    if (!enumLabels) {
        return enumValue;
    }

    if (Array.isArray(enumValue)) {
        return enumValue.map((val) => enumLabels[val] || val);
    }

    return enumLabels[enumValue as any] || enumValue;
}
