import { Component } from 'vue';
import { UserManagementApiClient } from '@evidentid/user-management-api-client';
import type { User } from '@evidentid/user-management-api-client/types';
import { createModuleBuilder } from '../../app';
import { createPersistingErrorModule } from '../persisting-error';
import { createSnackbarModule } from '../snackbar';
import UsersDashboard from './views/UsersDashboard.vue';
import InviteUserProcedure from './procedures/InviteUserProcedure.vue';
import EditUserProcedure from './procedures/EditUserProcedure.vue';
import DeleteUserProcedure from './procedures/DeleteUserProcedure.vue';
import ResendInviteProcedure from './procedures/ResendInviteProcedure.vue';
import { UserManagementConfig, UserManagementProcedure } from './types';
import vuex from './vuex';

interface UserManagementModuleExpectedViews {
    Page: Component;
}

// TODO: add logging

export function createUserManagementModule() {
    return createModuleBuilder()
        .demandOptions<{
            views: UserManagementModuleExpectedViews;
            userManagementConfig: UserManagementConfig;
            userManagementApiUrl: string;
        }>()
        .registerRoutes(() => [
            {
                path: '/:rpId?/admin/users',
                name: 'manage-users',
                component: UsersDashboard as any,
                meta: { title: 'Manage Users' },
            },
        ])
        .demandContext<{
            auth: { getTokens(): Promise<{ accessToken: string | null, idToken: string | null }> };
        }>()
        .inject(async (app) => {
            const userManagement = new UserManagementApiClient(app.options.userManagementApiUrl);
            userManagement.setTokens(() => app.auth.getTokens());
            return { userManagement };
        })
        .injectVue((app) => ({
            $userManagement: {
                config: app.options.userManagementConfig,
                Page: app.options.views.Page,
            },
        }))
        .demand<ReturnType<typeof createPersistingErrorModule>>()
        .demand<ReturnType<typeof createSnackbarModule>>()
        .registerVuex({ userManagement: vuex.instantiateModule })
        .registerProcedures<{
            [UserManagementProcedure.invite]: { rpName: string, rpDisplayName?: string | null };
            [UserManagementProcedure.edit]: { rpName: string, user: User };
            [UserManagementProcedure.delete]: { rpName: string, user: User };
            [UserManagementProcedure.resendInvite]: { rpName: string, user: User };
        }>({
            [UserManagementProcedure.invite]: InviteUserProcedure,
            [UserManagementProcedure.edit]: EditUserProcedure,
            [UserManagementProcedure.delete]: DeleteUserProcedure,
            [UserManagementProcedure.resendInvite]: ResendInviteProcedure,
        })
        .end();
}

export type UserManagementModule = ReturnType<typeof createUserManagementModule>;
