import { TableColumn } from '@/modules/insured-details/models/TableColumn.model';

export const insuredFieldCollateralColumns: TableColumn[] = [
    {
        name: 'description',
        field: 'description',
        label: 'Description',
        tooltipData: {
            content: '',
        },
    },
    {
        name: 'category',
        field: 'category',
        label: 'Category',
        tooltipData: {
            content: '',
        },
    },
    {
        name: 'uniqueIdentifier',
        field: 'uniqueIdentifier',
        label: 'Unique Identifier',
        tooltipData: {
            content: '',
        },
    },
    {
        name: 'limitRequired',
        field: 'limitRequired',
        label: 'Limit Required',
        tooltipData: {
            content: '',
        },
    },
    {
        name: 'maximumDeductible',
        field: 'maximumDeductible',
        label: 'Max. Deductible Required',
        tooltipData: {
            content: '',
        },
    },
];
