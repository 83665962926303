import {
    JsonSchemaBoolean,
    JsonSchemaObject,
    JsonSchemaString,
    JsonSchemaType,
} from '@evidentid/json-schema/interfaces/JsonSchema';

export function buildEmailConfigurationSchema(): JsonSchemaObject {
    const fromNameSchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        minLength: 1,
        title: 'Enter "From" Name',
    };

    const replyToNameSchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        minLength: 1,
        title: 'Enter "Reply To" Name',
    };

    const replyToEmailSchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        format: 'email',
        minLength: 1,
        title: 'Enter "Reply To" Address',
    };

    const coiRequirementsLinkSchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        format: 'url',
        minLength: 1,
        title: 'COI Requirements Link URL',
    };

    const fulfillmentEnableSchema: JsonSchemaBoolean = {
        type: JsonSchemaType.boolean,
        title: 'Enable fulfillment',
    };

    const logoUriSchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        format: 'image-url',
        minLength: 1,
        maxLength: 2e6, // ~1MB in Base64 Data URL
        title: 'Logo in PNG or JPG format (1MB max.)',
    };

    const primaryColorSchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        format: 'color',
        minLength: 1,
        title: 'Primary color (used for the call-to-action button)',
    };

    const secondaryColorSchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        format: 'color',
        minLength: 1,
        title: 'Secondary color (used for the text in the call-to-action button)',
    };

    const fulfillmentTextSchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        format: 'html',
        title: 'Text in the fulfillment block',
    };

    const fulfillmentButtonLabelSchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        minLength: 1,
        title: 'Button label',
    };

    const fulfillmentUrlSchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        format: 'url',
        minLength: 1,
        title: 'Fulfillment URL',
    };

    const certificateHolderAddressSchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        format: 'html',
        title: 'Certificate Holder Address',
    };

    const fulfillmentConfigurationSchema: JsonSchemaObject = {
        type: JsonSchemaType.object,
        properties: {
            enabled: fulfillmentEnableSchema,
        },
        if: {
            properties: {
                enabled: { const: true },
            },
            required: [ 'enabled' ],
        },
        then: {
            properties: {
                logoUri: logoUriSchema,
                primaryColor: primaryColorSchema,
                secondaryColor: secondaryColorSchema,
                text: fulfillmentTextSchema,
                buttonLabel: fulfillmentButtonLabelSchema,
                fulfillmentUrl: fulfillmentUrlSchema,
            },
            required: [
                'logoUri',
                'primaryColor',
                'secondaryColor',
                'text',
                'buttonLabel',
                'fulfillmentUrl',
            ],
        },
        else: {},
    };

    return {
        type: JsonSchemaType.object,
        properties: {
            fromName: fromNameSchema,
            replyToName: replyToNameSchema,
            replyToEmail: replyToEmailSchema,
            coiRequirementsLink: coiRequirementsLinkSchema,
            certificateHolderAddress: certificateHolderAddressSchema,
            fulfillmentConfiguration: fulfillmentConfigurationSchema,
        },
        required: [ 'fromName', 'replyToName', 'replyToEmail', 'fulfillmentConfiguration' ],
    };
}
