import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import vuex from './vuex';
import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import Severity from '@evidentid/logger/Severity';
import AddIndividualInsuredProcedure from '@/modules/insured-management/procedures/AddIndividualInsuredProcedure.vue';
import BulkImportInsuredsProcedure from '@/modules/insured-management/procedures/BulkImportInsuredsProcedure.vue';
import { InsuranceInsured } from '@evidentid/rpweb-api-client/types';
import DeactivateInsuredProcedure from '@/modules/insured-management/procedures/DeactivateInsuredProcedure.vue';

export const insuredManagementModule = createModuleBuilder()
    .demandContext<{
        rpweb: RpWebApiClient;
        tracker?: Tracker<any>;
        logger?: Logger<any>;
    }>()
    .registerVuex({ insuredManagement: vuex.instantiateModule })
    .registerProcedures<{
        openAddIndividualInsuredModal: { rpName: string };
        openBulkImportInsuredsModal: { rpName: string };
        openDeactivateInsuredProcedure: { rpName: string, insureds: InsuranceInsured[], isBulk: boolean };
    }>({
        openAddIndividualInsuredModal: AddIndividualInsuredProcedure,
        openBulkImportInsuredsModal: BulkImportInsuredsProcedure,
        openDeactivateInsuredProcedure: DeactivateInsuredProcedure,
    })
    .execute((app) => {
        function onAddInsuredsRequest(rpName: string, count: number) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Add Insureds',
                    description: `[Requested] Add insureds for ${rpName}`,
                    rpName,
                    addInsuredsCount: count,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Insureds',
                    message: `[Requested] Add insureds for ${rpName}`,
                    data: { rpName, count },
                });
            }
        }

        function onAddInsuredsFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Add Insureds',
                    description: `[Finished] Add insureds for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Insureds',
                    message: `[Finished] Add insureds for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onAddInsuredsFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Add Insureds Failed',
                    description: `[Failed] Add insureds for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Dashboard - Insured Fields',
                    message: `[Failed] Add insureds for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onUpdateInsuredsRequest(rpName: string, count: number) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Update Insureds',
                    description: `[Requested] Update insureds for ${rpName}`,
                    updateInsuredsCount: count,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Insureds',
                    message: `[Requested] Update insureds for ${rpName}`,
                    data: { rpName, count },
                });
            }
        }

        function onUpdateInsuredsFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Update Insureds',
                    description: `[Finished] Update insureds for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Insureds',
                    message: `[Finished] Update insureds for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onUpdateInsuredsFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Update Insureds Failed',
                    description: `[Failed] Update insureds for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Dashboard - Insured Fields',
                    message: `[Failed] Update insureds for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onStartPatchInsureds(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Patch Insureds',
                    description: `[Requested] Patch insureds for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Insureds',
                    message: `[Requested] Patch insureds for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onFinishPatchInsureds(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Patch Insureds',
                    description: `[Finished] Patch insureds for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Insureds',
                    message: `[Finished] Patch insureds for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onFailPatchInsureds(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Patch Insureds Failed',
                    description: `[Failed] Patch insureds for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Dashboard - Insureds',
                    message: `[Failed] Patch insureds for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        app.observer.onMutation('startAddingInsureds', (x) => onAddInsuredsRequest(x.rpName, x.count));
        app.observer.onMutation('finishAddingInsureds', (x) => onAddInsuredsFinish(x.rpName));
        app.observer.onMutation('failAddingInsureds', (x) => onAddInsuredsFailure(x.rpName));
        app.observer.onMutation('startUpdateInsureds', (x) => onUpdateInsuredsRequest(x.rpName, x.count));
        app.observer.onMutation('finishUpdateInsureds', (x) => onUpdateInsuredsFinish(x.rpName));
        app.observer.onMutation('failUpdateInsureds', (x) => onUpdateInsuredsFailure(x.rpName));
        app.observer.onMutation('startPatchInsureds', (x) => onStartPatchInsureds(x.rpName));
        app.observer.onMutation('finishPatchInsureds', (x) => onFinishPatchInsureds(x.rpName));
        app.observer.onMutation('failPatchInsureds', (x) => onFailPatchInsureds(x.rpName));
    })
    .end();
