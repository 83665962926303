import { InsuranceInsuredField, InsuranceInsuredInput } from '@evidentid/rpweb-api-client/types';
import { JsonSchemaArray, JsonSchemaType } from '@evidentid/json-schema/interfaces/JsonSchema';
import createForm from '@evidentid/json-schema/createForm';

function formatPhoneNumber(phoneNumber: string): string {
    const number = phoneNumber.replace(/[^\d]/g, '');
    if (number.length === 10) {
        return number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    } else if (number.length === 11) {
        return number.substr(1).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
    return number;
}

function formatInsuredField(insuredField: InsuranceInsuredField, insured: InsuranceInsuredInput): string {
    const value = insured.insuredFields ? insured.insuredFields[insuredField.key] : undefined;
    if (typeof value === 'boolean') {
        return value ? 'Yes' : 'No';
    } else if (Array.isArray(value)) {
        const schema = insuredField.schema as JsonSchemaArray<any>;
        if (schema.items.type !== JsonSchemaType.object) {
            return value.join('|');
        }
        const formValue = createForm(schema).getValue(value, true);
        return formValue.map((x: object) => JSON.stringify(x)).join('|');
    } else {
        return value?.toString() || '';
    }
}

export function buildInsuredsCsv(insuredColumnLabels: string[], insureds: InsuranceInsuredInput[],
    insuredFields: InsuranceInsuredField[], coverageTypes: string[]): string[][] {
    return [
        [ ...insuredColumnLabels.map((label) => label || '') ],
        ...insureds.map((insured) => [
            insured.displayName,
            insured.contactEmail,
            insured.contactName || '',
            formatPhoneNumber(insured.contactPhoneNumber || ''),
            ...insuredFields.map((insuredField) => formatInsuredField(insuredField, insured)),
            ...coverageTypes.map((coverage) => (insured.exceptions ? insured.exceptions[coverage] || '' : '')),
        ]),
    ];
}
