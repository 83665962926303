import { createStateFactory } from '@evidentid/vue-commons/store';
import RpWebApiClient, { RelyingPartiesQuery } from '@evidentid/rpweb-api-client';
import { RelyingPartySignature } from '@evidentid/rpweb-api-client/types';
import { getAuthErrorMessage, IamClient } from '@evidentid/iam-client';
import { IamClientUser } from '@evidentid/iam-client/types';
import { getSnackbarActions } from '../../snackbar';
import { getPersistingErrorActions } from '../../persisting-error';
import { UserState } from '../types';

export interface UserAppRequirements {
    auth: IamClient;
    rpweb: RpWebApiClient;
    // TODO: Allow no coupling between user and relying parties
    options: { relyingPartiesFilter: RelyingPartiesQuery };
}

const createState = createStateFactory<UserAppRequirements>();

const { instantiateState, createMutationsFactories } = createState<UserState>(() => ({
    initialized: false,
    duringPasswordReset: false,
    duringSsoLogin: null,
    duringRegularLogin: false,
    duringRegistration: false,
    sendingEmailVerification: false,
    data: null,
    relyingParties: null,
}));

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({
    updatePasswordResetStatus(status: boolean) {
        this.duringPasswordReset = status;
    },
    updateSsoLoginStatus(method: string | null) {
        this.duringSsoLogin = method;
    },
    updateRegularLoginStatus(status: boolean) {
        this.duringRegularLogin = status;
    },
    updateRegistrationStatus(status: boolean) {
        this.duringRegistration = status;
    },
    updateEmailVerificationStatus(status: boolean) {
        this.sendingEmailVerification = status;
    },
    updateRelyingParties(relyingParties: RelyingPartySignature[] | null) {
        this.relyingParties = relyingParties;
    },
    updateUserData(data: IamClientUser | null) {
        this.initialized = true;
        this.data = data;
    },
}));

const { instantiateActions, instantiateModule } = createActionFactories(({
    auth,
    rpweb,
    options: { relyingPartiesFilter },
}: UserAppRequirements) => ({
    async showLoginError(error: any) {
        const message = getAuthErrorMessage(error);
        await getSnackbarActions(this).displaySnackbar({ permanent: true, message });
        console.warn('Login error', error);
    },
    async showRegistrationError(error: any) {
        const message = getAuthErrorMessage(error);
        await getSnackbarActions(this).displaySnackbar({ permanent: true, message });
        console.warn('Registration error', error);
    },
    async showResetError(error: any) {
        const message = getAuthErrorMessage(error, {
            InvalidEmailError: 'The email appears incorrect.',
        });
        await getSnackbarActions(this).displaySnackbar({ permanent: true, message });
        console.warn('Reset error', error);
    },
    async logInWithSso(method: string) {
        try {
            this.mutations.updateSsoLoginStatus(method);
            await auth.login(method);
        } catch (error) {
            await this.actions.showLoginError(error);
        } finally {
            this.mutations.updateSsoLoginStatus(null);
        }
    },
    async logIn(payload: { email: string, password: string }) {
        const { email, password } = payload;
        try {
            this.mutations.updateRegularLoginStatus(true);
            await auth.login('credentials', email, password);
        } catch (error) {
            await this.actions.showLoginError(error);
        } finally {
            this.mutations.updateRegularLoginStatus(false);
        }
    },
    async resetPassword(email: string) {
        const signInUrl = Object.assign(new URL(window.location.href), { hash: '#/auth' }).toString();
        try {
            this.mutations.updatePasswordResetStatus(true);
            await auth.resetPassword(email, signInUrl);
            await getSnackbarActions(this).displaySnackbar({
                message: 'Thanks! Check your email for the link.',
                permanent: true,
                success: true,
            });
        } catch (error) {
            await this.actions.showResetError(error);
        } finally {
            this.mutations.updatePasswordResetStatus(false);
        }
    },
    async register(payload: { email: string, password: string }) {
        const { email, password } = payload;
        try {
            this.mutations.updateRegistrationStatus(true);
            await auth.register('credentials', email, password);
            await this.actions.sendEmailVerification();
        } catch (error) {
            await this.actions.showRegistrationError(error);
        } finally {
            this.mutations.updateRegistrationStatus(false);
        }
    },
    async logOut() {
        try {
            await auth!.logOut();

            // Clear authentication-related errors after log out
            await this.actions.clearAuthorizationError();
        } catch (error) {
            console.warn('Log out error', error);
        }
    },
    async sendEmailVerification() {
        const signInUrl = Object.assign(new URL(window.location.href), { hash: '#/' }).toString();
        try {
            this.mutations.updateEmailVerificationStatus(true);
            await auth.sendEmailVerification(signInUrl);
            await getSnackbarActions(this).displaySnackbar({
                message: 'Thanks! Check your email for the link.',
                success: true,
            });
        } catch (error) {
            if (error?.code === 'auth/too-many-requests') {
                await getSnackbarActions(this).displaySnackbar({
                    message: 'The email has been sent recently. If it will not be delivered in few minutes, try again.',
                    permanent: true,
                    success: false,
                });
            } else {
                await getPersistingErrorActions(this).showError(error);
            }
        } finally {
            this.mutations.updateEmailVerificationStatus(false);
        }
    },
    async refreshSession() {
        await auth.getIdToken(true);
    },
    async clearAuthorizationError() {
        if (this.rootState.error?.reason === 'no-relying-parties') {
            await getPersistingErrorActions(this).showError(null);
        }
    },
    async handleUserDataUpdate(data: IamClientUser | null) {
        if (data === null) {
            this.mutations.updateUserData(null);
            this.mutations.updateRelyingParties(null);
        } else {
            this.mutations.updateUserData(data);
            try {
                const rps = data.verified
                    ? await rpweb.getAvailableRelyingParties(relyingPartiesFilter)
                    : null;
                // Handle race condition: do not update relying parties, when there was already another update
                if (this.state.data === data) {
                    this.mutations.updateRelyingParties(rps);
                }
            } catch (error) {
                await getPersistingErrorActions(this).showError(error);
            }
        }
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
};
