import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import vuex from './vuex';
import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import Severity from '@evidentid/logger/Severity';
import SendCoiRequestProcedure from '@/modules/insured-request/procedures/SendCoiRequestProcedure.vue';
import { InsuranceInsured } from '@evidentid/rpweb-api-client/types';

export const insuredRequestModule = createModuleBuilder()
    .demandContext<{
        rpweb: RpWebApiClient;
        tracker?: Tracker<any>;
        logger?: Logger<any>;
    }>()
    .registerVuex({ insuredRequest: vuex.instantiateModule })
    .registerProcedures<{
        openSendCoiRequestProcedure: { rpName: string, insureds: InsuranceInsured[] };
    }>({
        openSendCoiRequestProcedure: SendCoiRequestProcedure,
    })
    .execute((app) => {
        function onSendCoiRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Send COI Request',
                    description: `[Requested] Send COI request for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Insureds',
                    message: `[Requested] Send COI request for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onFinishSendCoiRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Send COI Request',
                    description: `[Finished] Send COI request for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Insureds',
                    message: `[Finished] Send COI request for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onSendCoiRequestFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Send COI Request',
                    description: `[Failed] Send COI request for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Dashboard - Insureds',
                    message: `[Failed] Send COI request for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        app.observer.onMutation('startSendingCoiRequests', (x) => onSendCoiRequest(x.rpName));
        app.observer.onMutation('finishSendingCoiRequests', (x) => onFinishSendCoiRequest(x.rpName));
        app.observer.onMutation('failSendingCoiRequests', (x) => onSendCoiRequestFailure(x.rpName));
    })
    .end();
