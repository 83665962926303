import * as rpContract from '@evidentid/common-datasets/terms/rpContract';
import { IamClientAdapterName, IamClientAdapterOptions } from '@evidentid/iam-client';

// Helpers for data extraction

function readJson(text: string): any {
    try {
        return JSON.parse(text);
    } catch (error) {
        return {};
    }
}

// Extract data from environment

const dev = process.env.NODE_ENV === 'development';
const rpwebApiUrl = deploy.WEB_PUBLIC_MICROSERVICE_URL_PREFIX;
const authMethod: IamClientAdapterName = deploy.WEB_PUBLIC_AUTH_METHOD_INSURANCEFACING || 'firebase';
const authConfig: { [K in IamClientAdapterName]: IamClientAdapterOptions<K> } = {
    mock: {},
    firebase: JSON.parse(deploy.WEB_PUBLIC_RPWEB_FIREBASE_CONFIG),
    auth0: {
        ...JSON.parse(deploy.WEB_PUBLIC_RPWEB_AUTH0_CONFIG),
        gateway: deploy.WEB_PUBLIC_RPWEB_AUTH0_GATEWAY_URL,
        namespace: 'https://evidentid.com/',
        // TODO: Add permissions which will be required later
        scope: [
            'read:request', // Used for reading T&Cs sign information
            'manage:configuration', // Used for signing T&Cs
            'manage:users', // User Management
        ],
    },
};
const trackerAdapter = deploy.WEB_PUBLIC_INSURANCE_TRACKING_ADAPTER;
const trackerOptions = readJson(deploy.WEB_PUBLIC_INSURANCE_TRACKING_OPTIONS);

// Combine all settings into single object

export const config = {
    // API and Auth
    rpwebApiUrl,

    // Auth
    iamConfig: { name: authMethod, options: authConfig[authMethod] || {} },
    relyingPartiesFilter: { filter: 'insurance' },
    terms: rpContract,

    // Config API
    configApiUrl: deploy.WEB_PUBLIC_CONFIG_API_URL_PREFIX,

    // User Management
    userManagementApiUrl: deploy.WEB_PUBLIC_USER_MANAGEMENT_URL_PREFIX,
    userManagementConfig: {
        serviceName: 'rpweb',
        availableRoles: [
            'administrator',
        ],
        roleLabels: {
            administrator: 'Administrator',
        },
    },

    // Tracking
    tracker: {
        adapter: trackerAdapter || (dev ? 'console' : 'noop'),
        options: trackerOptions || {},
    },

    // Auto logout after idle for more than 30 minutes
    logoutAfterMs: 1000 * 60 * 30,

    // Temporary errors, which could be redirected after refresh
    temporaryErrors: [
        'no-relying-parties',
        'cancelled-network-request',
    ],
};
