import { Vue } from '@evidentid/vue-property-decorator';

import JsonForm from '@evidentid/json-schema/interfaces/JsonForm';
import JsonSchema from '@evidentid/json-schema/interfaces/JsonSchema';
import { isArray, isEnum } from '@evidentid/json-schema/schemaChecks';
import { InsuranceCriterionSchema } from '@evidentid/rpweb-api-client/types';

export function getJsonSchemaCompFunction(
    customFormElementsMap: Record<string, Vue.Component>
): (form: JsonForm) => Vue.Component {
    return (form) => {
        const specialType = isArray(form.schema) && isEnum(form.schema.items as JsonSchema)
            ? 'multi_enum'
            : (form.schema as InsuranceCriterionSchema).displayFormat;
        return customFormElementsMap[specialType || ''] || customFormElementsMap[form.type];
    };
}
