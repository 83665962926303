import RpWebApiClient, { InsuredPatch } from '@evidentid/rpweb-api-client';
import { createStateFactory } from '@evidentid/vue-commons/store';
import {
    InsuranceAddInsuredsFailure,
    InsuranceInsured,
    InsuranceInsuredInput, InsuranceInsuredInputDataModel,
    InsuranceInsuredPostResponse,
    InsuranceInsuredUpsertResponse,
    InsuredPatchResult,
} from '@evidentid/rpweb-api-client/types';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import omit from 'lodash/omit';
import dashboard from '@/modules/dashboard/vuex/dashboard';
import { convertInsuredInputViewToData } from '@/modules/insured-management/utils/insuredProcessing';

function mergeBatchResults<T extends InsuranceInsuredPostResponse | InsuranceInsuredUpsertResponse>(
    partialResults: PromiseSettledResult<T>[]): T {
    return partialResults.reduce((result, partialResult) => (partialResult.status === 'fulfilled'
        ? {
            ...result,
            totalCount: result.totalCount + (partialResult.value.totalCount || 0),
            successCount: result.successCount + (partialResult.value.successCount || 0),
            failureCount: result.failureCount + (partialResult.value.failureCount || 0),
            successes: [ ...result.successes, ...(partialResult.value.successes || []) ],
            failures: [ ...result.failures, ...(partialResult.value.failures || []) ],
        }
        : result),
        {
            totalCount: 0,
            successCount: 0,
            failureCount: 0,
            successes: [] as InsuranceInsured[],
            failures: [] as T['failures'],
        } as T);
}

export interface InsuredManagementRequirement {
    rpweb: RpWebApiClient;
}

const createState = createStateFactory<InsuredManagementRequirement>();

// totalCount = total insureds requested for the batch call
// successOnRequestCount = successCount + failureCount(request succeed but entity not created/updated)
export interface BatchStatus {
    status: OperationStatus;
    progress: number;
    totalCount: number;
    successCount: number; // on success calls
    failureCount: number; // on success calls
    successOnRequestCount: number;
    failedOnRequestCount: number; // on failed calls
    successes: any[];
    failures: any[];
}

export interface BatchAddInsuredsStatus extends BatchStatus {
    successes: InsuranceInsured[];
    failures: InsuranceAddInsuredsFailure[];
}

export interface BatchUpdateInsuredsStatus extends BatchStatus {
    successes: InsuranceInsured[];
    failures: (InsuranceInsured | InsuranceInsuredInputDataModel)[];
}

export interface PatchInsuredsStatus {
    status: OperationStatus;
    successCount: number;
    failureCount: number;
    totalCount: number;
    successes: InsuredPatchResult[];
    failures: { element: InsuredPatchResult, error: { message: string, error: string } } [];
    currentlyUpdated: string[];
    isBulk: boolean;
}

interface InsuredManagementState {
    addInsuredsStatus: Record<string, BatchAddInsuredsStatus>;
    updateInsuredsStatus: Record<string, BatchUpdateInsuredsStatus>;
    patchInsuredsStatus: Record<string, PatchInsuredsStatus>;
}

const { instantiateState, createMutationsFactories } = createState<InsuredManagementState>(() => ({
    addInsuredsStatus: {},
    updateInsuredsStatus: {},
    patchInsuredsStatus: {},
}));

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({

    startAddingInsureds(payload: { rpName: string, count: number }) {
        this.addInsuredsStatus = {
            ...this.addInsuredsStatus,
            [payload.rpName]: {
                status: OperationStatus.loading,
                progress: 0,
                totalCount: payload.count,
                successCount: 0,
                failureCount: 0,
                successOnRequestCount: 0,
                failedOnRequestCount: 0,
                failures: [],
                successes: [],
            },
        };
    },
    failAddingInsureds(payload: { rpName: string }) {
        this.addInsuredsStatus = {
            ...this.addInsuredsStatus,
            [payload.rpName]: {
                status: OperationStatus.error,
                progress: 0,
                totalCount: this.addInsuredsStatus[payload.rpName]?.totalCount || 0,
                successCount: 0,
                failureCount: this.addInsuredsStatus[payload.rpName]?.totalCount || 0,
                successOnRequestCount: 0,
                failedOnRequestCount: 0,
                failures: [],
                successes: [],
            },
        };
    },
    progressAddingInsureds(payload: {
        rpName: string;
        progress: number;
    }) {
        this.addInsuredsStatus = {
            ...this.addInsuredsStatus,
            [payload.rpName]: {
                ...this.addInsuredsStatus[payload.rpName],
                progress: payload.progress,
            },
        };
    },
    finishAddingInsureds(payload: {
        rpName: string;
        totalCount: number;
        successCount: number;
        failureCount: number;
        successOnRequestCount: number;
        failedOnRequestCount: number;
        successes: InsuranceInsured[];
        failures: InsuranceAddInsuredsFailure[];
    }) {
        this.addInsuredsStatus = {
            ...this.addInsuredsStatus,
            [payload.rpName]: {
                status: OperationStatus.success,
                progress: 100,
                totalCount: payload.totalCount,
                successCount: payload.successCount,
                failureCount: payload.failureCount,
                successOnRequestCount: payload.successOnRequestCount,
                failedOnRequestCount: payload.failedOnRequestCount,
                failures: payload.failures,
                successes: payload.successes,
            },
        };
    },
    clearAddInsuredsStatus(rpName: string) {
        this.addInsuredsStatus = omit(this.addInsuredsStatus, [ rpName ]);
    },
    startUpdateInsureds(payload: { rpName: string, count: number }) {
        this.updateInsuredsStatus = {
            ...this.updateInsuredsStatus,
            [payload.rpName]: {
                status: OperationStatus.loading,
                progress: 0,
                totalCount: payload.count,
                successCount: 0,
                failureCount: 0,
                successOnRequestCount: 0,
                failedOnRequestCount: 0,
                successes: [],
                failures: [],
            },
        };
    },
    failUpdateInsureds(payload: { rpName: string }) {
        this.updateInsuredsStatus = {
            ...this.updateInsuredsStatus,
            [payload.rpName]: {
                status: OperationStatus.error,
                progress: 0,
                totalCount: this.updateInsuredsStatus[payload.rpName]?.totalCount || 0,
                successCount: 0,
                failureCount: 0,
                successOnRequestCount: 0,
                failedOnRequestCount: this.updateInsuredsStatus[payload.rpName]?.totalCount || 0,
                successes: [],
                failures: [],
            },
        };
    },
    progressUpdateInsureds(payload: {
        rpName: string;
        progress: number;
    }) {
        this.updateInsuredsStatus = {
            ...this.updateInsuredsStatus,
            [payload.rpName]: {
                ...this.updateInsuredsStatus[payload.rpName],
                progress: payload.progress,
            },
        };
    },
    finishUpdateInsureds(payload: {
        rpName: string;
        totalCount: number;
        successCount: number;
        failureCount: number;
        successOnRequestCount: number;
        failedOnRequestCount: number;
        successes: InsuranceInsured[];
        failures: (InsuranceInsured | InsuranceInsuredInputDataModel)[];
    }) {
        this.updateInsuredsStatus = {
            ...this.updateInsuredsStatus,
            [payload.rpName]: {
                status: OperationStatus.success,
                progress: 100,
                totalCount: payload.totalCount,
                successCount: payload.successCount,
                failureCount: payload.failureCount,
                failedOnRequestCount: payload.failedOnRequestCount,
                successOnRequestCount: payload.successOnRequestCount,
                successes: payload.successes,
                failures: payload.failures,
            },
        };
    },
    clearUpdateInsuredsStatus(rpName: string) {
        this.updateInsuredsStatus = omit(this.updateInsuredsStatus, [ rpName ]);
    },
    startPatchInsureds(payload: { rpName: string, insuredsUpdate: InsuredPatch[], isBulk: boolean }) {
        this.patchInsuredsStatus = {
            ...this.patchInsuredsStatus,
            [payload.rpName]: {
                status: OperationStatus.loading,
                successCount: 0,
                failureCount: 0,
                totalCount: payload.insuredsUpdate.length,
                successes: [],
                failures: [],
                currentlyUpdated: payload.insuredsUpdate.map((patch) => patch.id),
                isBulk: payload.isBulk,
            },
        };
    },
    failPatchInsureds(payload: {
        rpName: string;
        successCount: number;
        failureCount: number;
        totalCount: number;
        successes: InsuredPatchResult[];
        failures: { element: InsuredPatchResult, error: { message: string, error: string } }[];
    }) {
        this.patchInsuredsStatus = {
            ...this.patchInsuredsStatus,
            [payload.rpName]: {
                status: OperationStatus.error,
                successCount: payload.successCount,
                failureCount: payload.failureCount,
                totalCount: payload.totalCount,
                successes: payload.successes,
                failures: payload.failures,
                currentlyUpdated: [],
                isBulk: false,
            },
        };
    },
    finishPatchInsureds(payload: {
        rpName: string;
        successCount: number;
        failureCount: number;
        totalCount: number;
        successes: InsuredPatchResult[];
        failures: { element: InsuredPatchResult, error: { message: string, error: string } }[];
    }) {
        this.patchInsuredsStatus = {
            ...this.patchInsuredsStatus,
            [payload.rpName]: {
                status: OperationStatus.success,
                successCount: payload.successCount,
                failureCount: payload.failureCount,
                totalCount: payload.totalCount,
                successes: payload.successes,
                failures: payload.failures,
                currentlyUpdated: [],
                isBulk: false,
            },
        };
    },
    clearPatchInsuredsStatus(rpName: string) {
        this.patchInsuredsStatus = omit(this.patchInsuredsStatus, [ rpName ]);
    },
}));

const {
    instantiateActions,
    instantiateModule,
    getActions,
} = createActionFactories(({ rpweb }: InsuredManagementRequirement) => ({

    async addInsureds(payload: {
        rpName: string; insureds: InsuranceInsuredInput[];
    }) {
        const { rpName } = payload;
        const insureds = payload.insureds.map(convertInsuredInputViewToData);
        try {
            this.mutations.startAddingInsureds({ rpName, count: insureds.length });
            const updateProgress = (progress: number) => {
                this.mutations.progressAddingInsureds({ rpName, progress });
            };
            const batchResults = await rpweb.batchCreateInsuranceInsureds(rpName, insureds, 50, updateProgress);
            const results = mergeBatchResults(batchResults);
            const successOnRequestCount = results.totalCount;
            const failedOnRequestCount = insureds.length - successOnRequestCount;
            if (insureds.length > 0 && failedOnRequestCount === insureds.length) {
                this.mutations.failAddingInsureds({ rpName });
            } else {
                this.mutations.finishAddingInsureds({
                    rpName,
                    ...results,
                    totalCount: insureds?.length || 0,
                    successOnRequestCount,
                    failedOnRequestCount,
                });
            }
        } catch (error) {
            console.warn('Add insureds error', error);
            this.mutations.failAddingInsureds({ rpName });
        }
    },
    async updateInsureds(payload: {
        rpName: string;
        insureds: InsuranceInsuredInput[];
        updateInsuredsListState?: boolean;
    }) {
        const { rpName } = payload;
        const insureds = payload.insureds.map(convertInsuredInputViewToData);
        try {
            this.mutations.startUpdateInsureds({ rpName, count: insureds.length });
            const updateProgress = (progress: number) => {
                this.mutations.progressUpdateInsureds({ rpName, progress });
            };
            const batchResults = await rpweb.batchUpsertInsuranceInsureds(rpName, insureds, 50, updateProgress);
            const results = mergeBatchResults(batchResults);
            const successOnRequestCount = results.totalCount;
            const failedOnRequestCount = (insureds?.length - results.totalCount) || 0;
            if (insureds.length > 0 && failedOnRequestCount === insureds.length) {
                this.mutations.failUpdateInsureds({ rpName });
            } else {
                this.mutations.finishUpdateInsureds({
                    rpName,
                    ...results,
                    totalCount: insureds?.length || 0,
                    successOnRequestCount,
                    failedOnRequestCount,
                });
                if (payload.updateInsuredsListState) {
                    await dashboard.getActions(this).updateInsuredsInList({ rpName, insureds: results.successes });
                }
            }
        } catch (error) {
            console.warn('Update insureds error', error);
            this.mutations.failUpdateInsureds({ rpName });
        }
    },
    clearAddInsuredsStatus(payload: { rpName: string }) {
        this.mutations.clearAddInsuredsStatus(payload.rpName);
    },
    clearUpdateInsuredsStatus(payload: { rpName: string }) {
        this.mutations.clearUpdateInsuredsStatus(payload.rpName);
    },
    clearPatchInsuredsStatus(payload: { rpName: string }) {
        this.mutations.clearPatchInsuredsStatus(payload.rpName);
    },
    async patchInsureds(payload: {
        rpName: string;
        patch: InsuredPatch[];
        isBulk: boolean;
    }) {
        const { rpName, patch, isBulk } = payload;
        try {
            this.mutations.startPatchInsureds({ rpName, insuredsUpdate: patch, isBulk });
            const { successCount, failureCount, totalCount, successes, failures } =
                await rpweb.patchInsuranceInsured(rpName, patch);
            if (totalCount === failureCount) {
                this.mutations.failPatchInsureds(
                    { rpName, successCount, failureCount, totalCount, successes, failures },
                );
            } else {
                this.mutations.finishPatchInsureds({
                    rpName,
                    successCount,
                    failureCount,
                    totalCount,
                    successes,
                    failures,
                });
                await dashboard.getActions(this).patchInsuredsInList(
                    { rpName: payload.rpName, insuredsPatch: successes });
            }
        } catch (error) {
            console.warn('Patch insureds error', error);
            this.mutations.failPatchInsureds({
                rpName,
                successCount: 0,
                failureCount: patch.length,
                totalCount: patch.length,
                successes: [],
                failures: [],
            });
        }
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};
