import omit from 'lodash/omit';
import { createStateFactory } from '@evidentid/vue-commons/store';
import type RpWebApiClient from '@evidentid/rpweb-api-client';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import { getPersistingErrorActions } from '@evidentid/dashboard-commons/modules/persisting-error';
import { getInsuranceDetails } from '@/modules/insured-certificates/services/getInsuranceDetails';
import type { CoiDetails } from '@/modules/insured-certificates/types';

interface CoiResult {
    status: OperationStatus;
    content: CoiDetails[];
}

export interface InsuredCertificatesRequirements {
    rpweb: RpWebApiClient;
}

export interface InsuredCertificatesState {
    cois: Record<string, Record<string, CoiResult>>;
}

const createState = createStateFactory<InsuredCertificatesRequirements>();

const { instantiateState, createMutationsFactories } = createState<InsuredCertificatesState>(() => ({
    cois: {},
}));

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({
    startLoadingCoi(payload: { rpName: string, email: string }) {
        this.cois = {
            ...this.cois,
            [payload.rpName]: {
                ...this.cois[payload.rpName],
                [payload.email]: {
                    status: OperationStatus.loading,
                    content: [],
                },
            },
        };
    },
    failLoadingCoi(payload: { rpName: string, email: string }) {
        this.cois = {
            ...this.cois,
            [payload.rpName]: {
                ...this.cois[payload.rpName],
                [payload.email]: {
                    status: OperationStatus.error,
                    content: [],
                },
            },
        };
    },
    finishLoadingCoi(payload: { rpName: string, email: string, content: CoiDetails[] }) {
        this.cois = {
            ...this.cois,
            [payload.rpName]: {
                ...this.cois[payload.rpName],
                [payload.email]: {
                    status: OperationStatus.success,
                    content: payload.content,
                },
            },
        };
    },
    clearCois(payload: { rpName: string, email: string }) {
        this.cois = {
            ...this.cois,
            [payload.rpName]: omit({
                ...this.cois[payload.rpName],
            }, payload.email),
        };
    },
}));

const { instantiateActions, instantiateModule, getActions } = createActionFactories(({ rpweb }) => ({
    async loadCoiDetails(payload: { rpName: string, email: string }) {
        const { rpName, email } = payload;
        try {
            this.mutations.startLoadingCoi({ rpName, email });
            const content = await getInsuranceDetails(rpweb, rpName, email);
            this.mutations.finishLoadingCoi({ rpName, email, content });
        } catch (error) {
            console.warn('GET COIs error', error);
            await getPersistingErrorActions(this).showError(error);
            this.mutations.failLoadingCoi({ rpName, email });
        }
    },
    async clearCoiDetails(payload: { rpName: string, email: string }) {
        this.mutations.clearCois(payload);
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};
