import RpWebApiClient from '@evidentid/rpweb-api-client';
import { createStateFactory } from '@evidentid/vue-commons/store';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import omit from 'lodash/omit';
import {
    InsuranceExceptionInput,
    InsuranceInsuredCoverageDetails,
} from '@evidentid/rpweb-api-client/types';
import {
    transformCollateralsDetails,
} from '@/modules/insured-details/utils/transformCollateralsDetails/transformCollateralsDetails';
import { CollateralTabCollateral } from '@evidentid/rpweb-api-client/models/CollateralTabCollateral.model';

export interface InsuredDetailsRequirement {
    rpweb: RpWebApiClient;
}

const createState = createStateFactory<InsuredDetailsRequirement>();

export interface CoverageDetailsListStatus {
    status: OperationStatus;
    list: InsuranceInsuredCoverageDetails[];
}

interface InsuredDetailsState {
    coverageDetailsListStatus: Record<string, CoverageDetailsListStatus>;
    collateralEntitiesStatus: Record<string, { status: OperationStatus, list: CollateralTabCollateral[] }>;
    grantExceptionStatus: Record<string, OperationStatus>;
    removeExceptionStatus: Record<string, OperationStatus>;
}

const { instantiateState, createMutationsFactories } = createState<InsuredDetailsState>(() => ({
    coverageDetailsListStatus: {},
    collateralEntitiesStatus: {},
    grantExceptionStatus: {},
    removeExceptionStatus: {},
}));

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({

    startLoadingCoverageDetailsList(payload: { rpName: string }) {
        this.coverageDetailsListStatus = {
            ...this.coverageDetailsListStatus,
            [payload.rpName]: {
                status: OperationStatus.loading,
                list: this.coverageDetailsListStatus[payload.rpName]?.list || [],
            },
        };
    },
    failLoadingCoverageDetailsList(payload: { rpName: string }) {
        this.coverageDetailsListStatus = {
            ...this.coverageDetailsListStatus,
            [payload.rpName]: {
                status: OperationStatus.error,
                list: this.coverageDetailsListStatus[payload.rpName]?.list || [],
            },
        };
    },

    finishLoadingCoverageDetailsList(payload: {
        rpName: string;
        list: InsuranceInsuredCoverageDetails[];
    }) {
        payload.list.forEach((coverage) => {
            if (!coverage.coverage) {
                return;
            }

            let collateral = coverage.coverage.details?.collateral;

            if (collateral) {
                collateral = transformCollateralsDetails(collateral as any);
                coverage.coverage.details.collateral = collateral;
            }
        });

        this.coverageDetailsListStatus = {
            ...this.coverageDetailsListStatus,
            [payload.rpName]: {
                status: OperationStatus.success,
                list: payload.list,
            },
        };
    },
    clearCoverageDetailsList(rpName: string) {
        this.coverageDetailsListStatus = omit(this.coverageDetailsListStatus, [ rpName ]);
    },

    startLoadingCollateralEntities(payload: { rpName: string }) {
        this.collateralEntitiesStatus = {
            ...this.collateralEntitiesStatus,
            [payload.rpName]: {
                status: OperationStatus.loading,
                list: this.collateralEntitiesStatus[payload.rpName]?.list || [],
            },
        };
    },
    failLoadingCollateralEntities(payload: { rpName: string }) {
        this.collateralEntitiesStatus = {
            ...this.collateralEntitiesStatus,
            [payload.rpName]: {
                status: OperationStatus.error,
                list: this.collateralEntitiesStatus[payload.rpName]?.list || [],
            },
        };
    },

    finishLoadingCollateralEntities(payload: {
        rpName: string;
        list: CollateralTabCollateral[];
    }) {
        this.collateralEntitiesStatus = {
            ...this.collateralEntitiesStatus,
            [payload.rpName]: {
                status: OperationStatus.success,
                list: payload.list,
            },
        };
    },
    clearCollateralEntities(rpName: string) {
        this.collateralEntitiesStatus = omit(this.collateralEntitiesStatus, [ rpName ]);
    },

    startGrantingExceptions(payload: { rpName: string }) {
        this.grantExceptionStatus = {
            ...this.grantExceptionStatus,
            [payload.rpName]: OperationStatus.loading,
        };
    },
    failGrantingExceptions(payload: { rpName: string }) {
        this.grantExceptionStatus = {
            ...this.grantExceptionStatus,
            [payload.rpName]: OperationStatus.error,
        };
    },
    finishGrantingExceptions(payload: {
        rpName: string;
    }) {
        this.grantExceptionStatus = {
            ...this.grantExceptionStatus,
            [payload.rpName]: OperationStatus.success,
        };
    },
    clearGrantExceptionsStatus(rpName: string) {
        this.grantExceptionStatus = omit(this.grantExceptionStatus, [ rpName ]);
    },

    startRemovingExceptions(payload: { rpName: string }) {
        this.removeExceptionStatus = {
            ...this.removeExceptionStatus,
            [payload.rpName]: OperationStatus.loading,
        };
    },
    failRemovingExceptions(payload: { rpName: string }) {
        this.removeExceptionStatus = {
            ...this.removeExceptionStatus,
            [payload.rpName]: OperationStatus.error,
        };
    },
    finishRemovingExceptions(payload: {
        rpName: string;
    }) {
        this.removeExceptionStatus = {
            ...this.removeExceptionStatus,
            [payload.rpName]: OperationStatus.success,
        };
    },
    clearRemoveExceptionsStatus(rpName: string) {
        this.removeExceptionStatus = omit(this.removeExceptionStatus, [ rpName ]);
    },
}));

const {
    instantiateActions,
    instantiateModule,
    getActions,
} = createActionFactories(({ rpweb }: InsuredDetailsRequirement) => ({

    async loadCoverageDetailsList(payload: {
        rpName: string; insuredId: string;
    }) {
        const { rpName, insuredId } = payload;
        try {
            this.mutations.startLoadingCoverageDetailsList({ rpName });
            const list = await rpweb.getInsuranceCoveragesDetailsList(rpName, insuredId);
            this.mutations.finishLoadingCoverageDetailsList({ rpName, list });
        } catch (error) {
            console.warn('load coverage details error', error);
            this.mutations.failLoadingCoverageDetailsList({ rpName });
        }
    },
    clearCoverageDetailsList(payload: { rpName: string }) {
        this.mutations.clearCoverageDetailsList(payload.rpName);
    },
    async loadCollateralEntities(payload: {
        rpName: string; insuredId: string;
    }) {
        const { rpName, insuredId } = payload;
        try {
            this.mutations.startLoadingCollateralEntities({ rpName });
            const list = await rpweb.getInsuranceCollaterals(rpName, insuredId);
            this.mutations.finishLoadingCollateralEntities({ rpName, list });
        } catch (error) {
            console.warn('load collateral entities error', error);
            this.mutations.failLoadingCollateralEntities({ rpName });
        }
    },
    clearCollateralEntities(payload: { rpName: string }) {
        this.mutations.clearCollateralEntities(payload.rpName);
    },
    async grantExceptions(payload: {
        rpName: string;
        insuredId: string;
        exceptions: InsuranceExceptionInput[];
    }) {
        const { rpName, insuredId, exceptions } = payload;
        try {
            this.mutations.startGrantingExceptions({ rpName });
            await rpweb.grantException(rpName, insuredId, exceptions);
            this.mutations.finishGrantingExceptions({ rpName });
        } catch (error) {
            this.mutations.failGrantingExceptions({ rpName });
        }
    },
    clearGrantExceptionsStatus(payload: { rpName: string }) {
        this.mutations.clearGrantExceptionsStatus(payload.rpName);
    },

    async removeException(payload: {
        rpName: string;
        insuredId: string;
        exceptionId: string;
    }) {
        const { rpName, insuredId, exceptionId } = payload;
        try {
            this.mutations.startRemovingExceptions({ rpName });
            await rpweb.removeException(rpName, insuredId, exceptionId);
            this.mutations.finishRemovingExceptions({ rpName });
        } catch (error) {
            this.mutations.failRemovingExceptions({ rpName });
        }
    },
    clearRemoveExceptionsStatus(payload: { rpName: string }) {
        this.mutations.clearRemoveExceptionsStatus(payload.rpName);
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};
