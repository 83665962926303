import { JsonSchemaObject, JsonSchemaString, JsonSchemaType } from '@evidentid/json-schema/interfaces/JsonSchema';

export function buildEmailTemplateSchema(insuredFields: string[]): JsonSchemaObject {
    const summarySchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        minLength: 1,
        title: 'Email subject',
        description: 'Enter subject',
    };

    const descriptionSchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        format: 'html',
        minLength: 1,
        title: 'Email body text',
        description: 'Enter HTML body',
        variables: [
            'RELYING_PARTY:commonName',
            'RELYING_PARTY:displayName',
            'RELYING_PARTY:certificateHolderAddress',
            'VERIFICATION:policyNames',
            'VERIFICATION:policyItems',
            'INSURED:contactEmail',
            'INSURED:contactName',
            'INSURED:contactPhoneNumber',
            'INSURED:displayName',
            ...insuredFields.map((name) => `INSURED:insuredFields.${name}`),
        ],
        htmlExtensions: {
            links: true,
        },
    };

    return {
        type: JsonSchemaType.object,
        properties: {
            summaryTemplate: summarySchema,
            descriptionTemplate: descriptionSchema,
        },
        required: [ 'summaryTemplate', 'descriptionTemplate' ],
    };
}
