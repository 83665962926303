import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import legacyLogger from '@evidentid/universal-framework/logger';
import trackOnlineStatusInSentry from '@evidentid/vue-commons/trackOnlineStatusInSentry';

const isSentryBlocked = process.env.NODE_ENV === 'development';
const dsn = deploy.WEB_PUBLIC_SENTRY_DSN;
const environment = deploy.WEB_PUBLIC_ENVIRONMENT || 'development';

const isSentryAvailable = dsn && !isSentryBlocked;

if (isSentryAvailable) {
    const SINGLE_SESSION_ERRORS_LIMIT = 50;
    let errorsCount = 0;

    Sentry.init({
        dsn,
        environment,
        maxBreadcrumbs: 100,
        beforeSend: (event) => {
            errorsCount++;
            return errorsCount >= SINGLE_SESSION_ERRORS_LIMIT ? null : event;
        },
        integrations: [
            // @ts-ignore: TS 3.9.5 has problem with detecting it as Integration
            new Integrations.CaptureConsole({
                levels: [ 'error' ],
            }),
            // @ts-ignore: TS 3.9.5 has problem with detecting it as Integration
            new Integrations.Vue({
                // @ts-ignore: Sentry expects Vue instance, but it works directly too
                Vue,
                attachProps: false,
            }),
        ],
    });

    // Track User's connection status
    trackOnlineStatusInSentry(Sentry);

    // Set sentry in legacy logger as well
    legacyLogger.setSentry(Sentry);
} else if (isSentryBlocked) {
    console.warn('Sentry is not initialized - it was blocked for current environment.');
} else {
    console.warn('Sentry is not initialized - DSN was not provided.');
}

export default isSentryAvailable ? Sentry : null;
