import {
    InsuranceCoverageType,
    InsuranceInsuredField,
    InsuranceInsuredFieldType,
    InsuranceInsuredFieldValue,
    InsuranceInsuredInput, InsuranceInsuredInputDataModel,
} from '@evidentid/rpweb-api-client/types';
import moment from 'moment';
import mapValues from 'lodash/mapValues';
import negate from 'lodash/negate';
import pickBy from 'lodash/pickBy';
import { trimFieldValue } from '@/modules/insured-management/utils/trimFieldValue';
import { BaseInsuredInput } from '@/modules/insured-management/types';

function isPastDate(date: string): boolean {
    return moment(date).isBefore(moment(), 'day');
}

export function hasPastExpirationDate(insured: InsuranceInsuredInput | BaseInsuredInput): boolean {
    return Object.values(insured.exceptions || {}).some(isPastDate);
}

export function trimInsured(
    insured: InsuranceInsuredInput | BaseInsuredInput): InsuranceInsuredInput | BaseInsuredInput {
    const baseFields = mapValues(insured, trimFieldValue);
    const insuredFields = mapValues(insured.insuredFields, trimFieldValue);
    return { ...baseFields, insuredFields: { ...insuredFields } } as unknown as InsuranceInsuredInput;
}

export function filterInsuredEmptyItems(insured: InsuranceInsuredInput | BaseInsuredInput,
    insuredFields: InsuranceInsuredField[]): InsuranceInsuredInput | BaseInsuredInput {
    const filterArrayEmptyItems = (value: InsuranceInsuredFieldType): InsuranceInsuredFieldType =>
        (Array.isArray(value) ? (value as []).filter(Boolean) : value);
    const baseFields = mapValues(insured, filterArrayEmptyItems);

    const optional = insuredFields.filter((x) => !x.required);
    const insuredFieldValues = Object.entries(insured.insuredFields || {})
        .reduce((acc, [ key, val ]) => {
            const newVal = optional.find((x) => x.key === key) ? filterArrayEmptyItems(val) : val;
            return { ...acc, [key]: newVal } as InsuranceInsuredFieldValue;
        }, {} as InsuranceInsuredFieldValue);
    return { ...baseFields, insuredFields: { ...insuredFieldValues } } as unknown as InsuranceInsuredInput;
}

export function convertInsuredInputViewToData(insured: InsuranceInsuredInput): InsuranceInsuredInputDataModel {
    const viewExceptions = insured.exceptions || {};
    const exceptions = Object.keys(viewExceptions).map((key) => ({
        coverageType: key as InsuranceCoverageType,
        until: viewExceptions[key],
    }));
    return { ...insured, exceptions };
}

export function convertInsuredInputDataToView(insured: InsuranceInsuredInputDataModel): InsuranceInsuredInput {
    const dataExceptions = insured.exceptions || [];
    const exceptions = dataExceptions.reduce((accu, exception) => ({
        ...accu,
        [exception.coverageType]: exception.until,
    }), {});
    return { ...insured, exceptions };
}
