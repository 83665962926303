import { DayOfWeek } from '@evidentid/dashboard-commons/components/DayOfWeekInput/types';
import timezones from '@evidentid/common-datasets/timezones.json';
import { get12HourTimeLabel } from '@/utils/get12HourTimeLabel';
import { formatSupportDays } from '@/utils/formatSupportDays';

interface Timezone {
    name: string;
    city: string;
}

export interface SupportHoursSettings {
    supportDaysOfWeek?: DayOfWeek[];
    supportStartTime?: string;
    supportEndTime?: string;
    supportTimezone?: string;
}

export function formatSupportHours(data: SupportHoursSettings): string | null {
    if (!data.supportStartTime || !data.supportEndTime) {
        return null;
    }
    const startTime = get12HourTimeLabel(data.supportStartTime, '');
    const endTime = get12HourTimeLabel(data.supportEndTime, '');
    const days = data.supportDaysOfWeek ? formatSupportDays(data.supportDaysOfWeek) : null;
    const timezone = (timezones as Timezone[]).find((x) => x.city === data.supportTimezone);
    return timezone && days
        ? `${days},  ${startTime} – ${endTime} ${timezone.name}`
        : null;
}
