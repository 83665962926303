import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import createValueValidator from './createValueValidator';
import generateRandomKey from './generateRandomKey';
import * as checks from './schemaChecks';
import JsonSchema, {
    JsonSchemaArray,
    JsonSchemaBasicObject,
    JsonSchemaBoolean,
    JsonSchemaComplexObject,
    JsonSchemaConst,
    JsonSchemaEnum,
    JsonSchemaInteger,
    JsonSchemaNumber,
    JsonSchemaObject,
    JsonSchemaString,
    JsonSchemaType,
} from './interfaces/JsonSchema';
import JsonForm, {
    IS_VALID_FNC_PROP_KEY_NAME,
    JsonFormBySchema,
    JsonFormObject,
    JsonFormProperty,
    JsonFormType,
    PartialJsonForm,
    PartialJsonFormArray,
    PartialJsonFormBase,
    PartialJsonFormBySchema,
    PartialJsonFormObject,
} from './interfaces/JsonForm';

/* eslint-disable no-use-before-define */

/**
 * Create form for JSON Schema.
 *
 * It will analyze schema to:
 *  - exposing its structure (i.e. for UI),
 *  - ensure data integrity
 *  - set up validators
 */
export default function createForm<T extends JsonSchema>(schema: T): JsonFormBySchema<T>;
export default function createForm(schema: JsonSchema): JsonForm {
    // These checks are prepared to ensure type-safety
    if (checks.isConst(schema)) {
        return aggregateForm(schema, analyzeConstSchema(schema));
    } else if (checks.isEnum(schema)) {
        return aggregateForm(schema, analyzeEnumSchema(schema));
    } else if (checks.isObject(schema)) {
        return aggregateForm(schema, analyzeObjectSchema(schema));
    } else if (checks.isArray(schema)) {
        return aggregateForm(schema, analyzeArraySchema(schema as JsonSchemaArray<JsonSchema>));
    } else if (checks.isString(schema)) {
        return aggregateForm(schema, analyzeStringSchema(schema, ''));
    } else if (checks.isBoolean(schema)) {
        return aggregateForm(schema, analyzeBooleanSchema(schema));
    } else if (checks.isInteger(schema)) {
        return aggregateForm(schema, analyzeIntegerSchema(schema));
    }

    return aggregateForm(schema, analyzeNumberSchema(schema));
}

/**
 * Apply default values, options and methods for partial JSON Schema forms.
 */
function aggregateForm<T extends JsonSchema>(schema: T, analysis: PartialJsonFormBySchema<T>): JsonFormBySchema<T>;
function aggregateForm(schema: JsonSchema, analysis: PartialJsonForm): JsonForm {
    const { getValue, isValid: analysisIsValid, ...analysisOther } = analysis;
    const validate = createValueValidator(schema);
    const formProps = {
        key: generateRandomKey(),
        schema,
        getValue,
        isValid(value?: any, ignoreOptionalEmpty?: boolean) {
            const isValidFnc = this[IS_VALID_FNC_PROP_KEY_NAME];
            const _value = getValue(value, ignoreOptionalEmpty);
            const isValidByCustomValidation = isValidFnc ? isValidFnc(_value) : true;
            const isValidByJsonSchema = analysisIsValid ?
                analysisIsValid(value, ignoreOptionalEmpty)
                : validate(_value);
            return isValidByCustomValidation && isValidByJsonSchema;
        },
        ...analysisOther,
    };
    const form = Object.create({} as JsonForm);
    Object.defineProperties(
        form,
        Object.entries(formProps).reduce((result, [ key, value ]) => {
            result[key] = {
                value,
                enumerable: true,
                writable: true,
                configurable: true,
            };
            return result;
        }, {} as PropertyDescriptorMap),
    );
    return form;
}

/**
 * Set-up definition for Enum form.
 */
function analyzeEnumSchema(_: JsonSchemaEnum): PartialJsonFormBase<JsonSchemaEnum> {
    return {
        type: JsonFormType.enum,
        getValue: (value?: any) => (value == null ? null : value),
        isEmpty: (value?: any) => (value == null),
    };
}

function getNumberValue(value?: any) {
    if (value == null) {
        return null;
    } else if (typeof value === 'string') {
        const rawValue = value.trim();
        if (rawValue.length === 0) {
            return null;
        }
        // Disallow exponential strings
        return isNaN(rawValue.replace(/[eE]/, 'x') as any)
            ? rawValue
            : parseFloat(rawValue);
    }
    return value;
}

/**
 * Set-up definition for Number form.
 */
function analyzeNumberSchema(schema: JsonSchemaNumber): PartialJsonFormBase<JsonSchemaNumber> {
    const validate = createValueValidator(schema);
    const getValue = getNumberValue;

    function isValid(value?: any) {
        const v = getValue(value);
        return v == null || isNaN(v) ? false : validate(v);
    }

    function isEmpty(value?: any) {
        return value == null || (typeof value === 'number' && isNaN(value));
    }

    return { type: JsonFormType.number, isValid, isEmpty, getValue };
}

/**
 * Set-up definition for Integer form.
 */
function analyzeIntegerSchema(schema: JsonSchemaInteger): PartialJsonFormBase<JsonSchemaInteger> {
    const validate = createValueValidator(schema);
    const getValue = getNumberValue;

    function isValid(value?: any) {
        const v = getValue(value);
        return v == null || isNaN(v) || v % 1 !== 0 ? false : validate(v);
    }

    function isEmpty(value?: any) {
        return value == null || (typeof value === 'number' && isNaN(value));
    }

    return { type: JsonFormType.number, isValid, isEmpty, getValue };
}

/**
 * Set-up definition for primitives (boolean, string & number) form.
 */
function analyzeStringSchema(schema: JsonSchemaString, defaultValue?: any): PartialJsonFormBase<JsonSchemaString> {
    return {
        type: JsonFormType.string,
        getValue: (value?: any) => {
            if (value === undefined) {
                return defaultValue;
            } else if (typeof value === 'string' && schema.format === 'phone') {
                const strippedNumber = value.replace(/[()|\-\s.]+/g, '');
                return strippedNumber && strippedNumber[0] !== '+' ? `+1${strippedNumber}` : strippedNumber;
            } else if (typeof value === 'number' && !isNaN(value)) {
                return value.toString();
            }
            return value;
        },
        isEmpty: (value?: any) => (value == null || value === ''),
    };
}

/**
 * Set-up definition for primitives (boolean, string & number) form.
 */
function analyzeBooleanSchema(_: JsonSchemaBoolean, defaultValue?: any): PartialJsonFormBase<JsonSchemaBoolean> {
    return {
        type: JsonFormType.boolean,
        getValue: (value?: any) => (value === undefined ? defaultValue : value),
        isEmpty: (value?: any) => (value == null || value === ''),
    };
}

/**
 * Set-up definition for constant form.
 */
function analyzeConstSchema(schema: JsonSchemaConst): PartialJsonFormBase<JsonSchemaConst> {
    const value = schema.const;

    return {
        type: JsonFormType.const,
        getValue: (v?: any) => (isEqual(v, value) ? v : cloneDeep(value)),
        isEmpty: (v?: any) => (v == null),
    };
}

/**
 * Set-up definition for list form.
 */
function analyzeArraySchema(schema: JsonSchemaArray<JsonSchema>): PartialJsonFormArray {
    if (!schema.items) {
        throw new Error('JSON Schema error: `items` is required for array.');
    }

    const item = createForm(schema.items);
    const minItems = parseInt(schema.minItems as unknown as string, 10) || 0;

    function getValue(value?: any, ignoreOptionalEmpty?: boolean) {
        // When the value is not an array, build empty array with minimum number of items
        if (!Array.isArray(value)) {
            return new Array(minItems).fill(null).map(() => item.getValue());
        }

        // Build expected value for array items
        const expectedValue = value.map((element) => item.getValue(element, ignoreOptionalEmpty));

        // Return same value, when input elements has valid structure.
        return expectedValue.findIndex((element, index) => element !== value[index]) === -1
            ? value
            : expectedValue;
    }

    function isEmpty(value?: any) {
        return value == null || (Array.isArray(value) && value.length === 0);
    }

    return {
        type: JsonFormType.array,
        isEmpty,
        getValue,
        item,
    };
}

/**
 * Set-up definition for object form.
 */
function analyzeObjectSchema(
    schema: JsonSchemaObject,
    inheritedProperties: JsonFormProperty[] = [],
): PartialJsonFormObject {
    const inheritedNames = inheritedProperties.map((property) => property.name);
    const ownProperties = extractJsonFormPropertiesList(schema)
        .filter((property) => !inheritedNames.includes(property.name));
    const properties = [ ...inheritedProperties, ...ownProperties ].sort(getPropertiesSortComparator(schema));
    const getValue = createObjectGetValueFactory(properties, schema.required || []);

    // Build simple form for objects without conditional properties
    if (!checks.isComplexObject(schema)) {
        const validate = createValueValidator(schema);
        const isValid = (value?: any, ignoreOptionalEmpty?: boolean) => validate(getValue(value, ignoreOptionalEmpty));
        const isEmpty = (value?: any, ignoreOptionalEmpty?: boolean) => (
            value == null ||
            Object.keys(getValue(value, ignoreOptionalEmpty)).length === 0
        );
        return {
            type: JsonFormType.object,
            isValid,
            isEmpty,
            getProperties: () => properties,
            getValue,
        };
    }

    // Build a function to check if "then" branch is currently selected
    const isThenPassing = createValueValidator({ type: JsonSchemaType.object, ...schema.if } as JsonSchemaObject);

    const then = createObjectBranchForm(schema, 'then', properties);
    const otherwise = createObjectBranchForm(schema, 'else', properties);

    // Choose branch based on value
    const getBranch = (value: any): JsonFormObject => (isThenPassing(getValue(value, true)) ? then : otherwise);

    return {
        type: JsonFormType.object,
        getValue: (value?: any, ignoreOptionalEmpty?: boolean) => (
            getBranch(getValue(value, ignoreOptionalEmpty)).getValue(value, ignoreOptionalEmpty)
        ),
        getProperties: (value?: any) => getBranch(getValue(value)).getProperties(value),
        isValid: (value?: any, ignoreOptionalEmpty?: boolean) => getBranch(value).isValid(value, ignoreOptionalEmpty),
        isEmpty: (value?: any) => getBranch(value).isEmpty(value),
    };
}

/**
 * Create form for schema branch (then/else).
 * It will recursively call branches down all the time.
 */
function createObjectBranchForm(
    parentSchema: JsonSchemaComplexObject,
    branch: 'then' | 'else',
    properties: JsonFormProperty[],
): JsonFormObject {
    const schema = combineObjectSchemas(parentSchema, parentSchema[branch]);
    const overriddenProperties = Object.keys(parentSchema[branch].properties || {});
    const inheritedProperties = properties.filter((property) => !overriddenProperties.includes(property.name));
    return aggregateForm(schema, analyzeObjectSchema(schema, inheritedProperties));
}

/**
 * Combine object schemas, to allow building object schema from conditional partials.
 */
function combineObjectSchemas(
    schema: JsonSchemaComplexObject,
    extension: Partial<JsonSchemaBasicObject>,
): JsonSchemaBasicObject;
function combineObjectSchemas(
    schema: JsonSchemaComplexObject,
    extension: Partial<JsonSchemaComplexObject>,
): JsonSchemaComplexObject;
function combineObjectSchemas(schema: JsonSchemaComplexObject, extension: Partial<JsonSchemaObject>): JsonSchemaObject {
    return {
        ...schema,
        if: undefined,
        then: undefined,
        else: undefined,
        ...extension,
        properties: { ...schema.properties, ...extension.properties || {} },
        propertiesOrder: [ ...schema.propertiesOrder || [], ...extension.propertiesOrder || [] ],
        required: [ ...schema.required || [], ...extension.required || [] ],
    } as JsonSchemaObject;
}

/**
 * Extract not sorted list of form properties from JSON schema.
 */
function extractJsonFormPropertiesList(schema: JsonSchemaObject): JsonFormProperty[] {
    const required = schema.required || [];
    return Object.keys(schema.properties || {}).map((name) => ({
        form: createForm(schema.properties[name]),
        required: required.includes(name),
        name,
    }));
}

/**
 * Build sorting comparator for JSON schema properties list.
 */
function getPropertiesSortComparator(schema: JsonSchemaObject): (a: JsonFormProperty, b: JsonFormProperty) => number {
    const propertiesOrder = getPropertiesOrder(schema);
    return (a, b) => propertiesOrder.indexOf(a.name) - propertiesOrder.indexOf(b.name);
}

/**
 * Get list of all JSON schema object property names, sorted in proper order.
 */
function getPropertiesOrder(schema: JsonSchemaObject): string[] {
    return Array.from(new Set([
        ...schema.propertiesOrder || [],
        ...Object.keys(schema.properties || {}),
    ]));
}

/**
 * Create factory for building JSON schema object value.
 * When every property is valid, it will return input object.
 */
function createObjectGetValueFactory(
    properties: JsonFormProperty[],
    requiredProperties: string[],
): (value?: any, ignoreOptionalEmpty?: boolean) => any {
    return (value, ignoreOptionalEmpty = false) => {
        const input: { [key: string]: any } = value && typeof value === 'object' ? value : {};
        const result: { [key: string]: any } = {};

        let isDifferent = input !== value;
        for (const { name, form } of properties) {
            if (!ignoreOptionalEmpty || requiredProperties.includes(name) || !form.isEmpty(input[name])) {
                result[name] = form.getValue(input[name], ignoreOptionalEmpty);
            }
            isDifferent = isDifferent || input[name] !== result[name];
        }

        return isDifferent ? result : value;
    };
}
