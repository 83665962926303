import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import ConfirmFilterOverrideProcedure from '@/modules/insured-filtering/procedures/ConfirmFilterOverrideProcedure.vue';

export const insuredFilteringModule = createModuleBuilder()
    .registerProcedures<{
        confirmFilterOverride: {};
    }>({
        confirmFilterOverride: ConfirmFilterOverrideProcedure,
    })
    .end();
