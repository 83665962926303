import { JsonSchemaObject, JsonSchemaString, JsonSchemaType } from '@evidentid/json-schema/interfaces/JsonSchema';

export function buildBrandingSettingsSchema(): JsonSchemaObject {
    const displayNameSchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        minLength: 1,
        title: 'Company Name',
        description: 'Enter company name',
    };

    const wordmarkUrlSchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        format: 'image-url',
        minLength: 1,
        maxLength: 2e6, // ~1MB in Base64 Data URL
        title: 'Wordmark in PNG or JPG format (1MB max.)',
    };

    const primaryColorSchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        format: 'color',
        minLength: 1,
        title: 'Primary color (for email elements such as boxes, buttons)',
    };

    const secondaryColorSchema: JsonSchemaString = {
        type: JsonSchemaType.string,
        format: 'color',
        minLength: 1,
        title: 'Secondary color (for text on buttons, like the "START INSURANCE VERIFICATION NOW" text)',
    };

    return {
        type: JsonSchemaType.object,
        properties: {
            displayName: displayNameSchema,
            wordmarkUrl: wordmarkUrlSchema,
            primaryColor: primaryColorSchema,
            secondaryColor: secondaryColorSchema,
        },
        required: [ 'displayName', 'wordmarkUrl', 'primaryColor', 'secondaryColor' ],
    };
}
