import url from '@evidentid/universal-framework/url';
import parseJsonConditionally from '@evidentid/universal-framework/parseJsonConditionally';
import createCustomXhrErrorFactory from '@evidentid/universal-framework/createCustomXhrErrorFactory';
import {
    Branding,
    BrandingInput,
    Cadence,
    CadenceInput,
    ConfigRelyingParty,
    ConfigRelyingPartyInput,
    NotificationPreference,
    NotificationName,
    RawMacroType,
    MacroType,
    Macro,
    RawMacro,
    MacroInput,
    MacroUpdate,
    RpFulfillmentConfig,
} from './types';

const statusErrorFactories: Record<number, (xhr?: XMLHttpRequest) => Error> = {
    401: createCustomXhrErrorFactory('unauthorized', 'You are not authorized for selected operation'),
    403: createCustomXhrErrorFactory('forbidden', 'You are not authorized for selected operation'),
    404: createCustomXhrErrorFactory('not-found', 'The requested resource is not found'),
    440: createCustomXhrErrorFactory('session-expired', 'Your session has expired'),
};

function formatMacro(macro: RawMacro): Macro {
    return {
        id: macro._id,
        type: {
            id: macro.macroType._id,
            name: macro.macroType.type,
        },
        title: macro.title,
        content: macro.text,
        createdAt: new Date(Date.parse(macro.createdAt)),
        updatedAt: new Date(Date.parse(macro.updatedAt)),
    };
}

interface ApiClientTokens {
    accessToken: string | null;
    idToken: string | null;
}

class ConfigApiClient {
    protected baseUrl: string;
    protected getTokens: () => Promise<ApiClientTokens> = () => Promise.resolve({
        accessToken: null,
        idToken: null,
    });

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl.replace(/\/+$/, '');
    }

    public setTokens(token: () => (Promise<ApiClientTokens> | ApiClientTokens)): this {
        this.getTokens = () => Promise.resolve(token());
        return this;
    }

    public async getRelyingParties(): Promise<ConfigRelyingParty[]> {
        return (await this.request('GET', url`/setup/api/v1/rps`)).rps;
    }

    public async getConfig(rp: string): Promise<ConfigRelyingParty> {
        return this.request('GET', url`/setup/api/v1/rps/${rp}`);
    }

    public async updateConfig(rp: string, changes: Partial<ConfigRelyingPartyInput>): Promise<any> {
        return this.request('PUT', url`/setup/api/v1/rps/${rp}`, changes);
    }

    public async getBrandings(rp: string): Promise<Branding[]> {
        return (await this.request('GET', url`/setup/api/v1/rps/${rp}/brandings`)).brandings;
    }

    public async getFulfillmentConfig(rp: string): Promise<RpFulfillmentConfig> {
        return await this.request('GET', url`/setup/api/v1/rps/${rp}/fulfillment_configuration`);
    }

    public async updateFulfillmentConfig(rp: string, config: RpFulfillmentConfig): Promise<void> {
        return this.request('PUT', url`/setup/api/v1/rps/${rp}/fulfillment_configuration`, config);
    }

    public async getBranding(rp: string, id: string): Promise<Branding> {
        return this.request('GET', url`/setup/api/v1/rps/${rp}/brandings/${id}`);
    }

    public async updateBranding(rp: string, id: string, changes: Partial<BrandingInput>): Promise<Branding> {
        return this.request('PUT', url`/setup/api/v1/rps/${rp}/brandings/${id}`, changes);
    }

    public async updateDefaultBranding(rp: string, changes: Partial<BrandingInput>): Promise<Branding> {
        const brandings = await this.getBrandings(rp);
        const defaultBranding = brandings.find((x) => x.isDefault) || brandings[0];
        if (!defaultBranding) {
            throw statusErrorFactories[404]();
        }
        return this.updateBranding(rp, defaultBranding._id, changes);
    }

    public async getCadences(rp: string): Promise<any> {
        return (await this.request('GET', url`/setup/api/v1/rps/${rp}/cadences`)).cadences;
    }

    public async createCadence(rp: string, cadence: CadenceInput): Promise<Cadence> {
        return this.request('POST', url`/setup/api/v1/rps/${rp}/cadences`, cadence);
    }

    public async getCadence(rp: string, id: string): Promise<Cadence> {
        return this.request('GET', url`/setup/api/v1/rps/${rp}/cadences/${id}`);
    }

    public async updateCadence(rp: string, id: string, changes: Partial<CadenceInput>): Promise<Cadence> {
        return this.request('PUT', url`/setup/api/v1/rps/${rp}/cadences/${id}`, changes);
    }

    public async deleteCadence(rp: string, id: string): Promise<void> {
        await this.request('DELETE', url`/setup/api/v1/rps/${rp}/cadences/${id}`);
    }

    public async getNotificationPreferences(rp: string): Promise<NotificationPreference[]> {
        return (await this.request('GET', url`/setup/api/v1/rps/${rp}/notificationPreferences`))
            .notificationPreferences;
    }

    public async getNotificationPreference(rp: string, name: NotificationName): Promise<NotificationPreference> {
        return this.request('GET', url`/setup/api/v1/rps/${rp}/notificationPreferences/${name}`);
    }

    public async getMacroTypes(): Promise<MacroType[]> {
        const types = (await this.request('GET', url`/setup/api/v1/macro_types`))
            .macroTypes as RawMacroType[];
        return types.map((x) => ({
            id: x._id,
            name: x.type,
            createdAt: new Date(Date.parse(x.createdAt)),
            updatedAt: new Date(Date.parse(x.updatedAt)),
        }));
    }

    public async getMacros(rp: string): Promise<Macro[]> {
        const macros = (await this.request('GET', url`/setup/api/v1/rps/${rp}/macro_configurations`))
            .macroConfigurations as RawMacro[];
        return macros
            .map(formatMacro)
            .sort((a, b) => (b.createdAt.getTime() - a.createdAt.getTime()));
    }

    public async createMacro(rp: string, input: MacroInput): Promise<Macro> {
        const macro = await this.request('POST', url`/setup/api/v1/rps/${rp}/macro_configurations`, {
            macroTypeId: input.typeId,
            title: input.title,
            text: input.content,
        });
        return formatMacro(macro);
    }

    public async updateMacro(rp: string, id: string, input: MacroUpdate): Promise<void> {
        await this.request('PUT', url`/setup/api/v1/rps/${rp}/macro_configurations/${id}`, {
            title: input.title,
            text: input.content,
        });
    }

    public async deleteMacro(rp: string, id: string): Promise<void> {
        await this.request('DELETE', url`/setup/api/v1/rps/${rp}/macro_configurations/${id}`);
    }

    /**
     * Make a request to EID Config service.
     */
    private async request<T = any>(
        method: string,
        requestUrl: string,
        data?: any,
        allowedAdditionalHttpStatuses: number[] = [],
    ): Promise<T> {
        // Retrieve current auth token
        const { accessToken, idToken } = await this.getTokens();

        return new Promise((resolve, reject) => {
            // Initialize XHR object
            const xhr = new XMLHttpRequest();
            xhr.open(method, `${this.baseUrl}${requestUrl}`);
            xhr.setRequestHeader('content-type', 'application/json');
            if (accessToken) {
                xhr.setRequestHeader('authorization', `Bearer ${accessToken}`);
            }
            if (idToken) {
                xhr.setRequestHeader('idtoken', idToken);
            }
            xhr.setRequestHeader('cache-control', 'no-cache');

            // Handle valid response (read as JSON, but fallback to auth error or responseText)
            xhr.addEventListener('load', () => {
                const statusErrorFactory = statusErrorFactories[xhr.status];
                if (statusErrorFactory) {
                    return reject(statusErrorFactory(xhr));
                }
                const result = parseJsonConditionally(xhr.responseText);
                const success = (
                    (xhr.status >= 200 && xhr.status < 300) ||
                    allowedAdditionalHttpStatuses.includes(xhr.status)
                );
                const finish = success ? resolve : reject;
                return finish(result);
            });

            // Handle connection problem
            xhr.addEventListener('error', (error) => {
                console.error(`Config Api Client request error: ${requestUrl} [${xhr.status}]`, error);
                reject(error);
            });

            // Initialize request
            if (data === undefined) {
                xhr.send();
            } else {
                xhr.send(JSON.stringify(data));
            }
        });
    }
}

export default ConfigApiClient;
