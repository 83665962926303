import groupBy from 'lodash/groupBy';
import {
    InsuranceCoverageCriterion,
    InsuranceCoverageCriterionInput,
    InsuranceCoverageCriterionTemplate,
    InsuranceCriterionPatch,
} from '@evidentid/rpweb-api-client/types';
import differenceWith from 'lodash/differenceWith';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import { PatchOperationType } from '@evidentid/rpweb-api-client';
import JsonSchema, {
    JsonSchemaArray,
    JsonSchemaEnum,
} from '@evidentid/json-schema/interfaces/JsonSchema';
import { CriterionBases } from '@/modules/decisioning-criteria/models/CriterionBases.model';

export function groupCriteriaByType<T extends CriterionBases>(criteria: T[]): Record<string, T[]> {
    return groupBy(criteria, 'coverageType');
}

export function isCriterionTypeAndFieldEqual(
    a: InsuranceCoverageCriterion | InsuranceCoverageCriterionInput,
    b: InsuranceCoverageCriterion | InsuranceCoverageCriterionInput,
): boolean {
    return a.coverageType === b.coverageType && a.field === b.field;
}

export function isCriterionReferencesEqual(
    a: InsuranceCoverageCriterion | InsuranceCoverageCriterionInput,
    b: InsuranceCoverageCriterion | InsuranceCoverageCriterionInput,
): boolean {
    if (isCriterionTypeAndFieldEqual(a, b)) {
        return isEqual(a.evaluator.references, b.evaluator.references) && a.verify === b.verify;
    } else {
        return false;
    }
}

export function convertCriteriaChangesToPatches(
    originalCriteria: InsuranceCoverageCriterion[],
    newCriteria: CriterionBases[]): InsuranceCriterionPatch[] {
    const findOriginal = (x: CriterionBases) => originalCriteria.find(
        (og) => og.field === x.field && og.coverageType === x.coverageType,
    );
    const deleted = differenceWith(originalCriteria, newCriteria, isCriterionTypeAndFieldEqual);
    const created = differenceWith(newCriteria, originalCriteria, isCriterionTypeAndFieldEqual);
    const nonDeleteOrCreate =
        differenceWith(newCriteria, uniqWith([ ...created, ...deleted ], isEqual), isCriterionTypeAndFieldEqual);
    const updated = differenceWith(nonDeleteOrCreate, originalCriteria, isCriterionReferencesEqual)
        .map((criterion) => ({ ...criterion, id: findOriginal(criterion)?.id || '' })) as InsuranceCoverageCriterion[];
    return [
        ...deleted.map((criterion) => ({ op: PatchOperationType.delete, id: criterion.id })),
        ...created.map((criterion) => ({ op: PatchOperationType.create, value: criterion })),
        ...updated.map((criterion) => ({ op: PatchOperationType.update, value: criterion, id: criterion.id })),
    ] as InsuranceCriterionPatch[];
}

function addLabelToEnumSchema(schema: JsonSchemaEnum, enumLabels: string[]): JsonSchemaEnum {
    return { ...schema, enumLabels };
}

function addLabelToEnumArraySchema(schema: JsonSchemaArray, enumLabels: string[]): JsonSchemaArray {
    return { ...schema, items: { ...schema.items, enumLabels } as JsonSchemaEnum };
}

export function addEnumLabelToSchemaIfAvailable(schema: JsonSchema, enumLabelMap: Record<string, string>): JsonSchema |
    JsonSchemaEnum | JsonSchemaArray {
    const enumValues = (schema as JsonSchemaEnum).enum;
    const arrayEnumValues = ((schema as JsonSchemaArray).items as JsonSchemaEnum)?.enum;
    const enumLabels = Object.values(enumLabelMap);
    const hasLabels = enumLabels && enumLabels.length > 0;
    if (enumValues && hasLabels) {
        const labelsSortByValues = enumValues.map((val) => enumLabelMap[String(val)]);
        return addLabelToEnumSchema(schema as JsonSchemaEnum, labelsSortByValues);
    } else if (arrayEnumValues && hasLabels) {
        const labelsSortByValues = arrayEnumValues.map((val) => enumLabelMap[String(val)]);
        return addLabelToEnumArraySchema(schema as JsonSchemaArray, labelsSortByValues);
    } else {
        return schema;
    }
}

export function findCriterionTemplate(
    criterion: InsuranceCoverageCriterion | InsuranceCoverageCriterionInput,
    templates: InsuranceCoverageCriterionTemplate[],
): InsuranceCoverageCriterionTemplate | null {
    return templates.find(
        (template) => template.coverageType === criterion.coverageType && template.field === criterion.field,
    ) || null;
}
