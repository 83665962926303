import flatten from 'lodash/flatten';
import RpWebApiClient from '@evidentid/rpweb-api-client';

export async function fetchCoiDocuments(
    rpweb: RpWebApiClient,
    rpName: string, rprIds: string[],
): Promise<any[]> {
    const promises = rprIds.map((id) => rpweb.getRelyingPartyRequestDetails(rpName, id));
    const rprs = await Promise.all(promises);
    const regex = /.certificate_of_insurance_document/;
    // all cois are the same across coverage type at the moment, thus finding one is sufficient
    const documentsPerRpr = rprs.map(
        (rpr) => rpr.attributes.find((attr) => regex.test(attr.type))?.value?.content || null,
    ).filter(Boolean);
    return flatten(documentsPerRpr);
}
