import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import ActionsReview from '@/modules/insured-actions-review/views/ActionReview/ActionsReview.vue';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import vuex from '@/modules/insured-actions-review/vuex';
import Severity from '@evidentid/logger/Severity';

export const insuredActionsReviewModule = createModuleBuilder()
    .registerRoutes(() => [
        {
            path: '/:rpId?/actions-review',
            name: 'insuredActionsReview',
            component: ActionsReview as any,
            meta: { title: 'Actions Review' },
        },
    ])
    .demandContext<{
        rpweb: RpWebApiClient;
        tracker?: Tracker<any>;
        logger?: Logger<any>;
    }>()
    .registerVuex({ insuredActionsReview: vuex.instantiateModule })
    .execute((app) => {
        function onLoadingInsuredActionsRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Insured Actions',
                    description: `[Requested] Load insured actions for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Insured Actions',
                    message: `[Requested] Load insured actions for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingInsuredActionsFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Insured Actions',
                    description: `[Finished] Load insured actions for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Insured Actions',
                    message: `[Finished] Load insured actions for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingInsuredActionsFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Insured Actions Failed',
                    description: `[Failed] Load insured actions for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Insured Actions',
                    message: `[Failed] Load insured actions for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingEndorsementCategoriesRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Endorsement Categories',
                    description: `[Requested] Load endorsement categories for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Endorsement Categories',
                    message: `[Requested] Load endorsement categories for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingEndorsementCategoriesFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Endorsement Categories',
                    description: `[Finished] Load endorsement categories for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Decisioning Criteria - Endorsement Categories',
                    message: `[Finished] Load endorsement categories for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingEndorsementCategoriesFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Endorsement Categories Failed',
                    description: `[Failed] Load endorsement categories for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Decisioning Criteria - Endorsement Categories',
                    message: `[Failed] Load endorsement categories for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        app.observer.onMutation('startLoadingInsuredActions', (x) => onLoadingInsuredActionsRequest(x.rpName));
        app.observer.onMutation('finishLoadingInsuredActions', (x) => onLoadingInsuredActionsFinish(x.rpName));
        app.observer.onMutation('failLoadingInsuredActions', (x) => onLoadingInsuredActionsFailure(x.rpName));
        app.observer.onMutation('startLoadingEndorsementCategories', (x) => onLoadingEndorsementCategoriesRequest(x.rpName));
        app.observer.onMutation('finishLoadingEndorsementCategories', (x) => onLoadingEndorsementCategoriesFinish(x.rpName));
        app.observer.onMutation('failLoadingEndorsementCategories', (x) => onLoadingEndorsementCategoriesFailure(x.rpName));
    })
    .end();
