import startCase from 'lodash/startCase';
import {
    InsuranceInsuredActionDetails,
    InsuranceInsuredActionMatchType,
    InsuranceInsuredActionType,
    InsuranceInsuredEntityMatchingActionDetails,
    InsuranceInsuredEntityNameMatchingAction,
} from '@evidentid/rpweb-api-client/types';

const endorsementTypes = [ InsuranceInsuredActionType.endorsementFormNumberV1 ];
const entityNameMatchingTypes = [ InsuranceInsuredActionType.entityNameMatchingV1 ];
const collateralMappingTypes = [ InsuranceInsuredActionType.evaluatedEntityMappingV1 ];
const endorsementPrefix = 'Endorsement Review';
const entityNameMatchingPrefix = 'Entity Name Matching';
const collateralMappingPrefix = 'Collateral mapping';

function getActionRequiredPrefix(actionType: InsuranceInsuredActionType): string | null {
    if (entityNameMatchingTypes.includes(actionType)) {
        return entityNameMatchingPrefix;
    } else if (endorsementTypes.includes(actionType)) {
        return endorsementPrefix;
    } else if (collateralMappingTypes.includes(actionType)) {
        // [entityMappingExpansion]: collateral is only type atm, revisit once the feature expands and BE supports it
        return collateralMappingPrefix;
    } else {
        return null;
    }
}

function extractFieldName(insuredAction: InsuranceInsuredEntityMatchingActionDetails): string {
    const tokens = (insuredAction.action as InsuranceInsuredEntityNameMatchingAction).field.split('.');
    return tokens[tokens.length - 1];
}

export function getEntityMatchFieldName(insuredAction: InsuranceInsuredEntityMatchingActionDetails): string {
    switch (insuredAction.action.matchType) {
        case InsuranceInsuredActionMatchType.insured:
            return 'Insured Name';
        case InsuranceInsuredActionMatchType.relyingParty:
            return 'Relying Party';
        case InsuranceInsuredActionMatchType.custom:
            return extractFieldName(insuredAction);
    }
}

export function getActionRequired(insuredAction: InsuranceInsuredActionDetails): string {
    const prefix = getActionRequiredPrefix(insuredAction.action.$action);
    if (!prefix) {
        return '';
    }
    let actionRequiredText = prefix;
    if (prefix === entityNameMatchingPrefix) {
        const fieldNameLabel =
            startCase(getEntityMatchFieldName(insuredAction as InsuranceInsuredEntityMatchingActionDetails));
        const hyphen = fieldNameLabel ? ' - ' : '';
        actionRequiredText = `${prefix}${hyphen}${fieldNameLabel}`;
    }
    return actionRequiredText;
}
