import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import DashboardConfiguration from '@/modules/dashboard-configuration/views/DashboardConfiguration.vue';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import vuex from './vuex';
import Severity from '@evidentid/logger/Severity';

export const dashboardConfigurationModule = createModuleBuilder()
    .registerRoutes(() => [
        {
            path: '/:rpId?/configuration/insuredFields',
            name: 'insuredFieldsConfiguration',
            component: DashboardConfiguration as any,
            meta: { title: 'Configuration' },
        },
    ]).demandContext<{
        rpweb: RpWebApiClient;
        tracker?: Tracker<any>;
        logger?: Logger<any>;
    }>()
    .registerVuex({ dashboardConfiguration: vuex.instantiateModule })
    .execute((app) => {
        function onLoadingDashboardConfigurationRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Dashboard Configuration',
                    description: `[Requested] Load dashboard configuration for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Insureds',
                    message: `[Requested] Load dashboard configuration for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingDashboardConfigurationFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Dashboard Configuration',
                    description: `[Finished] Load dashboard configuration for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Insureds',
                    message: `[Finished] Load dashboard configuration for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onLoadingDashboardConfigurationFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Load Dashboard Configuration Failed',
                    description: `[Failed] Load dashboard configuration for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Dashboard - Insured Fields',
                    message: `[Failed] Load dashboard configuration for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onUpdatingDashboardConfigurationRequest(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Update Dashboard Configuration',
                    description: `[Requested] Update dashboard configuration for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Insureds',
                    message: `[Requested] Update dashboard configuration for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onUpdatingDashboardConfigurationFinish(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Update Dashboard Configuration',
                    description: `[Finished] Update dashboard configuration for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'Dashboard - Insureds',
                    message: `[Finished] Update dashboard configuration for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        function onUpdatingDashboardConfigurationFailure(rpName: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Update Dashboard Configuration Failed',
                    description: `[Failed] Update dashboard configuration for ${rpName}`,
                    rpName,
                });
            }
            if (app.logger) {
                app.logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Dashboard - Insured Fields',
                    message: `[Failed] Update dashboard configuration for ${rpName}`,
                    data: { rpName },
                });
            }
        }

        app.observer.onMutation('startLoadingDashboardConfiguration', (x) => onLoadingDashboardConfigurationRequest(x.rpName));
        app.observer.onMutation('finishLoadingDashboardConfiguration', (x) => onLoadingDashboardConfigurationFinish(x.rpName));
        app.observer.onMutation('failLoadingDashboardConfiguration', (x) => onLoadingDashboardConfigurationFailure(x.rpName));
        app.observer.onMutation('startUpdatingDashboardConfiguration', (x) => onUpdatingDashboardConfigurationRequest(x.rpName));
        app.observer.onMutation('finishUpdatingDashboardConfiguration', (x) => onUpdatingDashboardConfigurationFinish(x.rpName));
        app.observer.onMutation('failUpdatingDashboardConfiguration', (x) => onUpdatingDashboardConfigurationFailure(x.rpName));
    })
    .end();
