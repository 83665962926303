import FirebaseAuth, {
    FirebaseAppConfig,
    FirebaseAppSession,
    FirebaseSignInExternalMethod,
} from '@evidentid/firebase-auth';
import { IamClientAdapter, IamClientUser, IamPermission } from '../types';
import * as errors from '../errors';

function formatUserData(session: FirebaseAppSession | null): IamClientUser | null {
    if (!session) {
        return null;
    }
    return {
        email: session.email,
        displayName: session.displayName,
        photoURL: session.avatarUrl,
        providerId: session.provider,
        verified: session.emailVerified,
    };
}

const ignoredErrors = [
    'auth/cancelled-popup-request',
    'auth/popup-closed-by-user',
];

const errorFactories: Record<string, ErrorConstructor> = {
    'auth/user-not-found': errors.UserNotFoundError,
    'auth/invalid-email': errors.InvalidEmailError,
    'auth/wrong-password': errors.InvalidCredentialsError,
    'auth/weak-password': errors.WeakPasswordError,
    'auth/email-already-in-use': errors.EmailAlreadyUsedError,
    'auth/user-disabled': errors.UserDisabledError,
    'auth/account-exists-with-different-credential': errors.DifferentProviderError,
    'auth/too-many-requests': errors.RateLimitError,
}

function translateError(error: any): Error {
    if (error?.$auth) {
        return error;
    }
    const ErrorClass = errorFactories[error?.code] || errors.UnknownAuthError;
    return new ErrorClass(error?.message || error?.code);
}

// eslint-disable-next-line
function handleErrors<T extends (...args: any[]) => Promise<any>, U extends any>(fn: T, result: U): (
    (...args: Parameters<T>) => ReturnType<T> | Promise<U>
) {
    return (...args: Parameters<T>): ReturnType<T> | Promise<U> => fn(...args).catch((error) => {
        if (ignoredErrors.includes(error?.code)) {
            return result;
        } else {
            throw translateError(error);
        }
    });
}

export class IamClientFirebaseAdapter implements IamClientAdapter {
    private options: FirebaseAppConfig;
    private auth: FirebaseAuth | null = null;
    private subscriptions: ((user: IamClientUser | null) => void)[] = [];

    public readonly loginMethods = {
        google: handleErrors(async () => {
            await this.auth!.signInWithExternalProvider(FirebaseSignInExternalMethod.google);
        }, undefined),
        facebook: handleErrors(async () => {
            await this.auth!.signInWithExternalProvider(FirebaseSignInExternalMethod.facebook);
        }, undefined),
        credentials: handleErrors(async (username: string, password: string) => {
            await this.auth!.signIn(username, password);
        }, undefined),
    };

    public readonly registerMethods = {
        google: handleErrors(this.loginMethods.google, undefined),
        facebook: handleErrors(this.loginMethods.facebook, undefined),
        credentials: handleErrors(async (username: string, password: string) => {
            await this.auth!.register(username, password);
        }, undefined),
    };

    public constructor(options: FirebaseAppConfig) {
        this.options = { ...options };
    }

    public requestPermissions(...permissions: IamPermission[]): Promise<boolean> {
        // We can't request permissions in Firebase (these are always in scope)
        return this.hasPermissions(...permissions);
    }

    public async hasPermissions(...permissions: IamPermission[]): Promise<boolean> {
        const session = await this.auth?.getSession();
        if (!session?.emailVerified) {
            return false;
        }
        return permissions.every((x) => (
            // FIXME: it allows any action for `rpweb`, as we don't have permissions system
            x.service === 'rpweb' ||
            (!x.permission || session.claims.includes(x.permission))
        ));
    }

    public subscribe(listener: (user: IamClientUser | null) => void): () => void {
        // Wrap listener, and ensure that cached value will not reach,
        // after the new value is there already (race condition).
        let called = false;
        const wrappedListener = (user: IamClientUser | null) => {
            called = true;
            listener(user);
        }
        this.subscriptions.push(wrappedListener);

        // Get current user information, and return it if it's not unsubscribed until this time
        this.getUser().then((user) => {
            if (!called && this.subscriptions.includes(wrappedListener)) {
                wrappedListener(user);
            }
        });

        // Create a function for unsubscribing
        const unsubscribe = this.auth!.addListener((session) => wrappedListener(formatUserData(session)));
        return () => {
            unsubscribe();
            const index = this.subscriptions.indexOf(wrappedListener);
            if (index !== -1) {
                this.subscriptions.splice(index, 1);
            }
        };
    }

    public async initialize(): Promise<void> {
        this.auth = new FirebaseAuth(this.options);
        try {
            await this.auth.untilReady(5000);
        } catch (error) {
            throw new errors.TimeoutError();
        }
    }

    public async getUser(): Promise<IamClientUser | null> {
        return formatUserData(await this.auth!.getSession());
    }

    public async getAccessToken(): Promise<string | null> {
        return this.auth!.getToken();
    }

    /**
     * With Firebase we use only single token for authorization,
     * without sending `IdToken` header to back-end.
     * Due to that, the Firebase adapter is returning only the token,
     * which will be passed into `Authorization` header.
     */
    public async getIdToken(): Promise<null> {
        return null;
    }

    public async refreshToken(): Promise<void> {
        return this.auth!.refreshToken();
    }

    public async logOut(): Promise<void> {
        await handleErrors(this.auth!.signOut.bind(this.auth), undefined)();
    }

    public async sendEmailVerification(continueUrl: string): Promise<void> {
        await handleErrors(this.auth!.sendEmailVerification.bind(this.auth), undefined)(continueUrl);
    }

    public async resetPassword(username: string, continueUrl: string): Promise<void> {
        await handleErrors(this.auth!.resetPassword.bind(this.auth), undefined)(username, continueUrl);
    }

    public async destroy(): Promise<void> {
        await this.auth?.destroy();
        this.subscriptions = [];
        this.auth = null;
    }
}
