import {
    InsuranceComplianceStatus,
    InsuranceCoverageType,
    InsuranceEvaluationResult,
    InsuranceInsuredCoverageCriteriaGroup,
} from '@evidentid/rpweb-api-client/types';
import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';

function getCoverageCriteriaGroupName(
    ccgId: string,
    insuredCoverageCriteriaGroups: InsuranceInsuredCoverageCriteriaGroup[]
): string {
    return insuredCoverageCriteriaGroups.find((ccg) => ccgId === ccg.id)?.displayName || '';
}

function getFieldEvaluationResultError(
    evaluationResult: InsuranceEvaluationResult,
    fieldEvaluationError: FieldEvaluationResultError,
    insuredCoverageCriteriaGroups: InsuranceInsuredCoverageCriteriaGroup[],
    { errorMessage, isMultiFieldCriterion }: { errorMessage: string, isMultiFieldCriterion: boolean }
): FieldEvaluationResultError {
    const evaluationError = fieldEvaluationError || {
        messages: [],
        criterionId: [],
        complianceStatusMetadata: [],
    };
    evaluationError.evaluatedEntityId = evaluationResult.evaluatedEntityId;
    evaluationError.useDuringComplianceCalculation = evaluationResult.useDuringComplianceCalculation;
    evaluationError.messages.push({
        message: errorMessage,
        coverageCriteriaGroupName: getCoverageCriteriaGroupName(
            evaluationResult.coverageCriteriaGroupId, insuredCoverageCriteriaGroups),
    });
    evaluationError.coverageCriteriaGroupId = evaluationResult.coverageCriteriaGroupId;
    evaluationError.criterionId.push(evaluationResult.criterionId);
    evaluationError.isMultiFieldCriterion = isMultiFieldCriterion;

    if (evaluationResult.complianceStatusMetadata) {
        evaluationError.complianceStatusMetadata.push(evaluationResult.complianceStatusMetadata);
    }

    return evaluationError;
}

function mapErrorFromFailures(
    evaluationResult: InsuranceEvaluationResult,
    evaluationErrors: Record<string, FieldEvaluationResultError>,
    insuredCoverageCriteriaGroups: InsuranceInsuredCoverageCriteriaGroup[],
): void {
    // deprecated, only for backward compatibility
    evaluationResult.failures.forEach((failure) => {
        const match = /#.*:/.exec(failure);
        if (match && match.length > 0) {
            const key = `${match[0].slice(0, -1)}`;
            const errorMessage = failure.split(':')[1].trim();
            if (key && errorMessage) {
                evaluationErrors[key] = getFieldEvaluationResultError(
                    evaluationResult,
                    evaluationErrors[key],
                    insuredCoverageCriteriaGroups,
                    {
                        errorMessage,
                        isMultiFieldCriterion: false,
                    }
                );
            }
        }
    });
}

export function mapEvaluationErrors(
    evaluationResults: InsuranceEvaluationResult[],
    insuredCoverageCriteriaGroups: InsuranceInsuredCoverageCriteriaGroup[],
): Record<string, FieldEvaluationResultError> {
    return evaluationResults
        .filter((x) => (x.result === InsuranceComplianceStatus.nonCompliant
            || Boolean(x.complianceStatusMetadata)) && x.useDuringComplianceCalculation)
        .reduce((evaluationErrors, evaluationResult) => {
            // only for backward compatibility
            if ((!evaluationResult.usedFields || evaluationResult.usedFields.length === 0) &&
                evaluationResult.failures.length > 0
            ) {
                mapErrorFromFailures(evaluationResult, evaluationErrors, insuredCoverageCriteriaGroups);
            } else {
                evaluationResult.usedFields.forEach((field) => {
                    if (field) {
                        evaluationErrors[field] = getFieldEvaluationResultError(
                            evaluationResult,
                            evaluationErrors[field],
                            insuredCoverageCriteriaGroups,
                            {
                                errorMessage: evaluationResult.nonComplianceMessage || '',
                                isMultiFieldCriterion: evaluationResult.usedFields.length > 1,
                            }
                        );
                    }
                });
            }

            return evaluationErrors;
        }, {} as Record<string, FieldEvaluationResultError>);
}
