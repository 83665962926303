import startCase from 'lodash/startCase';
import { InsuranceCoverageModel, InsuranceCoverageType } from '@evidentid/rpweb-api-client/types';

const attributeAndCoverageTypeMap: Record<string, InsuranceCoverageType> = {
    'commercial_auto_liability': InsuranceCoverageType.businessAutoLiability,
    'commercial_crime': InsuranceCoverageType.commercialCrimeLiability,
    'commercial_general_liability': InsuranceCoverageType.commercialGeneralLiability,
    'commercial_property': InsuranceCoverageType.commercialProperty,
    'inland_marine_commercial_auto': InsuranceCoverageType.inlandMarineCommercialAuto,
    'occupational_accident': InsuranceCoverageType.occupationalAccident,
    'umbrella_excess_liability': InsuranceCoverageType.umbrellaExcessLiability,
    'workers_compensation': InsuranceCoverageType.workersCompensation,
    '3rd_party_fidelity_bond': InsuranceCoverageType.thirdPartyFidelityBond,
    'automobile_liability': InsuranceCoverageType.businessAutoLiability,
    'comprehensive_crime': InsuranceCoverageType.commercialCrimeLiability,
    'cyber_liability': InsuranceCoverageType.cyberLiability,
    'employee_dishonesty': InsuranceCoverageType.employeeDishonesty,
    'employment_practices_liability': InsuranceCoverageType.employmentPracticesLiability,
    'professional_liability': InsuranceCoverageType.professionalLiability,
    'property_insurance': InsuranceCoverageType.commercialProperty,
    'sexual_abuse_and_molestation': InsuranceCoverageType.sexualAbuseAndMolestationLiability,
    'umbrella_liability': InsuranceCoverageType.umbrellaExcessLiability,
    'private_passenger_auto': InsuranceCoverageType.privatePassengerAuto,
    'garage_keepers_liability': InsuranceCoverageType.garageKeepersLiability,
    'garage_liability': InsuranceCoverageType.garageLiability,
    'contingent_auto_liability': InsuranceCoverageType.contingentAutoLiability,
    'cargo_liability': InsuranceCoverageType.cargoLiability,
    'non_trucking_liability': InsuranceCoverageType.nonTruckingLiability,
    'builders_risk': InsuranceCoverageType.buildersRisk,
    'crop_insurance': InsuranceCoverageType.cropInsurance,
    'dwelling': InsuranceCoverageType.dwelling,
    'farmowners': InsuranceCoverageType.farmowners,
    'fleet': InsuranceCoverageType.fleet,
    'homeowners': InsuranceCoverageType.homeowners,
    'life_insurance': InsuranceCoverageType.lifeInsurance,
    'loss_of_income': InsuranceCoverageType.lossOfIncome,
    'flood_insurance': InsuranceCoverageType.floodInsurance,
};

export function getCoverageTypeVersionByAttribute(attr: string): string | null {
    const version = attr.split('.')[4];
    return /v\d+/.test(version) ? version : null;
}

export function getCoverageTypeByAttribute(attr: string): InsuranceCoverageType | null {
    const typePortion = attr.split('.')[3];
    return attributeAndCoverageTypeMap[typePortion] || null;
}

export function getCoverageTypeLabelByAttribute(
    attr: string,
    withVersion?: boolean,
    coverageTypeModels?: InsuranceCoverageModel[],
): InsuranceCoverageType | string | null {
    const coverageType = getCoverageTypeByAttribute(attr);
    if (!coverageType) {
        return null;
    }
    const typeModel = coverageTypeModels && coverageTypeModels.length > 0
        ? coverageTypeModels.find((model) => model.coverageType === coverageType)
        : null;
    const typeLabel = typeModel?.label || startCase(coverageType.toLowerCase());
    const version = getCoverageTypeVersionByAttribute(attr);
    return withVersion && version
        ? `${typeLabel} ${version}`
        : typeLabel;
}
