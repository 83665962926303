/* eslint-disable camelcase */
import isNil from 'lodash/isNil';
import { CollateralDetails } from '@/modules/insured-details/models/CollateralDetails.model';
import { CollateralDetailsLimit } from '@/modules/insured-details/models/CollateralDetailsLimit.model';
import { CollateralValuation } from '@/modules/insured-details/models/CollateralValuation.model';

interface CollateralDetailsLimitMvObject {
    $objectType: 'CollateralLimitType';
    coverageLimit: string;
    deductible: string;
    limitType: string;
    valuation: string;
}

interface CollateralDetailsMvObject {
    $objectType: 'Collateral';
    description: string;
    uniqueIdentifier: string;
    limitTypes: CollateralDetailsLimitMvObject[];
}

const transformCollateralDetailslLimits = (limits?: CollateralDetailsLimitMvObject[]): CollateralDetailsLimit[] => {
    if (!limits) {
        return [];
    }

    return limits.map(({
        coverageLimit,
        limitType,
        deductible,
        valuation,
    }) => ({
        valuation: valuation as CollateralValuation,
        limitType,
        coverageLimit: !isNil(coverageLimit) && coverageLimit !== '' ? Number(coverageLimit) : null,
        deductible: !isNil(deductible) && deductible !== '' ? Number(deductible) : null,
    }));
};

export const transformCollateralsDetails = (
    collaterals?: CollateralDetailsMvObject[]
): CollateralDetails[] => {
    if (!collaterals) {
        return [];
    }

    return collaterals.map(({
        uniqueIdentifier,
        description,
        limitTypes,
    }) => ({
        description,
        uniqueIdentifier,
        limitTypes: transformCollateralDetailslLimits(limitTypes),
    }));
};
