import { InsuranceCoverageType } from '@evidentid/rpweb-api-client/types';
import businessAutoIcon from '@/modules/insured-details/assets/coverage-icon-business-auto-liability.svg';
import commercialCrimeIcon from '@/modules/insured-details/assets/coverage-icon-commercial-crime.svg';
import commercialPropertyIcon from '@/modules/insured-details/assets/coverage-icon-commercial-property.svg';
import cyberLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-cyber-liability.svg';
import defaultCoverageIcon from '@/modules/insured-details/assets/coverage-icon-default.svg';
import employeeDishonestyIcon from '@/modules/insured-details/assets/coverage-icon-employee-dishonesty.svg';
import employmentPracticesLiabilityIcon
    from '@/modules/insured-details/assets/coverage-icon-employment-practices-liability.svg';
import garageKeepersLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-garage-keepers-liability.svg';
import garageLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-garage-liability.svg';
import generalLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-commercial-general-liability.svg';
import inlandMarineCommercialAutoIcon
    from '@/modules/insured-details/assets/coverage-icon-inland-marline-commercial-auto.svg';
import occupationalIcon from '@/modules/insured-details/assets/coverage-icon-occupational.svg';
import privatePassengerAutoIcon from '@/modules/insured-details/assets/coverage-icon-private-passenger-auto.svg';
import professionalLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-professional-liability.svg';
import sexualAbuseAndMolestationLiabilityIcon
    from '@/modules/insured-details/assets/coverage-icon-sextual-abuse-and-molestation.svg';
import thirdPartyFidelityBondIcon from '@/modules/insured-details/assets/coverage-icon-3rd-party-fidelity-bond.svg';
import umbrellaExcessIcon from '@/modules/insured-details/assets/coverage-icon-umbrella-excess.svg';
import workersCompensationIcon from '@/modules/insured-details/assets/coverage-icon-workers-compensation.svg';
import cargoLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-cargo-liability.svg';
import contingentAutoLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-contingent-auto-liability.svg';
import nonTruckingLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-non-trucking-liability.svg';
import coverageIconBuildersRisk from '@/modules/insured-details/assets/coverage-icon-builders-risk.svg';
import coverageIconCrop from '@/modules/insured-details/assets/coverage-icon-crop.svg';
import coverageIconDwelling from '@/modules/insured-details/assets/coverage-icon-dwelling.svg';
import coverageIconFarmOwners from '@/modules/insured-details/assets/coverage-icon-farm-owners.svg';
import coverageIconFleet from '@/modules/insured-details/assets/coverage-icon-fleet.svg';
import coverageIconHomeOwners from '@/modules/insured-details/assets/coverage-icon-home-owners.svg';
import coverageIconLifeInsurance from '@/modules/insured-details/assets/coverage-icon-life-insurance.svg';
import coverageIconLossOfIncome from '@/modules/insured-details/assets/coverage-icon-loss-of-income.svg';
import coverageIconFloodInsurance from '@/modules/insured-details/assets/coverage-icon-flood-insurance.svg';

const coverageTypeIcons: Record<InsuranceCoverageType, string> = {
    [InsuranceCoverageType.brokerInfo]: defaultCoverageIcon,
    [InsuranceCoverageType.businessAutoLiability]: businessAutoIcon,
    [InsuranceCoverageType.commercialCrimeLiability]: commercialCrimeIcon,
    [InsuranceCoverageType.commercialGeneralLiability]: generalLiabilityIcon,
    [InsuranceCoverageType.commercialProperty]: commercialPropertyIcon,
    [InsuranceCoverageType.cyberLiability]: cyberLiabilityIcon,
    [InsuranceCoverageType.employeeDishonesty]: employeeDishonestyIcon,
    [InsuranceCoverageType.employmentPracticesLiability]: employmentPracticesLiabilityIcon,
    [InsuranceCoverageType.garageKeepersLiability]: garageKeepersLiabilityIcon,
    [InsuranceCoverageType.garageLiability]: garageLiabilityIcon,
    [InsuranceCoverageType.inlandMarineCommercialAuto]: inlandMarineCommercialAutoIcon,
    [InsuranceCoverageType.occupationalAccident]: occupationalIcon,
    [InsuranceCoverageType.privatePassengerAuto]: privatePassengerAutoIcon,
    [InsuranceCoverageType.professionalLiability]: professionalLiabilityIcon,
    [InsuranceCoverageType.sexualAbuseAndMolestationLiability]: sexualAbuseAndMolestationLiabilityIcon,
    [InsuranceCoverageType.thirdPartyFidelityBond]: thirdPartyFidelityBondIcon,
    [InsuranceCoverageType.umbrellaExcessLiability]: umbrellaExcessIcon,
    [InsuranceCoverageType.workersCompensation]: workersCompensationIcon,
    [InsuranceCoverageType.cargoLiability]: cargoLiabilityIcon,
    [InsuranceCoverageType.contingentAutoLiability]: contingentAutoLiabilityIcon,
    [InsuranceCoverageType.nonTruckingLiability]: nonTruckingLiabilityIcon,
    [InsuranceCoverageType.buildersRisk]: coverageIconBuildersRisk,
    [InsuranceCoverageType.cropInsurance]: coverageIconCrop,
    [InsuranceCoverageType.dwelling]: coverageIconDwelling,
    [InsuranceCoverageType.farmowners]: coverageIconFarmOwners,
    [InsuranceCoverageType.fleet]: coverageIconFleet,
    [InsuranceCoverageType.homeowners]: coverageIconHomeOwners,
    [InsuranceCoverageType.lifeInsurance]: coverageIconLifeInsurance,
    [InsuranceCoverageType.lossOfIncome]: coverageIconLossOfIncome,
    [InsuranceCoverageType.floodInsurance]: coverageIconFloodInsurance,
};

export function getCoverageTypeIcon(coverageType: InsuranceCoverageType): string {
    return coverageTypeIcons[coverageType] || defaultCoverageIcon;
}
