import omit from 'lodash/omit';
import JsonSchema, {
    JsonSchemaComplexObject, JsonSchemaType, RegularJsonSchema,
} from '@evidentid/json-schema/interfaces/JsonSchema';
import {
    InsuranceCriterionSchema,
    InsuranceInsuredField,
    InsuranceSchemaDisplayFormat,
} from '@evidentid/rpweb-api-client/types';
import { convertInsuredFieldSchemaToEnumSchema } from '@/modules/decisioning-criteria/utils/criterionSchemaConversion';
// referenced from https://json-schema.org/understanding-json-schema/reference/string.html#built-in-formats
const standardStringFormat = [
    'date-time', 'time', 'date', 'duration', 'email', 'idn-hostname', 'ipv4', 'ipv6', 'uuid', 'uri', 'uri-reference',
    'iri', 'iri-reference', 'uri-template', 'json-pointer', 'relative-json-pointer', 'regex',
];
const customStringFormats = [
    'color', 'html', 'phone', 'image-url',
];

const acceptedStringFormats = [ ...standardStringFormat, ...customStringFormats ];

function removeNonStandardSchemaFormat(
    schema: JsonSchema,
): JsonSchema {
    const moddedSchema = 'if' in schema
        ? {
            ...schema,
            if: removeNonStandardSchemaFormat(schema.if as JsonSchema),
            then: removeNonStandardSchemaFormat(schema.then as JsonSchema),
            else: removeNonStandardSchemaFormat(schema.else as JsonSchema),
        } as JsonSchemaComplexObject
        : schema;
    if ('properties' in moddedSchema) {
        return {
            ...moddedSchema,
            properties: Object.keys(moddedSchema.properties).reduce((acc, key) => ({
                ...acc,
                [key]: removeNonStandardSchemaFormat(moddedSchema.properties[key]),
            }), {}),
        };
    } else if ('items' in moddedSchema) {
        return {
            ...moddedSchema,
            items: removeNonStandardSchemaFormat(moddedSchema.items as JsonSchema),
        };
    }
    return 'format' in moddedSchema && moddedSchema.format && !acceptedStringFormats.includes(moddedSchema.format)
        ? omit(moddedSchema, 'format')
        : moddedSchema;
}

function standardizeInsuredFieldSchema(
    schema: JsonSchema|JsonSchemaComplexObject,
    insuredFields?: InsuranceInsuredField[],
    insuredValueTypeFilterBy?: JsonSchemaType | null,
): JsonSchema {
    let moddedSchema = schema;

    if ('if' in schema) {
        const thenSchema = Object.values(schema.then?.properties || {})[0];
        const elseSchema = Object.values(schema.else?.properties || {})[0];
        const thenDisplayFormat = (thenSchema as InsuranceCriterionSchema)?.displayFormat;
        const elseDisplayFormat = (elseSchema as InsuranceCriterionSchema)?.displayFormat;
        const thenInsuredValueTypeFilterBy = thenDisplayFormat === InsuranceSchemaDisplayFormat.insuredField ?
            (elseSchema as RegularJsonSchema).type
            : null;
        const elseInsuredValueTypeFilterBy = elseDisplayFormat === InsuranceSchemaDisplayFormat.insuredField ?
            (thenSchema as RegularJsonSchema).type
            : null;

        moddedSchema = {
            ...schema,
            if: standardizeInsuredFieldSchema(schema.if as JsonSchema, insuredFields),
            then: standardizeInsuredFieldSchema(
                schema.then as JsonSchema,
                insuredFields,
                thenInsuredValueTypeFilterBy
            ),
            else: standardizeInsuredFieldSchema(
                schema.else as JsonSchema,
                insuredFields,
                elseInsuredValueTypeFilterBy
            ),
        } as JsonSchemaComplexObject;
    }

    if ('properties' in moddedSchema) {
        return {
            ...moddedSchema,
            properties: Object.keys(moddedSchema.properties).reduce((acc, key) => ({
                ...acc,
                [key]: standardizeInsuredFieldSchema(
                    (moddedSchema as JsonSchemaComplexObject).properties[key],
                    insuredFields,
                    insuredValueTypeFilterBy
                ),
            }), {}),
        };
    } else if ('items' in moddedSchema) {
        return {
            ...moddedSchema,
            items: standardizeInsuredFieldSchema(
                moddedSchema.items as JsonSchema,
                insuredFields
            ),
        };
    }

    const isInsuredFieldSchema =
        (moddedSchema as InsuranceCriterionSchema).displayFormat === InsuranceSchemaDisplayFormat.insuredField &&
        insuredFields && insuredFields.length > 0;

    if (isInsuredFieldSchema) {
        return convertInsuredFieldSchemaToEnumSchema(moddedSchema, insuredFields, insuredValueTypeFilterBy);
    }

    return moddedSchema;
}

export function standardizeCriterionSchema(
    schema: JsonSchema,
    insuredFields?: InsuranceInsuredField[],
    insuredValueTypeFilterBy?: JsonSchemaType | null,
): JsonSchema {
    return removeNonStandardSchemaFormat(
        standardizeInsuredFieldSchema(schema, insuredFields, insuredValueTypeFilterBy),
    );
}
