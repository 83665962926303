module.exports = {
    content: [
        './index.html',
        './src/**/*.{vue,js,ts}',
    ],
    theme: {
        extend: {
            colors: {
                eidGreen: {
                    DEFAULT: '#38B349',
                },
                eidTrustGreen: {
                    DEFAULT: '#2BB597',
                    75: '#60C7B1',
                    50: '#95DACB',
                    25: '#CAECE5',
                    10: '#EAF8F5',
                },
                eidSecureBlue: {
                    DEFAULT: '#2D3F50',
                    75: '#616F7C',
                    50: '#969FA7',
                    25: '#CACFD3',
                    10: '#EAECEE',
                },
                eidQualityBlack: {
                    DEFAULT: '#23313A',
                    75: '#5A646B',
                    50: '#91989C',
                    25: '#C8CBCE',
                    10: '#E9EAEB',
                },
                eidTechGray: {
                    DEFAULT: '#788FA2',
                    75: '#9AABB9',
                    50: '#BBC7D0',
                    25: '#DDE3E8',
                    10: '#F2F4F6',
                },
                eidSafetyBlue: {
                    DEFAULT: '#2D93AA',
                    75: '#61AEBF',
                    50: '#96C9D4',
                    25: '#CAE4EA',
                    10: '#EAF4F7',
                },
                eidImpactOrange: {
                    DEFAULT: '#EF9A29',
                    75: '#F3B35E',
                    50: '#F7CC94',
                    25: '#FBE6C9',
                    10: '#FDF5EA',
                },
                eidSuccessGreen: {
                    DEFAULT: '#9BB40D',
                    75: '#B4C749',
                    50: '#CDD986',
                    25: '#E6ECC2',
                    10: '#F5F7E7',
                },
                eidWarningYellow: {
                    DEFAULT: '#FEB649',
                    75: '#F7D270',
                    50: '#FAE1A0',
                    25: '#FCF0CF',
                    10: '#FEF9EC',
                },
                eidDangerRed: {
                    DEFAULT: '#F15B47',
                    75: '#F48475',
                    50: '#F8ADA3',
                    25: '#FBD6D1',
                    10: '#FEEFED',
                },
                eidDarkBlue: {
                    DEFAULT: '#223039',
                    50: '#90979C',
                    75: '#59646A',
                },
            },
            fontSize: {
                '2.5xl': [ '26px' ],
                'xxs': [ '10px' ],
            },
        },
    },
    plugins: [],
    corePlugins: {
        preflight: false,
    },
};
