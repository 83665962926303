import { PartialDeep } from 'type-fest';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { createStateFactory } from '@evidentid/vue-commons/store';
import type ConfigApiClient from '@evidentid/config-api-client';
import {
    BrandingInput,
    ConfigRelyingPartyInput,
    NotificationName,
    SupportTimeframe,
} from '@evidentid/config-api-client/types';
import type RpWebApiClient from '@evidentid/rpweb-api-client';
import {
    InsuranceConfigInput,
    InsuranceRequestsConfig,
    VerificationRequestType,
} from '@evidentid/rpweb-api-client/types';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import { getPersistingErrorActions } from '@evidentid/dashboard-commons/modules/persisting-error';
import { buildNotificationCadence } from './utils/buildNotificationCadence';
import {
    EmailTemplate,
    NotificationCadence,
    NotificationsConfiguration,
    NotificationsConfigurationState,
} from './types';
import { buildCadenceInput } from '@/modules/notifications-configuration/utils/buildCadenceInput';
import { DayOfWeek } from '@evidentid/dashboard-commons/components/DayOfWeekInput/types';
import range from 'lodash/range';
import uniq from 'lodash/uniq';
import omit from 'lodash/omit';
import {
    evidentFulfillmentConfiguration,
    marshFulfillmentConfiguration,
} from '@/modules/notifications-configuration/constants';
import { extractFulfillmentConfig } from '@/modules/notifications-configuration/utils/extractFulfillmentConfig';

interface NotificationsConfigurationRequirements {
    rpweb: RpWebApiClient;
    configClient: ConfigApiClient;
}

const createState = createStateFactory<NotificationsConfigurationRequirements>();

const { instantiateState, createMutationsFactories } = createState<NotificationsConfigurationState>(() => ({
    settings: {},
    loadSettings: {},
    updateSettings: {},
    emailTemplates: {},
    loadEmailTemplates: {},
    updateEmailTemplates: {},
    loadInsuredFields: {},
    insuredFields: {},
    cadences: {},
    loadCadences: {},
    updateCadences: {},
    sendTestEmailStatus: {},
}));

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({
    setSettings(payload: { rpName: string, settings: NotificationsConfiguration }) {
        this.settings = {
            ...this.settings,
            [payload.rpName]: payload.settings,
        };
    },
    setLoadSettingsStatus(payload: { rpName: string, status: OperationStatus }) {
        this.loadSettings = {
            ...this.loadSettings,
            [payload.rpName]: payload.status,
        };
    },
    setUpdateSettingsStatus(payload: { rpName: string, status: OperationStatus }) {
        this.updateSettings = {
            ...this.updateSettings,
            [payload.rpName]: payload.status,
        };
    },
    setEmailTemplates(payload: { rpName: string, emailTemplates: Record<VerificationRequestType, EmailTemplate> }) {
        this.emailTemplates = {
            ...this.emailTemplates,
            [payload.rpName]: payload.emailTemplates,
        };
    },
    setLoadEmailTemplatesStatus(payload: { rpName: string, status: OperationStatus }) {
        this.loadEmailTemplates = {
            ...this.loadEmailTemplates,
            [payload.rpName]: payload.status,
        };
    },
    setUpdateEmailTemplatesStatus(payload: {
        rpName: string;
        type: VerificationRequestType;
        status: OperationStatus;
    }) {
        this.updateEmailTemplates = {
            ...this.updateEmailTemplates,
            [payload.rpName]: {
                ...this.updateEmailTemplates[payload.rpName],
                [payload.type]: payload.status,
            },
        };
    },
    setLoadInsuredFieldsStatus(payload: { rpName: string, status: OperationStatus }) {
        this.loadInsuredFields = {
            ...this.loadInsuredFields,
            [payload.rpName]: payload.status,
        };
    },
    setInsuredFields(payload: { rpName: string, insuredFields: string[] }) {
        this.insuredFields = {
            ...this.insuredFields,
            [payload.rpName]: payload.insuredFields,
        };
    },
    setNotificationCadence(payload: {
        rpName: string;
        type: NotificationName;
        cadence: NotificationCadence;
    }) {
        this.cadences = {
            ...this.cadences,
            [payload.rpName]: {
                ...this.cadences[payload.rpName],
                [payload.type]: payload.cadence,
            },
        };
    },
    setLoadNotificationCadenceStatus(payload: {
        rpName: string;
        type: NotificationName;
        status: OperationStatus;
    }) {
        this.loadCadences = {
            ...this.loadCadences,
            [payload.rpName]: {
                ...this.loadCadences[payload.rpName],
                [payload.type]: payload.status,
            },
        };
    },
    setUpdateNotificationCadenceStatus(payload: {
        rpName: string;
        type: NotificationName;
        status: OperationStatus;
    }) {
        this.updateCadences = {
            ...this.updateCadences,
            [payload.rpName]: {
                ...this.updateCadences[payload.rpName],
                [payload.type]: payload.status,
            },
        };
    },
    setSendTestEmailStatus(payload: {
        rpName: string;
        status: OperationStatus;
    }) {
        this.sendTestEmailStatus = {
            ...this.sendTestEmailStatus,
            [payload.rpName]: payload.status,
        };
    },
    clearSendTestEmailStatus(rpName: string) {
        this.sendTestEmailStatus = omit(this.sendTestEmailStatus, [ rpName ]);
    },
}));

function buildInsuranceConfigInput(config: NotificationsConfiguration): InsuranceConfigInput {
    return {
        displayName: config.displayName,
        certificateHolderAddress: config.certificateHolderAddress || null,
    };
}

function buildInsuranceRequestsConfigInput(config: NotificationsConfiguration): PartialDeep<InsuranceRequestsConfig> {
    return {
        coiRequirementsLink: config.coiRequirementsLink || null,
        fulfillmentUrl: config.legacyFulfillmentUrl || null,
    };
}

function buildSupportTimeframes(config: NotificationsConfiguration): SupportTimeframe[] {
    if (!config.supportDaysOfWeek || !config.supportStartTime || !config.supportEndTime || !config.supportTimezone) {
        return [];
    }
    const name = 'support-hours';
    const description = '';
    const enabled = true;
    const supportHours = `${config.supportStartTime} - ${config.supportEndTime}`;
    const supportTimezone = config.supportTimezone;
    const supportDaysOfWeek = config.supportDaysOfWeek.slice().sort();
    const supportDaysOfWeekGroups: { start: DayOfWeek, end: DayOfWeek }[] = [];
    let currentStart: DayOfWeek | null = null;
    let currentEnd: DayOfWeek | null = null;
    for (const dayOfWeek of supportDaysOfWeek) {
        if (currentStart === null) {
            currentStart = dayOfWeek;
            currentEnd = dayOfWeek;
        } else if (dayOfWeek === currentEnd! + 1) {
            currentEnd = dayOfWeek;
        } else {
            supportDaysOfWeekGroups.push({ start: currentStart, end: currentEnd! });
            currentStart = dayOfWeek;
            currentEnd = dayOfWeek;
        }
    }
    if (currentStart !== null) {
        supportDaysOfWeekGroups.push({ start: currentStart, end: currentEnd! });
    }
    return supportDaysOfWeekGroups.map(({ start, end }, index) => ({
        name: `${name}-${index}`,
        description,
        enabled,
        supportHours,
        supportTimezone,
        supportDays: `${start}-${end}`,
    }));
}

function buildRpConfigInput(config: NotificationsConfiguration): Partial<ConfigRelyingPartyInput> {
    return {
        displayName: config.displayName,
        wordmarkUri: config.wordmarkUrl,
        primaryColor: config.primaryColor,
        supportUrl: config.supportUrl || null,
        supportUrlTitle: config.supportUrlTitle || null,
        supportPhone: config.supportPhone || null,
        supportEmail: config.supportEmail || null,
        supportTimeframes: buildSupportTimeframes(config),
    };
}

function buildBrandingInput(config: NotificationsConfiguration): Partial<BrandingInput> {
    return {
        fromName: config.fromName,
        replyToName: config.replyToName,
        replyToEmail: config.replyToEmail,
        boxBehindButtonBgColor: config.primaryColor,
        buttonBgColor: config.primaryColor,
        barBelowWordmarkBgColor: config.primaryColor,
        boxBehindButtonFgColor: config.secondaryColor,
        buttonFgColor: config.secondaryColor,
    };
}

function buildLegacySupportContactInput(config: NotificationsConfiguration): any {
    return {
        email: config.supportEmail || null,
        phone: config.supportPhone || null,
        url: config.supportUrl || null,
        urlTitle: config.supportUrlTitle || null,
    };
}

function buildLegacyEmailInput(config: NotificationsConfiguration): any {
    return {
        fromName: config.fromName,
        replyToName: config.replyToName,
        replyToAddress: config.replyToEmail,
        boxBehindButtonBgColor: config.primaryColor,
        buttonBgColor: config.primaryColor,
        barBelowWordmarkBgColor: config.primaryColor,
        boxBehindButtonFgColor: config.secondaryColor,
        buttonFgColor: config.secondaryColor,
    };
}

function buildLegacyBrandingInput(config: NotificationsConfiguration): any {
    return {
        dbaName: config.displayName,
        primaryColor: config.primaryColor,
        wordmarkUrl: config.wordmarkUrl,
    };
}

const { instantiateActions, instantiateModule, getActions } = createActionFactories(({
    configClient,
    rpweb,
}) => ({
    async loadSettings(payload: { rpName: string }) {
        const { rpName } = payload;
        try {
            this.mutations.setLoadSettingsStatus({ rpName, status: OperationStatus.loading });
            const [
                insuranceConfig,
                insuranceRequestsConfig,
                rpConfig,
                [ brandingConfig ],
            ] = await Promise.all([
                rpweb.getInsuranceConfig(rpName),
                rpweb.getInsuranceRequestsConfig(rpName),
                configClient.getConfig(rpName),
                configClient.getBrandings(rpName),
            ]);
            let fulfillmentConfig = null;
            try {
                fulfillmentConfig = await configClient.getFulfillmentConfig(rpName);
            } catch (error) {
                // silent the not found error, it will be handled in NotificationEmailConfiguration with defaults
                if (error.reason !== 'not-found') {
                    throw error;
                }
                fulfillmentConfig = insuranceRequestsConfig.fulfillmentUrl
                    ? {
                        ...marshFulfillmentConfiguration,
                        fulfillmentUrl: insuranceRequestsConfig.fulfillmentUrl,
                        enabled: true,
                    }
                    : { ...evidentFulfillmentConfiguration, enabled: false };
            }
            // Validate and parse support timeframes
            const timeframes = rpConfig.supportTimeframes
                .filter((x) => x.enabled);
            const supportDays = timeframes
                .filter((time) => /^([1-7])\s*-\s*([1-7])$/.test(time.supportDays))
                .map((time) => time.supportDays.split(/\s*-\s*/))
                .flatMap(([ start, end ]) => range(parseInt(start, 10), parseInt(end, 10) + 1) as DayOfWeek[])
                .filter((x, i, a) => a.indexOf(x) === i)
                .sort();
            const rawSupportHours = timeframes
                .map((x) => x.supportHours)
                .filter((x, i, a) => a.indexOf(x) === i)
                .filter((x) => /^((?:[0-1]?[0-9]|2[0-3]):[0-5][0-9])\s*-\s*((?:[0-1]?[0-9]|2[0-3]):[0-5][0-9])$/);
            const supportHours = rawSupportHours.length === 1 ? rawSupportHours[0].split(/\s*-\s*/) : null;
            const [ supportStartTime, supportEndTime ] = supportHours == null ? [ null, null ] : supportHours;
            const supportTimezones = uniq(timeframes.map((x) => x.supportTimezone));
            const supportTimezone = supportTimezones.length === 1 ? supportTimezones[0] : null;
            this.mutations.setSettings({
                rpName,
                settings: {
                    displayName: rpConfig.displayName,
                    primaryColor: rpConfig.primaryColor,
                    secondaryColor: brandingConfig.buttonFgColor || brandingConfig.boxBehindButtonFgColor,
                    wordmarkUrl: rpConfig.wordmarkUri,
                    fromName: brandingConfig.fromName,
                    replyToEmail: brandingConfig.replyToEmail,
                    replyToName: brandingConfig.replyToName,
                    supportEmail: rpConfig.supportEmail,
                    supportPhone: rpConfig.supportPhone,
                    supportUrl: rpConfig.supportUrl,
                    supportUrlTitle: rpConfig.supportUrlTitle,
                    supportDaysOfWeek: supportDays,
                    supportStartTime,
                    supportEndTime,
                    supportTimezone,
                    certificateHolderAddress: insuranceConfig.certificateHolderAddress || '',
                    coiRequirementsLink: insuranceRequestsConfig.coiRequirementsLink,
                    legacyFulfillmentUrl: insuranceRequestsConfig.fulfillmentUrl,
                    fulfillmentConfiguration: extractFulfillmentConfig(fulfillmentConfig),
                },
            });
            this.mutations.setLoadSettingsStatus({ rpName, status: OperationStatus.success });
        } catch (error) {
            this.mutations.setLoadSettingsStatus({ rpName, status: OperationStatus.error });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async updateSettings(payload: { rpName: string, settings: Partial<NotificationsConfiguration> }) {
        const { rpName } = payload;
        const prevConfig = this.state.settings?.[rpName];
        if (!prevConfig) {
            throw new Error('Tried to update unknown settings');
        }
        const newConfig = { ...prevConfig, ...payload.settings };

        const prevInsuranceConfig = buildInsuranceConfigInput(prevConfig);
        const newInsuranceConfig = buildInsuranceConfigInput(newConfig);
        const prevInsuranceRequestsConfig = buildInsuranceRequestsConfigInput(prevConfig);
        const newInsuranceRequestsConfig = buildInsuranceRequestsConfigInput(newConfig);
        const prevRpConfig = buildRpConfigInput(prevConfig);
        const newRpConfig = buildRpConfigInput(newConfig);
        const prevBrandingConfig = buildBrandingInput(prevConfig);
        const newBrandingConfig = buildBrandingInput(newConfig);
        const prevSupportConfig = buildLegacySupportContactInput(prevConfig);
        const newSupportConfig = buildLegacySupportContactInput(newConfig);
        const prevEmailConfig = buildLegacyEmailInput(prevConfig);
        const newEmailConfig = buildLegacyEmailInput(newConfig);
        const prevLegacyBrandingConfig = buildLegacyBrandingInput(prevConfig);
        const newLegacyBrandingConfig = buildLegacyBrandingInput(newConfig);
        const prevFulfillmentConfig = prevConfig.fulfillmentConfiguration;
        const newFulfillmentConfig = newConfig.fulfillmentConfiguration;
        // previous fulfillment config exists meaning there was data in the config service already, removing legacy url
        if (prevFulfillmentConfig && prevFulfillmentConfig.fulfillmentUrl) {
            newInsuranceRequestsConfig.fulfillmentUrl = null;
        }

        try {
            this.mutations.setUpdateSettingsStatus({ rpName, status: OperationStatus.loading });
            await Promise.all([
                // Insurance
                isEqual(prevInsuranceConfig, newInsuranceConfig)
                    ? Promise.resolve()
                    : rpweb.updateInsuranceConfig(rpName, newInsuranceConfig),
                // Insurance Requests
                isEqual(prevInsuranceRequestsConfig, newInsuranceRequestsConfig)
                    ? Promise.resolve()
                    : rpweb.updateInsuranceRequestsConfig(rpName, newInsuranceRequestsConfig),
                // Config API
                isEqual(prevBrandingConfig, newBrandingConfig)
                    ? Promise.resolve()
                    : configClient.updateDefaultBranding(rpName, newBrandingConfig),
                isEqual(prevRpConfig, newRpConfig)
                    ? Promise.resolve()
                    : configClient.updateConfig(rpName, newRpConfig),
                isEqual(prevFulfillmentConfig, newFulfillmentConfig)
                    ? Promise.resolve()
                    : configClient.updateFulfillmentConfig(rpName, newFulfillmentConfig),
                // Legacy
                isEqual(prevSupportConfig, newSupportConfig)
                    ? Promise.resolve()
                    : rpweb.updateSupportContactInfo(rpName, newSupportConfig),
                isEqual(prevEmailConfig, newEmailConfig)
                    ? Promise.resolve()
                    : rpweb.updateEmailSettings(rpName, newEmailConfig),
                isEqual(prevLegacyBrandingConfig, newLegacyBrandingConfig)
                    ? Promise.resolve()
                    : rpweb.updateBranding(rpName, newLegacyBrandingConfig),
            ]);
            this.mutations.setSettings({ rpName, settings: newConfig });
            this.mutations.setUpdateSettingsStatus({ rpName, status: OperationStatus.success });
        } catch (error) {
            this.mutations.setUpdateSettingsStatus({ rpName, status: OperationStatus.error });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async loadEmailTemplates(payload: { rpName: string }) {
        const { rpName } = payload;
        try {
            this.mutations.setLoadEmailTemplatesStatus({ rpName, status: OperationStatus.loading });
            const { verificationRequestConfigurations } = await rpweb.getInsuranceRequestsConfig(rpName);
            this.mutations.setEmailTemplates({ rpName, emailTemplates: verificationRequestConfigurations });
            this.mutations.setLoadEmailTemplatesStatus({ rpName, status: OperationStatus.success });
        } catch (error) {
            this.mutations.setLoadEmailTemplatesStatus({ rpName, status: OperationStatus.error });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async updateEmailTemplate(payload: { rpName: string, type: VerificationRequestType, template: EmailTemplate }) {
        const { rpName, type, template } = payload;
        try {
            this.mutations.setUpdateEmailTemplatesStatus({ rpName, type, status: OperationStatus.loading });
            await rpweb.updateInsuranceRequestsConfig(rpName, {
                verificationRequestConfigurations: {
                    [type]: template,
                },
            });
            this.mutations.setUpdateEmailTemplatesStatus({ rpName, type, status: OperationStatus.success });
        } catch (error) {
            this.mutations.setUpdateEmailTemplatesStatus({ rpName, type, status: OperationStatus.error });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async loadInsuredFieldIds(payload: { rpName: string }) {
        const { rpName } = payload;
        try {
            this.mutations.setLoadInsuredFieldsStatus({ rpName, status: OperationStatus.loading });
            const rawInsuredFields = await rpweb.getInsuranceInsuredFields(rpName);
            const insuredFields = rawInsuredFields.map((field) => field.key);
            this.mutations.setInsuredFields({ rpName, insuredFields });
            this.mutations.setLoadInsuredFieldsStatus({ rpName, status: OperationStatus.success });
        } catch (error) {
            this.mutations.setLoadInsuredFieldsStatus({ rpName, status: OperationStatus.error });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async loadNotificationCadence(payload: { rpName: string, type: NotificationName }) {
        const { rpName, type } = payload;
        try {
            this.mutations.setLoadNotificationCadenceStatus({ rpName, type, status: OperationStatus.loading });
            const [
                { cadenceConfig },
                { expirationNumberOfDays },
            ] = await Promise.all([
                configClient.getNotificationPreference(rpName, type),
                rpweb.getInsuranceRequestsConfig(rpName),
            ]);
            if (!cadenceConfig) {
                throw new Error(`No cadence available for "${type}".`);
            }
            const cadence = buildNotificationCadence(
                cadenceConfig,
                type === NotificationName.expiringRequestReminder ? expirationNumberOfDays : null,
            );
            this.mutations.setNotificationCadence({ rpName, type, cadence });
            this.mutations.setLoadNotificationCadenceStatus({ rpName, type, status: OperationStatus.success });
        } catch (error) {
            this.mutations.setLoadNotificationCadenceStatus({ rpName, type, status: OperationStatus.error });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async updateNotificationCadence(payload: { rpName: string, type: NotificationName, cadence: NotificationCadence }) {
        const { rpName, type, cadence } = payload;
        try {
            this.mutations.setUpdateNotificationCadenceStatus({ rpName, type, status: OperationStatus.loading });
            const [
                { cadenceConfig },
                { expirationNumberOfDays },
            ] = await Promise.all([
                configClient.getNotificationPreference(rpName, type),
                rpweb.getInsuranceRequestsConfig(rpName),
            ]);
            if (!cadenceConfig) {
                throw new Error(`No cadence available for "${type}".`);
            }

            const input = buildCadenceInput(cadence);
            const promises: Promise<any>[] = [ Promise.resolve() ];

            // Update expiration date if necessary
            if (
                type === NotificationName.expiringRequestReminder &&
                cadence.daysBeforeCount != null &&
                cadence.daysBeforeCount !== expirationNumberOfDays
            ) {
                promises.push(rpweb.updateInsuranceRequestsConfig(rpName, {
                    expirationNumberOfDays: cadence.daysBeforeCount,
                }));
            }

            // Update cadence if necessary
            if (!isEqual(input, pick(cadenceConfig, Object.keys(input)))) {
                promises.push(configClient.updateCadence(rpName, cadenceConfig._id, input));
            }

            // Wait for all requests to complete
            await Promise.all(promises);

            this.mutations.setNotificationCadence({ rpName, type, cadence });
            this.mutations.setUpdateNotificationCadenceStatus({ rpName, type, status: OperationStatus.success });
        } catch (error) {
            this.mutations.setUpdateNotificationCadenceStatus({ rpName, type, status: OperationStatus.error });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async sendTestEmail(payload: { rpName: string, type: VerificationRequestType, to: string, cc?: string[] }) {
        const { rpName, type, to, cc } = payload;
        try {
            this.mutations.setSendTestEmailStatus({ rpName, status: OperationStatus.loading });
            await rpweb.sendTestEmail(rpName, type, to, cc);
            this.mutations.setSendTestEmailStatus({ rpName, status: OperationStatus.success });
        } catch (error) {
            this.mutations.setSendTestEmailStatus({ rpName, status: OperationStatus.error });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async clearSendTestEmailStatus(rpName: string) {
        this.mutations.clearSendTestEmailStatus(rpName);
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};
