/* eslint-disable max-lines */
import omit from 'lodash/omit';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import { createStateFactory } from '@evidentid/vue-commons/store';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import { getPersistingErrorActions } from '@evidentid/dashboard-commons/modules/persisting-error';
import {
    InsuranceCoverageCriteriaGroup,
    InsuranceCoverageCriteriaGroupInput,
    InsuranceCoverageCriterion,
    InsuranceCoverageCriterionInput,
    InsuranceCoverageCriterionMessage,
    InsuranceCoverageCriterionTemplate,
    InsuranceCoverageModel,
    InsuranceCoverageTypesPerCountry,
    InsuranceEffectiveGroup,
    InsuranceInsuredCoverageCriteriaGroup,
    InsuranceInsuredField,
    OperationError,
} from '@evidentid/rpweb-api-client/types';
import { getSnackbarActions } from '@evidentid/dashboard-commons/modules/snackbar';

export interface DecisioningCriteriaRequirement {
    rpweb: RpWebApiClient;
}

export interface CoverageCriteriaGroupsStatus {
    status: OperationStatus;
    list: InsuranceCoverageCriteriaGroup[];
}

export interface InsuredCoverageCriteriaGroupsStatus {
    status: OperationStatus;
    list: InsuranceInsuredCoverageCriteriaGroup[];
}

export type InsuredCoverageCriteriaGroupsStatusPerInsured = Record<string, InsuredCoverageCriteriaGroupsStatus>;

export interface CoverageCriteriaGroupStatus {
    status: OperationStatus;
    data: InsuranceCoverageCriteriaGroup | null;
}

export interface CoverageCriteriaStatus {
    status: OperationStatus;
    criteria: Record<string, InsuranceCoverageCriterion[]>;
}

export interface CoverageCriterionMessageStatus {
    status: OperationStatus;
    data: InsuranceCoverageCriterionMessage | null;
    latestCallNum?: number | null;
}

export type CoverageCriterionMessage = Record<string, CoverageCriterionMessageStatus>;

export interface CoverageCriterionTemplatesStatus {
    status: OperationStatus;
    list: InsuranceCoverageCriterionTemplate[];
}

export interface CoverageTypesPerCountryStatus {
    status: OperationStatus;
    data: InsuranceCoverageTypesPerCountry;
}

export interface InsuredFieldsListStatus {
    status: OperationStatus;
    list: InsuranceInsuredField[];
}

export interface InsuredEffectiveGroupStatus {
    status: OperationStatus;
    list: InsuranceEffectiveGroup[];
}

export interface CoverageModelsStatus {
    status: OperationStatus;
    list: InsuranceCoverageModel[];
}

interface DecisioningCriteriaState {
    insuredFields: Record<string, InsuredFieldsListStatus>;
    coverageCriteriaGroups: Record<string, CoverageCriteriaGroupsStatus>;
    insuredCoverageCriteriaGroups: Record<string, InsuredCoverageCriteriaGroupsStatusPerInsured>;
    currentCoverageCriteriaGroup: Record<string, CoverageCriteriaGroupStatus>;
    coverageCriteria: Record<string, CoverageCriteriaStatus>;
    coverageCriterionTemplates: Record<string, CoverageCriterionTemplatesStatus>;
    coverageCriterionMessage: Record<string, CoverageCriterionMessage>;
    coverageTypesPerCountry: Record<string, CoverageTypesPerCountryStatus>;
    deletingCoverageCriteriaGroupStatus: Record<string, OperationStatus>;
    creatingCoverageCriteriaGroupStatus: Record<string, OperationStatus>;
    creatingCoverageCriteriaStatus: Record<string, OperationStatus>;
    patchingCoverageCriteriaStatus: Record<string, OperationStatus>;
    updatingCoverageCriteriaGroupStatus: Record<string, OperationStatus>;
    effectiveGroupStatus: Record<string, InsuredEffectiveGroupStatus>;
    coverageModelsStatus: Record<string, CoverageModelsStatus>;
}

const createState = createStateFactory<DecisioningCriteriaRequirement>();

export function createEmptyCoverageCriteriaGroupsStatus(): CoverageCriteriaGroupsStatus {
    return {
        status: OperationStatus.uninitialized,
        list: [],
    };
}

export function createEmptyCurrentCoverageCriteriaGroupStatus(): CoverageCriteriaGroupStatus {
    return {
        status: OperationStatus.uninitialized,
        data: null,
    };
}

export function createEmptyInsuredFieldStatus(): InsuredFieldsListStatus {
    return {
        status: OperationStatus.uninitialized,
        list: [],
    };
}

export function createEmptyCoverageCriterionTemplatesStatus(): CoverageCriterionTemplatesStatus {
    return {
        status: OperationStatus.uninitialized,
        list: [],
    };
}

export function createEmptyCoverageCriteriaStatus(): CoverageCriteriaStatus {
    return {
        status: OperationStatus.uninitialized,
        criteria: {},
    };
}

export function createEmptyCoverageTypesPerCountry(): CoverageTypesPerCountryStatus {
    return {
        status: OperationStatus.uninitialized,
        data: {},
    };
}

export function createEmptyEffectiveGroupStatus(): InsuredEffectiveGroupStatus {
    return {
        status: OperationStatus.uninitialized,
        list: [],
    };
}

export function createEmptyCoverageModelsStatus(): CoverageModelsStatus {
    return {
        status: OperationStatus.uninitialized,
        list: [],
    };
}

const { instantiateState, createMutationsFactories } = createState<DecisioningCriteriaState>(() => ({
    insuredFields: {},
    coverageCriteriaGroups: {},
    insuredCoverageCriteriaGroups: {},
    coverageCriteria: {},
    coverageCriterionMessage: {},
    deletingCoverageCriteriaGroupStatus: {},
    creatingCoverageCriteriaGroupStatus: {},
    creatingCoverageCriteriaStatus: {},
    patchingCoverageCriteriaStatus: {},
    updatingCoverageCriteriaGroupStatus: {},
    currentCoverageCriteriaGroup: {},
    coverageCriterionTemplates: {},
    coverageTypesPerCountry: {},
    effectiveGroupStatus: {},
    coverageModelsStatus: {},
}));

const handleActionError = async (scopedContext: any, mutationCall: Function, data: any, error: string) => {
    mutationCall(data);
    const notFoundErrorPattern = /^Error: The requested resource is not found.?/;
    const errorType = notFoundErrorPattern.test(error) ? 'page-not-found' : error;
    await getPersistingErrorActions(scopedContext).showError(errorType);
};

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({
    startLoadingCoverageCriteriaGroups(payload: { rpName: string }) {
        this.coverageCriteriaGroups = {
            ...this.coverageCriteriaGroups,
            [payload.rpName]: {
                ...createEmptyCoverageCriteriaGroupsStatus(),
                ...(this.coverageCriteriaGroups[payload.rpName] as CoverageCriteriaGroupsStatus),
                status: OperationStatus.loading,
            },
        };
    },
    finishLoadingCoverageCriteriaGroups(
        payload: { rpName: string, groups: InsuranceCoverageCriteriaGroup[] },
    ) {
        this.coverageCriteriaGroups = {
            ...this.coverageCriteriaGroups,
            [payload.rpName]: {
                ...(this.coverageCriteriaGroups[payload.rpName] as CoverageCriteriaGroupsStatus),
                status: OperationStatus.success,
                list: payload.groups,
            },
        };
    },
    failLoadingCoverageCriteriaGroups(payload: { rpName: string }) {
        this.coverageCriteriaGroups = {
            ...this.coverageCriteriaGroups,
            [payload.rpName]: {
                ...(this.coverageCriteriaGroups[payload.rpName] as CoverageCriteriaGroupsStatus),
                status: OperationStatus.error,
                list: [],
            },
        };
    },
    startLoadingInsuredCoverageCriteriaGroups(payload: { rpName: string, insuredId: string }) {
        this.insuredCoverageCriteriaGroups = {
            ...this.insuredCoverageCriteriaGroups,
            [payload.rpName]: {
                ...this.insuredCoverageCriteriaGroups[payload.rpName],
                [payload.insuredId]: {
                    status: OperationStatus.loading,
                    list: [],
                },
            },
        };
    },
    finishLoadingInsuredCoverageCriteriaGroups(
        payload: { rpName: string, insuredId: string, insuredCriteriaGroups: InsuranceInsuredCoverageCriteriaGroup[] },
    ) {
        this.insuredCoverageCriteriaGroups = {
            ...this.insuredCoverageCriteriaGroups,
            [payload.rpName]: {
                ...this.insuredCoverageCriteriaGroups[payload.rpName],
                [payload.insuredId]: {
                    status: OperationStatus.success,
                    list: payload.insuredCriteriaGroups,
                },
            },
        };
    },
    failLoadingInsuredCoverageCriteriaGroups(payload: { rpName: string, insuredId: string }) {
        this.insuredCoverageCriteriaGroups = {
            ...this.insuredCoverageCriteriaGroups,
            [payload.rpName]: {
                ...this.insuredCoverageCriteriaGroups[payload.rpName],
                [payload.insuredId]: {
                    status: OperationStatus.error,
                    list: [],
                },
            },
        };
    },
    startLoadingCurrentCoverageCriteriaGroup(payload: { rpName: string }) {
        this.currentCoverageCriteriaGroup = {
            ...this.currentCoverageCriteriaGroup,
            [payload.rpName]: {
                ...createEmptyCurrentCoverageCriteriaGroupStatus(),
                ...(this.currentCoverageCriteriaGroup[payload.rpName] as CoverageCriteriaGroupStatus),
                status: OperationStatus.loading,
            },
        };
    },
    finishLoadingCurrentCoverageCriteriaGroup(
        payload: { rpName: string, group: InsuranceCoverageCriteriaGroup },
    ) {
        this.currentCoverageCriteriaGroup = {
            ...this.currentCoverageCriteriaGroup,
            [payload.rpName]: {
                ...(this.currentCoverageCriteriaGroup[payload.rpName] as CoverageCriteriaGroupStatus),
                status: OperationStatus.success,
                data: payload.group,
            },
        };
    },
    failLoadingCurrentCoverageCriteriaGroup(payload: { rpName: string }) {
        this.currentCoverageCriteriaGroup = {
            ...this.currentCoverageCriteriaGroup,
            [payload.rpName]: {
                ...(this.currentCoverageCriteriaGroup[payload.rpName] as CoverageCriteriaGroupStatus),
                status: OperationStatus.error,
                data: null,
            },
        };
    },
    startLoadingCoverageCriteria(payload: { rpName: string }) {
        this.coverageCriteria = {
            ...this.coverageCriteria,
            [payload.rpName]: {
                ...createEmptyCoverageCriteriaStatus(),
                ...(this.coverageCriteria[payload.rpName] as CoverageCriteriaStatus),
                status: OperationStatus.loading,
            },
        };
    },
    finishLoadingCoverageCriteria(
        payload: { rpName: string, groupId: string, criteria: InsuranceCoverageCriterion[] },
    ) {
        this.coverageCriteria = {
            ...this.coverageCriteria,
            [payload.rpName]: {
                ...(this.coverageCriteria[payload.rpName] as CoverageCriteriaStatus),
                status: OperationStatus.success,
                criteria: {
                    ...(this.coverageCriteria[payload.rpName]?.criteria || {}),
                    [payload.groupId]: payload.criteria,
                },
            },
        };
    },
    failLoadingCoverageCriteria(payload: { rpName: string }) {
        this.coverageCriteria = {
            ...this.coverageCriteria,
            [payload.rpName]: {
                ...(this.coverageCriteria[payload.rpName] as CoverageCriteriaStatus),
                status: OperationStatus.error,
                criteria: {
                    ...(this.coverageCriteria[payload.rpName]?.criteria || {}),
                },
            },
        };
    },
    startLoadingCoverageCriterionMessage(
        payload: { rpName: string, criterionId: string, latestCallNum?: number | null },
    ) {
        const currentState = this.coverageCriterionMessage[payload.rpName]
            ? this.coverageCriterionMessage[payload.rpName][payload.criterionId]
            : null;
        this.coverageCriterionMessage = {
            ...this.coverageCriterionMessage,
            [payload.rpName]: {
                ...this.coverageCriterionMessage[payload.rpName],
                [payload.criterionId]: {
                    status: OperationStatus.loading,
                    data: currentState?.data || null,
                    latestCallNum: payload.latestCallNum || null,
                },
            },
        };
    },
    finishLoadingCoverageCriterionMessage(
        payload: { rpName: string, criterionId: string, criterionMessage: InsuranceCoverageCriterionMessage },
    ) {
        this.coverageCriterionMessage = {
            ...this.coverageCriterionMessage,
            [payload.rpName]: {
                ...this.coverageCriterionMessage[payload.rpName],
                [payload.criterionId]: {
                    status: OperationStatus.success,
                    data: payload.criterionMessage,
                    latestCallNum: null,
                },
            },
        };
    },
    failLoadingCoverageCriterionMessage(payload: { rpName: string, criterionId: string }) {
        const currentState = this.coverageCriterionMessage[payload.rpName]
            ? this.coverageCriterionMessage[payload.rpName][payload.criterionId]
            : null;
        this.coverageCriterionMessage = {
            ...this.coverageCriterionMessage,
            [payload.rpName]: {
                ...this.coverageCriterionMessage[payload.rpName],
                [payload.criterionId]: {
                    status: OperationStatus.error,
                    data: currentState?.data || null,
                    latestCallNum: null,
                },
            },
        };
    },
    startLoadingCoverageCriterionTemplates(payload: { rpName: string }) {
        this.coverageCriterionTemplates = {
            ...this.coverageCriterionTemplates,
            [payload.rpName]: {
                ...createEmptyCoverageCriterionTemplatesStatus(),
                ...(this.coverageCriterionTemplates[payload.rpName] as CoverageCriterionTemplatesStatus),
                status: OperationStatus.loading,
            },
        };
    },
    finishLoadingCoverageCriterionTemplates(
        payload: { rpName: string, templates: InsuranceCoverageCriterionTemplate[] },
    ) {
        this.coverageCriterionTemplates = {
            ...this.coverageCriterionTemplates,
            [payload.rpName]: {
                ...(this.coverageCriterionTemplates[payload.rpName] as CoverageCriterionTemplatesStatus),
                status: OperationStatus.success,
                list: payload.templates,
            },
        };
    },
    failLoadingCoverageCriterionTemplates(payload: { rpName: string }) {
        this.coverageCriterionTemplates = {
            ...this.coverageCriterionTemplates,
            [payload.rpName]: {
                ...(this.coverageCriterionTemplates[payload.rpName] as CoverageCriterionTemplatesStatus),
                status: OperationStatus.error,
                list: [],
            },
        };
    },
    startLoadingCoverageTypesPerCountry(payload: { rpName: string }) {
        this.coverageTypesPerCountry = {
            ...this.coverageTypesPerCountry,
            [payload.rpName]: {
                ...createEmptyCoverageTypesPerCountry(),
                ...this.coverageTypesPerCountry[payload.rpName],
                status: OperationStatus.loading,
            },
        };
    },
    finishLoadingCoverageTypesPerCountry(
        payload: { rpName: string, coverageTypesPerCountry: InsuranceCoverageTypesPerCountry },
    ) {
        this.coverageTypesPerCountry = {
            ...this.coverageTypesPerCountry,
            [payload.rpName]: {
                ...this.coverageTypesPerCountry[payload.rpName],
                status: OperationStatus.success,
                data: payload.coverageTypesPerCountry,
            },
        };
    },
    failLoadingCoverageTypesPerCountry(payload: { rpName: string }) {
        this.coverageTypesPerCountry = {
            ...this.coverageTypesPerCountry,
            [payload.rpName]: {
                ...this.coverageTypesPerCountry[payload.rpName],
                status: OperationStatus.error,
                data: {},
            },
        };
    },
    startDeletingCoverageCriteriaGroup(payload: { rpName: string }) {
        this.deletingCoverageCriteriaGroupStatus = {
            ...this.deletingCoverageCriteriaGroupStatus,
            [payload.rpName]: OperationStatus.loading,
        };
    },
    finishDeletingCoverageCriteriaGroup(
        payload: { rpName: string, groupId: string },
    ) {
        this.deletingCoverageCriteriaGroupStatus = {
            ...this.deletingCoverageCriteriaGroupStatus,
            [payload.rpName]: OperationStatus.success,
        };
        const groups = this.coverageCriteriaGroups[payload.rpName]?.list || [];
        const deletedIndex = groups.findIndex((group) => group.id === payload.groupId);
        if (deletedIndex >= 0) {
            const newList = [ ...groups.slice(0, deletedIndex), ...groups.slice(deletedIndex + 1) ];
            this.coverageCriteriaGroups = {
                ...this.coverageCriteriaGroups,
                [payload.rpName]: {
                    ...(this.coverageCriteriaGroups[payload.rpName] as CoverageCriteriaGroupsStatus),
                    list: newList,
                },
            };
        }
    },
    failDeletingCoverageCriteriaGroup(payload: { rpName: string }) {
        this.deletingCoverageCriteriaGroupStatus = {
            ...this.deletingCoverageCriteriaGroupStatus,
            [payload.rpName]: OperationStatus.error,
        };
    },
    startCreatingCoverageCriteriaGroup(payload: { rpName: string }) {
        this.creatingCoverageCriteriaGroupStatus = {
            ...this.creatingCoverageCriteriaGroupStatus,
            [payload.rpName]: OperationStatus.loading,
        };
    },
    finishCreatingCoverageCriteriaGroup(
        payload: { rpName: string, group: InsuranceCoverageCriteriaGroup },
    ) {
        this.creatingCoverageCriteriaGroupStatus = {
            ...this.creatingCoverageCriteriaGroupStatus,
            [payload.rpName]: OperationStatus.success,
        };
        this.currentCoverageCriteriaGroup = {
            ...this.currentCoverageCriteriaGroup,
            [payload.rpName]: {
                ...(this.currentCoverageCriteriaGroup[payload.rpName] as CoverageCriteriaGroupStatus),
                data: payload.group,
            },
        };
    },
    failCreatingCoverageCriteriaGroup(payload: { rpName: string }) {
        this.creatingCoverageCriteriaGroupStatus = {
            ...this.creatingCoverageCriteriaGroupStatus,
            [payload.rpName]: OperationStatus.error,
        };
    },
    startCreatingCoverageCriteria(payload: { rpName: string }) {
        this.creatingCoverageCriteriaStatus = {
            ...this.creatingCoverageCriteriaStatus,
            [payload.rpName]: OperationStatus.loading,
        };
    },
    finishCreatingCoverageCriteria(
        payload: { rpName: string },
    ) {
        this.creatingCoverageCriteriaStatus = {
            ...this.creatingCoverageCriteriaStatus,
            [payload.rpName]: OperationStatus.success,
        };
    },
    failCreatingCoverageCriteria(payload: { rpName: string }) {
        this.creatingCoverageCriteriaStatus = {
            ...this.creatingCoverageCriteriaStatus,
            [payload.rpName]: OperationStatus.error,
        };
    },
    startPatchingCoverageCriteria(payload: { rpName: string }) {
        this.patchingCoverageCriteriaStatus = {
            ...this.patchingCoverageCriteriaStatus,
            [payload.rpName]: OperationStatus.loading,
        };
    },
    finishPatchingCoverageCriteria(
        payload: { rpName: string },
    ) {
        this.patchingCoverageCriteriaStatus = {
            ...this.patchingCoverageCriteriaStatus,
            [payload.rpName]: OperationStatus.success,
        };
    },
    failPatchingCoverageCriteria(payload: { rpName: string }) {
        this.patchingCoverageCriteriaStatus = {
            ...this.patchingCoverageCriteriaStatus,
            [payload.rpName]: OperationStatus.error,
        };
    },
    startUpdatingCoverageCriteriaGroup(payload: { rpName: string }) {
        this.updatingCoverageCriteriaGroupStatus = {
            ...this.updatingCoverageCriteriaGroupStatus,
            [payload.rpName]: OperationStatus.loading,
        };
    },
    finishUpdatingCoverageCriteriaGroup(
        payload: { rpName: string },
    ) {
        this.updatingCoverageCriteriaGroupStatus = {
            ...this.updatingCoverageCriteriaGroupStatus,
            [payload.rpName]: OperationStatus.success,
        };
    },
    failUpdatingCoverageCriteriaGroup(payload: { rpName: string }) {
        this.updatingCoverageCriteriaGroupStatus = {
            ...this.updatingCoverageCriteriaGroupStatus,
            [payload.rpName]: OperationStatus.error,
        };
    },
    startLoadingEffectiveGroupStatus(payload: { rpName: string }) {
        this.effectiveGroupStatus = {
            ...this.effectiveGroupStatus,
            [payload.rpName]: {
                status: OperationStatus.loading,
                list: [],
            },
        };
    },
    finishLoadingEffectiveGroupStatus(
        payload: { rpName: string, list: InsuranceEffectiveGroup[] },
    ) {
        this.effectiveGroupStatus = {
            ...this.effectiveGroupStatus,
            [payload.rpName]: {
                status: OperationStatus.success,
                list: payload.list,
            },
        };
    },
    failLoadingEffectiveGroupStatus(payload: { rpName: string }) {
        this.effectiveGroupStatus = {
            ...this.effectiveGroupStatus,
            [payload.rpName]: {
                status: OperationStatus.error,
                list: [],
            },
        };
    },
    startLoadingCoverageModels(payload: { rpName: string }) {
        this.coverageModelsStatus = {
            ...this.coverageModelsStatus,
            [payload.rpName]: {
                status: OperationStatus.loading,
                list: [],
            },
        };
    },
    finishLoadingCoverageModels(
        payload: { rpName: string, list: InsuranceCoverageModel[] },
    ) {
        this.coverageModelsStatus = {
            ...this.coverageModelsStatus,
            [payload.rpName]: {
                status: OperationStatus.success,
                list: payload.list,
            },
        };
    },
    failLoadingCoverageModels(payload: { rpName: string }) {
        this.coverageModelsStatus = {
            ...this.coverageModelsStatus,
            [payload.rpName]: {
                status: OperationStatus.error,
                list: [],
            },
        };
    },
    clearCoverageCriteriaGroups(rpName: string) {
        this.coverageCriteriaGroups = omit(this.coverageCriteriaGroups, [ rpName ]);
    },
    clearInsuredCoverageCriteriaGroupsPerInsured(payload: { rpName: string, insuredId: string }) {
        const { rpName, insuredId } = payload;
        this.insuredCoverageCriteriaGroups = {
            ...this.insuredCoverageCriteriaGroups,
            [rpName]: {
                ...omit(this.insuredCoverageCriteriaGroups[rpName], insuredId),
            },
        };
    },
    clearInsuredCoverageCriteriaGroupsPerRp(rpName: string) {
        this.insuredCoverageCriteriaGroups = omit(this.insuredCoverageCriteriaGroups, [ rpName ]);
    },
    clearGroupCoverageCriteria(payload: { rpName: string, groupId: string }) {
        const { rpName, groupId } = payload;
        this.coverageCriteria = {
            ...this.coverageCriteria,
            [rpName]: {
                ...(this.coverageCriteria[rpName] as CoverageCriteriaStatus),
                criteria: omit(this.coverageCriteria[rpName].criteria, groupId),
            },
        };
    },
    clearRpCoverageCriteria(rpName: string) {
        this.coverageCriteria = omit(this.coverageCriteria, [ rpName ]);
    },
    clearCoverageCriterionTemplates(rpName: string) {
        this.coverageCriterionTemplates = omit(this.coverageCriterionTemplates, [ rpName ]);
    },
    clearCoverageTypesPerCountry(rpName: string) {
        this.coverageTypesPerCountry = {
            ...this.coverageTypesPerCountry,
            [rpName]: {
                ...this.coverageTypesPerCountry[rpName],
                data: {},
            },
        };
    },
    clearCurrentCoverageCriteriaGroup(rpName: string) {
        this.currentCoverageCriteriaGroup = omit(this.currentCoverageCriteriaGroup, [ rpName ]);
    },
    clearDeletingCoverageCriteriaGroupStatus(rpName: string) {
        this.deletingCoverageCriteriaGroupStatus = omit(this.deletingCoverageCriteriaGroupStatus, [ rpName ]);
    },
    clearCreatingCoverageCriteriaGroupStatus(rpName: string) {
        this.creatingCoverageCriteriaGroupStatus = omit(this.creatingCoverageCriteriaGroupStatus, [ rpName ]);
    },
    clearCreatingCoverageCriteriaStatus(rpName: string) {
        this.creatingCoverageCriteriaStatus = omit(this.creatingCoverageCriteriaStatus, [ rpName ]);
    },
    clearPatchingCoverageCriteriaStatus(rpName: string) {
        this.patchingCoverageCriteriaStatus = omit(this.patchingCoverageCriteriaStatus, [ rpName ]);
    },
    clearUpdatingCoverageCriteriaGroupStatus(rpName: string) {
        this.updatingCoverageCriteriaGroupStatus = omit(this.updatingCoverageCriteriaGroupStatus, [ rpName ]);
    },
    clearEffectiveGroupStatus(rpName: string) {
        this.effectiveGroupStatus = omit(this.effectiveGroupStatus, [ rpName ]);
    },
    clearCoverageCriterionMessage(rpName: string) {
        this.coverageCriterionMessage = omit(this.coverageCriterionMessage, [ rpName ]);
    },
    clearCoverageModelsStatus(rpName: string) {
        this.coverageModelsStatus = omit(this.coverageModelsStatus, [ rpName ]);
    },
}));

const {
    instantiateActions,
    instantiateModule,
    getActions,
} = createActionFactories(({ rpweb }: DecisioningCriteriaRequirement) => ({
    async loadCoverageCriteriaGroups(payload: {
        rpName: string;
    }) {
        const { rpName } = payload;
        const currentStatus =
            this.state.coverageCriteriaGroups[rpName] || createEmptyCoverageCriteriaGroupsStatus();
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingCoverageCriteriaGroups({ rpName });
        try {
            const latestStatus =
                this.state.coverageCriteriaGroups[rpName] || createEmptyCoverageCriteriaGroupsStatus();
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            const groups = await rpweb.getInsuranceCoverageCriteriaGroups(rpName);
            this.mutations.finishLoadingCoverageCriteriaGroups({
                rpName,
                groups,
            });
        } catch (error) {
            this.mutations.failLoadingCoverageCriteriaGroups({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async loadInsuredCoverageCriteriaGroups(payload: { rpName: string, insuredId: string }) {
        const { rpName, insuredId } = payload;
        const currentRpStatus = this.state.insuredCoverageCriteriaGroups[rpName] || {};
        const currentStatus = currentRpStatus[insuredId] || { status: OperationStatus.uninitialized, list: [] };
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingInsuredCoverageCriteriaGroups({ rpName, insuredId });
        try {
            const latestRpStatus = this.state.insuredCoverageCriteriaGroups[rpName] || {};
            const latestStatus =
                latestRpStatus[insuredId] || { status: OperationStatus.uninitialized, list: [] };
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            const insuredCriteriaGroups = await rpweb.getInsuranceInsuredCoverageCriteriaGroups(rpName, insuredId);
            this.mutations.finishLoadingInsuredCoverageCriteriaGroups({
                rpName,
                insuredId,
                insuredCriteriaGroups,
            });
        } catch (error) {
            this.mutations.failLoadingInsuredCoverageCriteriaGroups({ rpName, insuredId });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async loadCoverageCriteriaGroup(payload: { rpName: string, id: string }) {
        const { rpName, id } = payload;
        const currentStatus =
            this.state.currentCoverageCriteriaGroup[rpName] || createEmptyCurrentCoverageCriteriaGroupStatus();
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingCurrentCoverageCriteriaGroup({ rpName });
        try {
            const latestStatus =
                this.state.currentCoverageCriteriaGroup[rpName] || createEmptyCurrentCoverageCriteriaGroupStatus();
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            const group = await rpweb.getInsuranceCoverageCriteriaGroup(rpName, id);
            this.mutations.finishLoadingCurrentCoverageCriteriaGroup({ rpName, group });
        } catch (error) {
            await handleActionError(this, this.mutations.failLoadingCurrentCoverageCriteriaGroup, { rpName }, error);
        }
    },
    async loadCoverageCriteria(payload: { rpName: string, groupId: string }) {
        const { rpName, groupId } = payload;
        const currentStatus =
            this.state.coverageCriteria[rpName] || createEmptyCoverageCriteriaStatus();
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingCoverageCriteria({ rpName });
        try {
            const latestStatus =
                this.state.coverageCriteria[rpName] || createEmptyCoverageCriteriaStatus();
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            const criteria = await rpweb.getInsuranceCoverageCriteria(rpName, groupId);
            this.mutations.finishLoadingCoverageCriteria({ rpName, groupId, criteria });
        } catch (error) {
            await handleActionError(this, this.mutations.failLoadingCoverageCriteria, { rpName }, error);
        }
    },
    async loadCoverageCriterionTemplates(payload: { rpName: string }) {
        const { rpName } = payload;
        const currentStatus =
            this.state.coverageCriterionTemplates[rpName] || createEmptyCoverageCriterionTemplatesStatus();
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingCoverageCriterionTemplates({ rpName });
        try {
            const latestStatus =
                this.state.coverageCriterionTemplates[rpName] || createEmptyCoverageCriterionTemplatesStatus();
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            const templates = await rpweb.getInsuranceCoverageCriteriaTemplates(rpName);
            this.mutations.finishLoadingCoverageCriterionTemplates({ rpName, templates });
        } catch (error) {
            this.mutations.failLoadingCoverageCriterionTemplates({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async loadCoverageCriterionMessage(
        payload: { rpName: string, criterion: InsuranceCoverageCriterionInput | InsuranceCoverageCriterion },
    ) {
        const { rpName, criterion } = payload;
        const criterionId = 'id' in criterion ? criterion.id : `${criterion.coverageType}-${criterion.field}`;
        const currentState = this.state.coverageCriterionMessage?.[rpName]?.[criterionId] ||
            { status: OperationStatus.uninitialized, data: null };
        const localNum = (currentState.latestCallNum ?? 0) + 1;
        this.mutations.startLoadingCoverageCriterionMessage({ rpName, criterionId, latestCallNum: localNum });
        try {
            const criterionMessage = await rpweb.postInsuranceCriterionForMessage(rpName, criterion);
            const latestState = this.state.coverageCriterionMessage?.[rpName]?.[criterionId] ||
                { status: OperationStatus.uninitialized, data: null };
            if (latestState.latestCallNum === localNum) {
                this.mutations.finishLoadingCoverageCriterionMessage({ rpName, criterionId, criterionMessage });
            }
        } catch (error) {
            this.mutations.failLoadingCoverageCriterionMessage({ rpName, criterionId });
            console.error(error);
        }
    },
    async loadCoverageTypesPerCountry(payload: { rpName: string }) {
        const { rpName } = payload;
        const currentStatus =
            this.state.coverageTypesPerCountry[rpName] || createEmptyCoverageTypesPerCountry();
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingCoverageTypesPerCountry({ rpName });
        try {
            const latestStatus =
                this.state.coverageTypesPerCountry[rpName] || createEmptyCoverageTypesPerCountry();
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            const coverageTypesPerCountry = await rpweb.getInsuranceCoverageTypesPerCountry(rpName);
            this.mutations.finishLoadingCoverageTypesPerCountry({ rpName, coverageTypesPerCountry });
        } catch (error) {
            this.mutations.failLoadingCoverageTypesPerCountry({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async deleteCoverageCriteriaGroup(payload: { rpName: string, groupId: string }) {
        const { rpName, groupId } = payload;
        const currentStatus =
            this.state.deletingCoverageCriteriaGroupStatus[rpName] || OperationStatus.uninitialized;
        if (currentStatus === OperationStatus.loading) {
            return;
        }
        this.mutations.startDeletingCoverageCriteriaGroup({ rpName });
        try {
            const latestStatus =
                this.state.deletingCoverageCriteriaGroupStatus[rpName] || OperationStatus.uninitialized;
            if (latestStatus !== OperationStatus.loading &&
                latestStatus !== OperationStatus.uninitialized) {
                return;
            }
            await rpweb.deleteInsuranceCoverageCriteriaGroup(rpName, groupId);
            this.mutations.finishDeletingCoverageCriteriaGroup({
                rpName,
                groupId: payload.groupId,
            });
        } catch (error) {
            this.mutations.failDeletingCoverageCriteriaGroup({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async createCoverageCriteriaGroup(payload: { rpName: string, group: InsuranceCoverageCriteriaGroupInput }) {
        const { rpName, group } = payload;
        const currentStatus =
            this.state.creatingCoverageCriteriaGroupStatus[rpName] || OperationStatus.uninitialized;
        if (currentStatus === OperationStatus.loading) {
            return;
        }
        this.mutations.startCreatingCoverageCriteriaGroup({ rpName });
        try {
            const latestStatus =
                this.state.creatingCoverageCriteriaGroupStatus[rpName] || OperationStatus.uninitialized;
            if (latestStatus !== OperationStatus.loading &&
                latestStatus !== OperationStatus.uninitialized) {
                return;
            }
            const savedGroup = await rpweb.createInsuranceCoverageCriteriaGroup(rpName, group);
            this.mutations.finishCreatingCoverageCriteriaGroup({ rpName, group: savedGroup });
        } catch (error) {
            this.mutations.failCreatingCoverageCriteriaGroup({ rpName });
            console.error('Failed to create coverage criteria group', error);
            const message = error.error === OperationError.keyConflict
                ? `A coverage criteria group with the name ${group.displayName} already exists. Please try a different name.`
                : 'Something went wrong, please try again or contact support';
            await getSnackbarActions(this).displaySnackbar({ permanent: false, message });
        }
    },
    async createCoverageCriteria(payload: {
        rpName: string;
        groupId: string;
        criteria: InsuranceCoverageCriterionInput[];
    }) {
        const { rpName, groupId, criteria } = payload;
        const currentStatus =
            this.state.creatingCoverageCriteriaStatus[rpName] || OperationStatus.uninitialized;
        if (currentStatus === OperationStatus.loading) {
            return;
        }
        this.mutations.startCreatingCoverageCriteria({ rpName });
        try {
            const latestStatus =
                this.state.creatingCoverageCriteriaStatus[rpName] || OperationStatus.uninitialized;
            if (latestStatus !== OperationStatus.loading &&
                latestStatus !== OperationStatus.uninitialized) {
                return;
            }
            await rpweb.createInsuranceCoverageCriteria(rpName, groupId, criteria);
            this.mutations.finishCreatingCoverageCriteria({ rpName });
        } catch (error) {
            this.mutations.failCreatingCoverageCriteria({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async patchCoverageCriteria(payload: {
        rpName: string;
        groupId: string;
        patches: any[];
    }) {
        const { rpName, groupId, patches } = payload;
        const currentStatus =
            this.state.patchingCoverageCriteriaStatus[rpName] || OperationStatus.uninitialized;
        if (currentStatus === OperationStatus.loading) {
            return;
        }
        this.mutations.startPatchingCoverageCriteria({ rpName });
        try {
            const latestStatus =
                this.state.patchingCoverageCriteriaStatus[rpName] || OperationStatus.uninitialized;
            if (latestStatus !== OperationStatus.loading &&
                latestStatus !== OperationStatus.uninitialized) {
                return;
            }
            await rpweb.patchInsuranceCoverageCriteria(rpName, groupId, patches);
            this.mutations.finishPatchingCoverageCriteria({ rpName });
        } catch (error) {
            this.mutations.failPatchingCoverageCriteria({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async updateCoverageCriteriaGroup(payload: { rpName: string, group: InsuranceCoverageCriteriaGroup }) {
        const { rpName, group } = payload;
        const currentStatus =
            this.state.updatingCoverageCriteriaGroupStatus[rpName] || OperationStatus.uninitialized;
        if (currentStatus === OperationStatus.loading) {
            return;
        }
        this.mutations.startUpdatingCoverageCriteriaGroup({ rpName });
        try {
            const latestStatus =
                this.state.updatingCoverageCriteriaGroupStatus[rpName] || OperationStatus.uninitialized;
            if (latestStatus !== OperationStatus.loading &&
                latestStatus !== OperationStatus.uninitialized) {
                return;
            }
            await rpweb.updateInsuranceCoverageCriteriaGroup(rpName, group);
            this.mutations.finishUpdatingCoverageCriteriaGroup({ rpName });
        } catch (error) {
            this.mutations.failUpdatingCoverageCriteriaGroup({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async loadEffectiveGroups(payload: { rpName: string }) {
        const { rpName } = payload;
        const currentStatus =
            this.state.effectiveGroupStatus[rpName] || createEmptyEffectiveGroupStatus();
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingEffectiveGroupStatus({ rpName });
        try {
            const latestStatus =
                this.state.effectiveGroupStatus[rpName] || createEmptyCoverageTypesPerCountry();
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            const list = await rpweb.getInsuranceEffectiveGroups(rpName);
            this.mutations.finishLoadingEffectiveGroupStatus({ rpName, list });
        } catch (error) {
            this.mutations.failLoadingEffectiveGroupStatus({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    async loadCoverageModels(payload: { rpName: string }) {
        const { rpName } = payload;
        const currentStatus =
            this.state.coverageModelsStatus[rpName] || createEmptyCoverageModelsStatus();
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingCoverageModels({ rpName });
        try {
            const latestStatus =
                this.state.coverageModelsStatus[rpName] || createEmptyCoverageModelsStatus();
            if (latestStatus.status !== OperationStatus.loading
                && latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            const list = await rpweb.getInsuranceCoverageModels(rpName);
            this.mutations.finishLoadingCoverageModels({ rpName, list });
        } catch (error) {
            this.mutations.failLoadingCoverageModels({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    clearCoverageCriteriaGroups(payload: { rpName: string }) {
        this.mutations.clearCoverageCriteriaGroups(payload.rpName);
    },
    clearCoverageCriterionTemplates(payload: { rpName: string }) {
        this.mutations.clearCoverageCriterionTemplates(payload.rpName);
    },
    clearCoverageTypesPerCountry(payload: { rpName: string }) {
        this.mutations.clearCoverageTypesPerCountry(payload.rpName);
    },
    clearCurrentCoverageCriteriaGroups(payload: { rpName: string }) {
        this.mutations.clearCurrentCoverageCriteriaGroup(payload.rpName);
    },
    clearDeletingCoverageCriteriaGroupStatus(payload: { rpName: string }) {
        this.mutations.clearDeletingCoverageCriteriaGroupStatus(payload.rpName);
    },
    clearCreatingCoverageCriteriaGroupStatus(payload: { rpName: string }) {
        this.mutations.clearCreatingCoverageCriteriaGroupStatus(payload.rpName);
    },
    clearCreatingCoverageCriteriaStatus(payload: { rpName: string }) {
        this.mutations.clearCreatingCoverageCriteriaStatus(payload.rpName);
    },
    clearPatchingCoverageCriteriaStatus(payload: { rpName: string }) {
        this.mutations.clearPatchingCoverageCriteriaStatus(payload.rpName);
    },
    clearUpdatingCoverageCriteriaGroupStatus(payload: { rpName: string }) {
        this.mutations.clearUpdatingCoverageCriteriaGroupStatus(payload.rpName);
    },
    clearRpCoverageCriteria(payload: { rpName: string }) {
        this.mutations.clearRpCoverageCriteria(payload.rpName);
    },
    clearGroupCoverageCriteria(payload: { rpName: string, groupId: string }) {
        this.mutations.clearGroupCoverageCriteria({ rpName: payload.rpName, groupId: payload.groupId });
    },
    clearRpInsuredCriteriaGroups(payload: { rpName: string }) {
        this.mutations.clearInsuredCoverageCriteriaGroupsPerRp(payload.rpName);
    },
    clearInsuredCriteriaGroups(payload: { rpName: string, insuredId: string }) {
        this.mutations.clearInsuredCoverageCriteriaGroupsPerInsured(payload);
    },
    clearEffectiveGroupsStatus(payload: { rpName: string }) {
        this.mutations.clearEffectiveGroupStatus(payload.rpName);
    },
    clearCoverageCriterionMessage(payload: { rpName: string }) {
        this.mutations.clearCoverageCriterionMessage(payload.rpName);
    },
    clearCoverageModelsStatus(payload: { rpName: string }) {
        this.mutations.clearCoverageModelsStatus(payload.rpName);
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};
