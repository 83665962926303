import omit from 'lodash/omit';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import { createStateFactory } from '@evidentid/vue-commons/store';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import { getPersistingErrorActions } from '@evidentid/dashboard-commons/modules/persisting-error';
import {
    InsuranceActionResolveInput,
    InsuranceCoverageType,
    InsuranceInsuredActionDetails,
} from '@evidentid/rpweb-api-client/types';
import { fetchCoiDocuments } from '@/modules/insured-actions-review/utils/fetchCoiDocuments';
import { ActionReviewFilters } from '@/modules/insured-actions-review/types';

export interface InsuredActionsReviewRequirement {
    rpweb: RpWebApiClient;
}

export interface InsuredActionsStatus {
    status: OperationStatus;
    list: InsuranceInsuredActionDetails[];
}

export interface CoiDocumentStatus {
    status: OperationStatus;
    list: any[];
}

export interface EndorsementCategoriesStatus {
    status: OperationStatus;
    list: string[];
}

export type CoiDocumentStatusPerAction = Record<string, CoiDocumentStatus>;

interface InsuredActionsReviewState {
    insuredActions: Record<string, InsuredActionsStatus>;
    endorsementCategories: Record<string, EndorsementCategoriesStatus>;
    coiDocuments: Record<string, CoiDocumentStatusPerAction>;
    resolvingActionStatus: Record<string, OperationStatus>;
}

const actionsReviewConfig = {
    recordsPerPage: 50,
};

const createState = createStateFactory<InsuredActionsReviewRequirement>();

export function createEmptyInsuredActionsStatus(): InsuredActionsStatus {
    return {
        status: OperationStatus.uninitialized,
        list: [],
    };
}

export function createEmptyListStatus(): any {
    return {
        status: OperationStatus.uninitialized,
        list: [],
    };
}

const { instantiateState, createMutationsFactories } = createState<InsuredActionsReviewState>(() => ({
    insuredActions: {},
    endorsementCategories: {},
    coiDocuments: {},
    resolvingActionStatus: {},
}));

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({
    startLoadingInsuredActions(payload: { rpName: string }) {
        this.insuredActions = {
            ...this.insuredActions,
            [payload.rpName]: {
                ...createEmptyInsuredActionsStatus(),
                ...(this.insuredActions[payload.rpName] as InsuredActionsStatus),
                status: OperationStatus.loading,
            },
        };
    },
    finishLoadingInsuredActions(
        payload: { rpName: string, insuredActions: InsuranceInsuredActionDetails[] },
    ) {
        this.insuredActions = {
            ...this.insuredActions,
            [payload.rpName]: {
                ...(this.insuredActions[payload.rpName] as InsuredActionsStatus),
                status: OperationStatus.success,
                list: payload.insuredActions,
            },
        };
    },
    failLoadingInsuredActions(payload: { rpName: string }) {
        this.insuredActions = {
            ...this.insuredActions,
            [payload.rpName]: {
                ...(this.insuredActions[payload.rpName] as InsuredActionsStatus),
                status: OperationStatus.error,
                list: [],
            },
        };
    },
    startLoadingEndorsementCategories(payload: { rpName: string }) {
        this.endorsementCategories = {
            ...this.endorsementCategories,
            [payload.rpName]: {
                ...createEmptyListStatus(),
                ...(this.endorsementCategories[payload.rpName] as EndorsementCategoriesStatus),
                status: OperationStatus.loading,
            },
        };
    },
    finishLoadingEndorsementCategories(
        payload: { rpName: string, endorsementCategories: string[] },
    ) {
        this.endorsementCategories = {
            ...this.endorsementCategories,
            [payload.rpName]: {
                ...(this.endorsementCategories[payload.rpName] as EndorsementCategoriesStatus),
                status: OperationStatus.success,
                list: payload.endorsementCategories,
            },
        };
    },
    failLoadingEndorsementCategories(payload: { rpName: string }) {
        this.endorsementCategories = {
            ...this.endorsementCategories,
            [payload.rpName]: {
                ...(this.endorsementCategories[payload.rpName] as EndorsementCategoriesStatus),
                status: OperationStatus.error,
                list: [],
            },
        };
    },
    startLoadingCoiDocuments(payload: { rpName: string, actionId: string }) {
        this.coiDocuments = {
            ...this.coiDocuments,
            [payload.rpName]: {
                ...(this.coiDocuments[payload.rpName] as CoiDocumentStatusPerAction),
                [payload.actionId]: {
                    status: OperationStatus.loading,
                    list: [],
                },
            },
        };
    },
    finishLoadingCoiDocuments(
        payload: { rpName: string, actionId: string, documents: any[] },
    ) {
        this.coiDocuments = {
            ...this.coiDocuments,
            [payload.rpName]: {
                ...(this.coiDocuments[payload.rpName] as CoiDocumentStatusPerAction),
                [payload.actionId]: {
                    status: OperationStatus.success,
                    list: payload.documents,
                },
            },
        };
    },
    failLoadingCoiDocuments(payload: { rpName: string, actionId: string }) {
        this.coiDocuments = {
            ...this.coiDocuments,
            [payload.rpName]: {
                ...(this.coiDocuments[payload.rpName] as CoiDocumentStatusPerAction),
                [payload.actionId]: {
                    status: OperationStatus.error,
                    list: [],
                },
            },
        };
    },
    startResolvingAction(payload: { rpName: string }) {
        this.resolvingActionStatus = {
            ...this.resolvingActionStatus,
            [payload.rpName]: OperationStatus.loading,
        };
    },
    finishResolvingAction(
        payload: { rpName: string, actionId: string },
    ) {
        this.resolvingActionStatus = {
            ...this.resolvingActionStatus,
            [payload.rpName]: OperationStatus.success,
        };
        const actionList = this.insuredActions[payload.rpName].list || [];
        const actionIndex = actionList.findIndex((action) => action.id === payload.actionId);
        if (actionIndex >= 0) {
            this.insuredActions = {
                ...this.insuredActions,
                [payload.rpName]: {
                    ...(this.insuredActions[payload.rpName] as InsuredActionsStatus),
                    status: OperationStatus.success,
                    list: [ ...actionList.slice(0, actionIndex), ...actionList.slice(actionIndex + 1) ],
                },
            };
        }
    },
    failResolvingAction(payload: { rpName: string }) {
        this.resolvingActionStatus = {
            ...this.resolvingActionStatus,
            [payload.rpName]: OperationStatus.error,
        };
    },

    clearInsuredActionsStatus(rpName: string) {
        this.insuredActions = omit(this.insuredActions, [ rpName ]);
    },
    clearEndorsementCategoriesStatus(rpName: string) {
        this.endorsementCategories = omit(this.endorsementCategories, [ rpName ]);
    },
    clearEndorsementPerAction(payload: { rpName: string, actionId: string }) {
        const { rpName, actionId } = payload;
        this.coiDocuments = {
            ...this.coiDocuments,
            [rpName]: {
                ...omit(this.coiDocuments[rpName], actionId),
            },
        };
    },
    clearEndorsementPerRp(rpName: string) {
        this.coiDocuments = omit(this.coiDocuments, [ rpName ]);
    },

    clearResolvingInsuredActionStatus(rpName: string) {
        this.resolvingActionStatus = omit(this.resolvingActionStatus, [ rpName ]);
    },
}));

const {
    instantiateActions,
    instantiateModule,
    getActions,
} = createActionFactories(({ rpweb }: InsuredActionsReviewRequirement) => ({
    async loadInsuredActions(payload: {
        rpName: string;
        sort?: string | null;
        filters?: ActionReviewFilters;
    }) {
        const { rpName, sort, filters } = payload;
        const currentStatus =
            this.state.insuredActions[rpName] || createEmptyInsuredActionsStatus();
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingInsuredActions({ rpName });
        const queryOptions = {
            limit: actionsReviewConfig.recordsPerPage,
            sort: sort || null,
            pending: true,
            ...filters,
        };
        try {
            const latestStatus =
                this.state.insuredActions[rpName] || createEmptyInsuredActionsStatus();
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            const { actions } = await rpweb.getInsuranceInsuredActions(rpName, queryOptions);
            const mock = {
                id: 'dd7ba3a9-8a68-4f00-9283-6f025e52d2c9',
                action: {
                    $action: 'EvaluatedEntityMappingV1',
                    evaluatedEntityId: '12345678-1234-1234-1234-1234567890AB',
                    requiredEntity: {
                        description: 'Lamborghini Tractor',
                        uniqueIdentifier: '12345',
                    },
                    providedEntities: [
                        {
                            verificationId: '22345678-1234-1234-1234-222222222222',
                            relyingPartyRequestId: '1c3b8e94-f610-4748-aad5-64e47a2e116e',
                            coverageType: InsuranceCoverageType.commercialCrimeLiability,
                            providedEntity: {
                                uniqueIdentifier: '12345v1',
                                description: 'Fastest tractor on the planet',
                            },
                        },
                        {
                            verificationId: '22345678-1234-1234-1234-222222222222',
                            relyingPartyRequestId: '90692b1c-e838-4e9d-a8f1-2500a79090c0',
                            coverageType: InsuranceCoverageType.commercialProperty,
                            providedEntity: {
                                uniqueIdentifier: '41772849718942',
                                description: 'Lamborghini Countach',
                            },
                        },
                    ],
                },
                evaluationResults: [],
                createdAt: '2022-08-03T19:37:20',
                completedAt: null,
                insuredId: '8535a89c-e676-40e9-a259-d8868b3b7b1c',

            } as any as InsuranceInsuredActionDetails;
            // TODO: resolve before merge, once finishes test, remove mock data
            this.mutations.finishLoadingInsuredActions({
                rpName,
                insuredActions: actions,
            });
        } catch (error) {
            // for now the insuredId should be the only query that we should handle differently when invalid format,
            // if we need to support more in the future we should change this check to be less explicit
            if (error.reason === 'bad-request' &&
                error.xhrObj.responseText.includes('Invalid value for: query parameter insuredId')) {
                this.mutations.finishLoadingInsuredActions({
                    rpName,
                    insuredActions: [],
                });
                throw error;
            } else {
                this.mutations.failLoadingInsuredActions({ rpName });
                await getPersistingErrorActions(this).showError(error);
            }
        }
    }, async loadEndorsementCategories(payload: { rpName: string }) {
        const { rpName } = payload;
        const currentStatus =
            this.state.endorsementCategories[rpName] || createEmptyListStatus();
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingEndorsementCategories({ rpName });
        try {
            const latestStatus =
                this.state.endorsementCategories[rpName] || createEmptyListStatus();
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            const endorsementCategories = await rpweb.getInsuranceEndorsementCategories(rpName);
            this.mutations.finishLoadingEndorsementCategories({
                rpName,
                endorsementCategories,
            });
        } catch (error) {
            this.mutations.failLoadingEndorsementCategories({ rpName });
            await getPersistingErrorActions(this).showError(error);
        }
    }, async resolveInsuredAction(payload: {
        rpName: string; actionId: string; resolve: InsuranceActionResolveInput;
    }) {
        const { rpName, actionId, resolve } = payload;
        try {
            this.mutations.startResolvingAction({ rpName });
            await rpweb.createActionResolve(rpName, actionId, resolve);
            this.mutations.finishResolvingAction({ rpName, actionId });
        } catch (error) {
            console.warn('Resolve action error', error);
            this.mutations.failResolvingAction({ rpName });
        }
    },
    async loadCoiDocuments(payload: {
        rpName: string;
        actionId: string;
        verificationIds?: string[];
        rprIds?: string[];
    }) {
        const { rpName, actionId, verificationIds, rprIds } = payload;
        const currentRpStatus = this.state.coiDocuments[rpName] || {};
        const currentStatus = currentRpStatus[actionId] || { status: OperationStatus.uninitialized, list: [] };
        if (currentStatus.status === OperationStatus.loading) {
            return;
        }
        this.mutations.startLoadingCoiDocuments({ rpName, actionId });
        try {
            const latestRpStatus = this.state.coiDocuments[rpName] || {};
            const latestStatus =
                latestRpStatus[actionId] || { status: OperationStatus.uninitialized, list: [] };
            if (latestStatus.status !== OperationStatus.loading &&
                latestStatus.status !== OperationStatus.uninitialized) {
                return;
            }
            let rpRequestIds = rprIds;
            if (!rpRequestIds && verificationIds) {
                const verifications = await Promise.all(
                    verificationIds.map((id) => rpweb.getInsuranceVerification(rpName, id)),
                );
                rpRequestIds = verifications.map((verification) => verification.requestId);
            }

            const documents = await fetchCoiDocuments(rpweb, rpName, rpRequestIds || []);
            this.mutations.finishLoadingCoiDocuments({
                rpName,
                actionId,
                documents,
            });
        } catch (error) {
            this.mutations.failLoadingCoiDocuments({ rpName, actionId });
            console.warn(`No certificate of documents found for ${actionId}`, error);
        }
    },
    clearEndorsementPerAction(payload: { rpName: string, actionId: string }) {
        this.mutations.clearEndorsementPerAction(payload);
    },
    clearEndorsementPerRp(payload: { rpName: string }) {
        this.mutations.clearEndorsementPerRp(payload.rpName);
    },
    clearInsuredActions(payload: { rpName: string }) {
        this.mutations.clearInsuredActionsStatus(payload.rpName);
    },
    clearEndorsementCategories(payload: { rpName: string }) {
        this.mutations.clearEndorsementCategoriesStatus(payload.rpName);
    },
    clearResolvingInsuredActionStatus(payload: { rpName: string }) {
        this.mutations.clearResolvingInsuredActionStatus(payload.rpName);
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};
