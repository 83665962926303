import { InsuranceCoverageCriteriaGroupType } from '@evidentid/rpweb-api-client/types';
import coverageTypesIcon from '@/modules/decisioning-criteria/assets/coverage-types-ccg-icon.svg';
import collateralTypeIcon from '@/modules/decisioning-criteria/assets/collateral-type-ccg-icon.svg';

const typeIconMapping = {
    [InsuranceCoverageCriteriaGroupType.collateral]: collateralTypeIcon,
    [InsuranceCoverageCriteriaGroupType.default]: coverageTypesIcon,
};

export function getCcgTypeIconSrc(type: InsuranceCoverageCriteriaGroupType): string {
    return typeIconMapping[type] || coverageTypesIcon;
}
