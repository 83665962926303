import RpWebApiClient from '@evidentid/rpweb-api-client';
import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import { createStateFactory } from '@evidentid/vue-commons/store';
import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
import { downloadFileUsingBinString } from '@evidentid/file-utils/blobs';
import Severity from '@evidentid/logger/Severity';
import { getPersistingErrorActions } from '@evidentid/dashboard-commons/modules/persisting-error';

export interface ExportInsuredsRequirements {
    rpweb: RpWebApiClient;
    tracker?: Tracker<any>;
    logger?: Logger<any>;
}

const createState = createStateFactory<ExportInsuredsRequirements>();

interface ExportInsuredsState {
    legacyExportStatus: Record<string, OperationStatus>;
    exportStatus: Record<string, OperationStatus>;
}

const { instantiateState, createMutationsFactories } = createState<ExportInsuredsState>(() => ({
    legacyExportStatus: {},
    exportStatus: {},
}));

const { instantiateMutations, createActionFactories } = createMutationsFactories(() => ({
    setExportStatus(payload: { rpName: string, status: OperationStatus }) {
        const { rpName, status } = payload;
        this.exportStatus = {
            ...this.exportStatus,
            [rpName]: status,
        };
    },
    setLegacyExportStatus(payload: { rpName: string, status: OperationStatus }) {
        const { rpName, status } = payload;
        this.legacyExportStatus = {
            ...this.legacyExportStatus,
            [rpName]: status,
        };
    },
}));

const {
    instantiateActions,
    instantiateModule,
    getActions,
} = createActionFactories(({ rpweb, tracker, logger }: ExportInsuredsRequirements) => ({
    async exportInsureds(payload: { rpName: string }) {
        const { rpName } = payload;
        if (this.state.exportStatus[rpName] === OperationStatus.loading) {
            return;
        }
        this.mutations.setExportStatus({ rpName, status: OperationStatus.loading });
        try {
            const exportedFile = await rpweb.getInsuranceInsuredExport(rpName);
            downloadFileUsingBinString(exportedFile, 'Insurance Export.csv');
            this.mutations.setExportStatus({ rpName, status: OperationStatus.success });
        } catch (error) {
            this.mutations.setExportStatus({ rpName, status: OperationStatus.error });
            await getPersistingErrorActions(this).showError(error);
        }
    },
    // TODO[PRODUCT-17919}: remove legacy export once all sheet users migrated
    async legacyExportInsureds(payload: { rpName: string }) {
        const { rpName } = payload;
        if (this.state.legacyExportStatus[rpName] === OperationStatus.loading) {
            return;
        }
        if (tracker) {
            tracker.track({
                name: 'Export',
                description: `[Requested] Export for ${rpName}`,
                rpName,
            });
        }
        if (logger) {
            logger.addBreadcrumb({
                category: 'Export',
                message: `[Requested] Export for ${rpName}`,
                data: { rpName },
            });
        }
        this.mutations.setLegacyExportStatus({ rpName, status: OperationStatus.loading });
        try {
            const exportedFile = await rpweb.getInsuranceExport(rpName);
            downloadFileUsingBinString(exportedFile, 'Insurance Export.csv');
            this.mutations.setLegacyExportStatus({ rpName, status: OperationStatus.success });
            if (tracker) {
                tracker.track({
                    name: 'Export',
                    description: `[Finished] Export for ${rpName}`,
                    rpName,
                });
            }
            if (logger) {
                logger.addBreadcrumb({
                    category: 'Export',
                    message: `[Finished] Export for ${rpName}`,
                    data: { rpName },
                });
            }
        } catch (error) {
            this.mutations.setLegacyExportStatus({ rpName, status: OperationStatus.error });
            if (tracker) {
                tracker.track({
                    name: 'Export Failed',
                    description: `[Failed] Export for ${rpName}`,
                    rpName,
                });
            }
            if (logger) {
                logger.addBreadcrumb({
                    level: Severity.error,
                    category: 'Export',
                    message: `[Failed] Export for ${rpName}`,
                    data: { rpName, error },
                });
            }
        }
    },
}));

export default {
    instantiateState,
    instantiateActions,
    instantiateMutations,
    instantiateModule,
    getActions,
};
