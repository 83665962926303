import { JsonSchemaType, JsonSchemaBase } from '@evidentid/json-schema/interfaces/JsonSchema';
import { InsuranceInsuredField } from '@evidentid/rpweb-api-client/types';

export function filterInsuredFieldsByValueType(
    insuredFields: InsuranceInsuredField[],
    insuredValueTypeFilterBy?: JsonSchemaType | null,
): InsuranceInsuredField[] {
    return insuredFields.filter((field) => {
        if (insuredValueTypeFilterBy && insuredValueTypeFilterBy !== (field.schema as JsonSchemaBase)?.type) {
            return false;
        }

        return true;
    });
}
