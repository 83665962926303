import { JsonSchemaType, RegularJsonSchema } from '@evidentid/json-schema/interfaces/JsonSchema';
import {
    InsuranceCoverageCriterion,
    InsuranceCoverageCriterionInput,
    InsuranceCoverageCriterionTemplate,
} from '@evidentid/rpweb-api-client/types';

interface Params {
    template: InsuranceCoverageCriterionTemplate;
    criterion: InsuranceCoverageCriterionInput | InsuranceCoverageCriterion;
}

export const getFlagDisabledReferenceValueType = ({ template, criterion }: Params): JsonSchemaType | null => {
    const conditionalRefs = template.displayMetadata.conditionalValueReference;

    if (!conditionalRefs) {
        return null;
    }

    const schema = criterion.evaluator.references[conditionalRefs.flagDisabledReference]?.schema;
    return (schema as RegularJsonSchema)?.type || null;
};
