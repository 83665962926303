import Vue from 'vue';
import type { VueRouter } from 'vue-router/types/router';
import noop from 'lodash/noop';
import { RelyingPartySignature } from '@evidentid/rpweb-api-client/types';
import { MOModule, TypedStore } from '@evidentid/vue-commons/store';
import { UserState } from './types';

interface RpControllerApp {
    router: VueRouter;
    store: TypedStore<MOModule<{}, {}, {}> & {
        modules: { user: MOModule<{}, UserState, {}> };
    }>;
}

class RpController {
    private app: RpControllerApp;
    public current!: string | null;
    public available: RelyingPartySignature[] = [];

    public constructor(app: RpControllerApp) {
        this.app = app;
        this.update();
        this.subscribe();
    }

    private update(): void {
        this.available = this.app.store.state.user.relyingParties || [];
        this.current = this.app.router.currentRoute?.params.rpId || null;
    }

    private subscribe(): void {
        this.app.store.watch((state) => state.user.relyingParties, () => this.update());
        this.app.router.afterEach(() => this.update());
    }

    public async change(rpId: string): Promise<void> {
        const app = this.app;
        const route = app.router.currentRoute;
        const internal = /^auth\./.test(route.name || '');
        const nextUrl = typeof route.query.next === 'string'
            ? route.query.next
            : null;
        if (internal && nextUrl) {
            const resolvedRoute = app.router.resolve(nextUrl)?.route;
            if (resolvedRoute) {
                await app.router.push({
                    name: resolvedRoute.name,
                    params: { ...resolvedRoute.params, rpId },
                    query: resolvedRoute.query,
                } as any).catch(noop);
                return;
            }
        }
        await app.router.push({
            ...route,
            params: { ...route.params, rpId },
            query: { ...route.query, next: undefined },
        } as any).catch(noop);
    }
}

export function createRpController(app: RpControllerApp): RpController {
    return Vue.observable(new RpController(app));
}
